<template>
  <a
    :href="href"
    :class="[$s.root, {
      'is-invert': invert,
      'is-external': external,
    }]"
    target="_blank"
    rel="noopener noreferrer"
  >
    <slot>{{ text }}</slot>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'


export default defineComponent({
  props: {
    invert: Boolean,
    external: Boolean,
    href: {
      type: String,
      required: true,
    },
    text: String,
  },
})
</script>

<style lang="scss" module="$s">
.root {
  font-weight: 400;
  line-height: 1.2;
  color: $color-link-text;
  transition: color 0.2s;

  &:hover {
    color: $color-link-hover;
  }

  &:global(.is-external) {
    &::after {
      display: inline;
      margin-left: 0.4rem;
      content: "↗";
    }
  }

  &:global(.is-invert) {
    color: $color-link-hover;

    &:hover {
      color: $color-link-text;
    }
  }
}
</style>
