import { BaseError } from 'viem'

import { t } from './helpers'


type IMetamaskError = {
  code: string;
  error: {
    code: number;
    message: string;
    reason: string;
  };
  data?: {
    message?: string;
  };
  method: string;
  reason: string;
}

const DEFAULT_MESSAGE = 'execution reverted'
const INSUFFICIENT_MESSAGE = t('error.not-enough-balance')
const USERS_REJECT_MESSAGE = t('error.users-reject-tx')
const RPC_DOWN_MESSAGE = t('error.rpc-down')
const WALLET_SWITCH_NOT_AVAILABLE_MESSAGE = t('error.switch-network-not-available')
const WALLET_SWITCH_ALREADY_PENDING = t('error.switch-network-already-pending')
const WALLET_CONNECT_ALREADY_PENDING = t('error.wallet-connect-already-processing')
export const AIRDROP_ALREADY_EXIST_MESSAGE = 'Airdrop name is already exist'

const INSUFFICIENT_MESSAGES = [
  'insufficient funds',
  'insufficient balance',
  '{ index: 51, error: 0, message: None }',
]

const USERS_REJECT_MESSAGES = [
  'user rejected',
  'user denied',
  'cancelled by user',
  'user canceled',
  // injected mobile imToken
  'Actionscancelled by user',
  // this metamask error when decline with close button
  'cannot set properties of undefined (setting \'loadingdefaults\')',
  // mobile trust wallet
  'the operation couldn’t be completed. (browser.dappbrowsererror error 0.)',
  // mobile trust wallet
  'cancelled',
  // mobile DeFi Wallet
  'rejected request',
]

const USER_CLOSE_PROVIDER_MODAL_MESSAGES = [
  'accounts received is empty', // coinbase close modal
  'user closed modal', // walletlink close modal
]

const RPC_DOWN_MESSAGES = [
  'could not detect network',
  'missing response',
]

export const isInsufficient = (message: string) => Boolean(
  INSUFFICIENT_MESSAGES.find((_) => (
    message?.toLowerCase().includes(_.toLowerCase())
  )),
)

export const isUsersReject = (message: string) => Boolean(
  USERS_REJECT_MESSAGES.find((_) => (
    message?.toLowerCase().includes(_.toLowerCase())
  )),
)

export const isUsersCloseProviderModal = (message: string) => Boolean(
  USER_CLOSE_PROVIDER_MODAL_MESSAGES.find((_) => (
    message?.toLowerCase().includes(_.toLowerCase())
  )),
)

export const isRpcDown = (message: string) => Boolean(
  RPC_DOWN_MESSAGES.find((_) => (
    message?.toLowerCase().includes(_.toLowerCase())
  )),
)

export const isWalletSwitchNotAvailable = (message: string) => Boolean(
  /(wallet_switchEthereumChain|wallet_addEthereumChain|switch chain)/.test(message)
  && /(error|not support|not available)/i.test(message),
)

export const isWalletSwitchAlreadyPending = (message: string) => Boolean(
  /(wallet_switchEthereumChain)/.test(message)
  && /(already pending)/i.test(message),
)

export const isWalletConnectAlreadyPending = (message: string) => Boolean(
  /(eth_requestAccounts)/.test(message)
  && /(already processing)/i.test(message),
)

export const isAlreadyConnected = (message: string) => Boolean(
  /(already connected)/i.test(message),
)

// https://github.com/aurora-is-near/rainbow-bridge-client/blob/main/packages/find-replacement-tx/src/index.ts#L49
export const isErrorFindingTransaction = (message?: string) => (
  message === 'Error finding transaction in block.'
)

export const isAlreadyExist = (message: string) => (
  message.includes(AIRDROP_ALREADY_EXIST_MESSAGE)
)

export const isExceedMaxRange = (error: unknown) => (
  /^exceed maximum block range: (\d+)$/i.test((error as Error)?.message || '')
)

export const isMaxCountResults = (error: unknown) => (
  /^query returned more than (\d+) results$/i.test((error as Error)?.message || '')
)

export const getTransactionError = (error: unknown, options: {
  origin?: boolean;
  message?: string;
} = {}) => {
  const opt = {
    origin: false,
    ...options,
  }

  const message = typeof error === 'string' ? error : void 0
    || (error as BaseError)?.shortMessage
    || (error as IMetamaskError)?.error?.reason
    || (error as IMetamaskError)?.error?.message
    || (error as IMetamaskError)?.reason
    || (error as IMetamaskError)?.data?.message
    || (error as Error)?.message

  if (!opt.origin) {
    if (isInsufficient(message)) return INSUFFICIENT_MESSAGE

    if (isUsersReject(message)) return USERS_REJECT_MESSAGE

    if (isRpcDown(message)) return RPC_DOWN_MESSAGE

    if (isWalletSwitchNotAvailable(message)) return WALLET_SWITCH_NOT_AVAILABLE_MESSAGE

    if (isWalletSwitchAlreadyPending(message)) return WALLET_SWITCH_ALREADY_PENDING

    if (isWalletConnectAlreadyPending(message)) return WALLET_CONNECT_ALREADY_PENDING
  }

  if (!message) return 'Some thing wrong'

  if (typeof message !== 'string') {
    try {
      return JSON.stringify(message)
    } catch {
      //
    }
  }

  if (message !== DEFAULT_MESSAGE) {
    if (opt.message) {
      // eslint-disable-next-line no-console
      console.log(`[App wan]: ${opt.message}:`, message)
    }

    // `...RPC '{"value":{"code":-32000,"message":"intrinsic gas too low"}}'` -> intrinsic gas too low
    return message
      .replace(/^(.*)"?(reason|message)"?:"(.+?)"(.*)$/i, '$3')
      .replace(/^(.*)'?(reason|message)'?:'(.+?)'(.*)$/i, '$3')
  }

  return message
}
