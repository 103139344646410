import { t } from '@/utils'
import { parseData } from './utils'


const DELIMITERS_TO_GUESS = ['=', ',', ';', ':', '-', '\t']

export enum SupportedTypes {
  csv = 'text/csv',
  text = 'text/plain',
  string = 'string',
  excel = 'application/vnd.ms-excel',
  excelSheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export class FileParserService {
  public static readonly supportedTypes = SupportedTypes

  constructor(
    private file: File | string,
  ) {}

  public parse() {
    const type = this.file instanceof File
      ? this.file.type as SupportedTypes
      : SupportedTypes.string

    switch (type) {
      case SupportedTypes.string:
        return this.csvParse(this.file, false)

      case SupportedTypes.csv:
      case SupportedTypes.text:
        return this.csvParse(this.file, true)

      case SupportedTypes.excel:
      case SupportedTypes.excelSheet:
        return this.xlsParse(this.file as File, true)

      default: {
        // eslint-disable-next-line no-console
        console.warn(`[App warn]: FileParserService do not supported file type "${type as string}"`)

        const error = new Error(t('error.unsupported-type'))
        return Promise.reject(error)
      }
    }
  }

  public async csvParse(file: File | string, filtered: boolean) {
    const papaparse = await import(
      /* webpackChunkName: "lib.papaparse" */
      'papaparse'
    )

    type IReturnType = ReturnType<typeof parseData>

    const data: [string, string | number][] = []

    return new Promise<IReturnType>((resolve, reject) => {
      papaparse.parse<[string, string | number]>(file, {
        chunkSize: 3,
        header: false,
        delimitersToGuess: DELIMITERS_TO_GUESS,
        error: reject,
        /*
        // > 300,000 lines
        worker: file instanceof File ? file.size > 20_000_000 : file.length > 20_000_000
        step: worker ? (results) => {
          data.push(results.data)
        } : void 0,
        download: false as true,
        */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        complete: (results) => {
          try {
            const result = parseData(results?.data || data, filtered)
            return resolve(result)
          } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('[App err]: papaparse - error on parseData', err)

            const error = new Error(t('error.parse-file'))
            return reject(error)
          }
        },
      })
    })
  }

  public async xlsParse(file: File, filtered: boolean) {
    const xlsx = await import(
      /* webpackChunkName: "lib.xlsx" */
      'xlsx'
    )

    type IReturnType = ReturnType<typeof parseData>

    return new Promise<IReturnType>((resolve, reject) => {
      const reader = new FileReader()

      reader.onerror = reject

      reader.onload = ({ target }) => {
        if (!target) {
          const error = new Error(t('error.read-file'))
          return reject(error)
        }

        const { SheetNames, Sheets } = xlsx.read(target.result, {
          type: 'binary',
          raw: true,
        })

        const [sheetName] = SheetNames
        const observedSheet = Sheets[sheetName]

        type IData = [string, string | number]
        const data = xlsx.utils.sheet_to_json<IData>(observedSheet, {
          header: 1,
          blankrows: false,
          raw: true,
          rawNumbers: true,
        })

        try {
          const formatData = parseData(data, filtered)
          return resolve(formatData)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn('[App err]: xlsx - error on parseData', err)

          const error = new Error(t('error.parse-file'))
          return reject(error)
        }
      }

      reader.readAsBinaryString(file)
    })
  }
}
