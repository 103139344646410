export const STORAGE_NAME = [
  process.env.NODE_ENV,
].join('')

export const APP_VERSION = [
  `Version: ${process.env.VUE_APP_VERSION}`,
  process.env.VUE_APP_RELEASE_SHORT_SHA,
].filter(Boolean).join(' / ')

export const DEFAULT_PAGINATION_LIMIT = 5

export const DEFAULT_PER_PAGE_LIMITS = [
  DEFAULT_PAGINATION_LIMIT,
  10,
  100,
  1000,
]

export const POLLING_BALANCE_TIMEOUT = 60_000 // 1min
export const INFINITY_SYMBOL = '∞'
export const DEFAULT_PAGE_LIMIT_FOR_BACKGROUND = 10_000
export const SWITCH_TO_NETWORK_TIMEOUT = 60_000
