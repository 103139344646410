import {
  Ref,
  ref,
  onBeforeMount,
  onBeforeUnmount,
} from 'vue'
import { storages } from '@/services'


type IResult = {
  key: string;
  value: number;
  isFirstTab: Ref<boolean>;
  isActiveTab: Ref<boolean>;
}

const mapResults = new Map<string, IResult>()
const CALLBACK_LIST: ((e : StorageEvent) => void)[] = []

const onStorage = (e : StorageEvent) => {
  if (!e.key?.startsWith(storages.detectFirstTab.key)) return

  CALLBACK_LIST.forEach((callback) => {
    callback(e)
  })
}

const onUnload = () => {
  mapResults.forEach((value, key) => {
    storages.detectFirstTab.remove(key)
  })
}

window.addEventListener('storage', onStorage, false)
window.addEventListener('beforeunload', onUnload, false)

const createValues = (key: string): IResult => {
  const value = Date.now()
  const isFirstTab = ref(false)
  const isActiveTab = ref(false)

  return {
    key,
    value,
    isFirstTab,
    isActiveTab,
  }
}

const createCallback = (result: IResult) => (
  (e: StorageEvent) => {
    if (e.key !== storages.detectFirstTab.getKey(result.key)) return

    const val = storages.detectFirstTab.get(result.key)

    if (val === null) {
      storages.detectFirstTab.set(result.value, result.key)
    }

    const isEqual = storages.detectFirstTab.get(result.key) === result.value
    // eslint-disable-next-line no-param-reassign
    result.isFirstTab.value = isEqual
  }
)

export const useMultiTab = (key: string, active: boolean): IResult => {
  if (!mapResults.has(key)) {
    const result = createValues(key)
    CALLBACK_LIST.push(createCallback(result))
    mapResults.set(key, result)
  }

  const result = mapResults.get(key) as IResult
  if (!active) return result

  onBeforeMount(() => {
    storages.detectFirstTab.set(result.value, key)
    result.isActiveTab.value = active
    result.isFirstTab.value = true
  })

  onBeforeUnmount(() => {
    result.isActiveTab.value = false
    const value = storages.detectFirstTab.get(key)
    if (value === result.value) {
      storages.detectFirstTab.remove(key)
    }
  })

  return result
}
