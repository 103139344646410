import { deserialize, serialize } from '@wagmi/vue'
import { persistQueryClient } from '@tanstack/query-persist-client-core'
import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import { IInitPlugin } from './types.d'

const vueQueryOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        gcTime: 1_000 * 60 * 60 * 24, // 24 hours
        networkMode: 'offlineFirst',
        refetchOnWindowFocus: false,
        retry: 0,
      },
      mutations: { networkMode: 'offlineFirst' },
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  clientPersister: (queryClient) => persistQueryClient({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryClient,
    persister: createSyncStoragePersister({
      key: 'massdrop.cache',
      serialize,
      deserialize,
      storage: window.localStorage,
    }),
  }),
}

export const initPlugin: IInitPlugin = (app) => {
  app.use(VueQueryPlugin, vueQueryOptions)
}
