import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!

  return (_ctx.isActive)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(_ctx.$s.root),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
      }, [
        _createVNode(_component_MIcon, {
          name: "arrow-down",
          sprite: "",
          size: "medium",
          class: _normalizeClass(_ctx.$s.icon)
        }, null, 8, ["class"])
      ], 2))
    : _createCommentVNode("", true)
}