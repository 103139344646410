<template>
  <div :class="$s.root" class="container-main">
    <router-link to="/" :class="$s.logo" name="logo">
      <img
        :src="require('@/assets/images/logo.svg')"
        :class="$s.logoImage"
        width="154"
        height="32"
        alt="logo"
      >
    </router-link>

    <MLayoutHeaderNav :class="$s.nav" />
    <div :class="$s.features">
      <MBtnNetwork
        :class="$s.btnNetwork"
        :aria-label="$t('aria.switch-btn')"
      />

      <MButton
        v-if="!isConnected"
        type="outline"
        :class="$s.btnConnect"
        :text="$t('button.connect')"
        name="header-connect"
        @click="onConnect"
      />

      <MBtnBalance
        v-else
        :class="$s.btnBalance"
        :aria-label="$t('aria.account')"
      />

      <MLocaleSwitcher
        :class="$s.switcher"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useAccount } from '@wagmi/vue'
import { computed, defineComponent } from 'vue'

import MButton from '@/components/ui/MButton.vue'
import { useModalConnect } from '@/components/modals'

import MBtnNetwork from './components/MBtnNetwork.vue'
import MBtnBalance from './components/MBtnBalance.vue'
import MLocaleSwitcher from './components/MLocaleSwitcher.vue'
import MLayoutHeaderNav from './components/MLayoutHeaderNav.vue'


export default defineComponent({
  components: {
    MButton,
    MBtnNetwork,
    MBtnBalance,
    MLocaleSwitcher,
    MLayoutHeaderNav,
  },
  setup: () => {
    const { address } = useAccount()

    const modalConnect = useModalConnect()

    const isConnected = computed(() => Boolean(address.value))

    const onConnect = async () => {
      await modalConnect.show()
    }

    return { isConnected, onConnect }
  },
})
</script>

<style lang="scss" module="$s">
$logo-width: 15.4rem;

.root {
  display: flex;
  align-items: center;
}

.logo {
  display: block;
  margin-right: auto;

  @include media-lt(tablet) {
    margin-top: 0.6rem;
  }

  @include media-gte(tablet) {
    margin-top: 1.2rem;
  }
}

.logo-image {
  width: $logo-width;
}

.nav {
  @include media-gte(desktop) {
    margin-right: 1.6rem;
  }
}

.features {
  z-index: $layer-overlay;
  display: flex;
  align-items: center;

  @include media-lt(tablet) {
    @include body-background-image;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: $header-height-mobile-scroll;
    padding-right: $layout-x-padding-small;
    padding-left: $layout-x-padding-small;
    border-top: 0.1rem solid $color-border-3;
  }
}

.btn-connect,
.btn-network,
.btn-balance {
  flex-shrink: 0;
}

.btn-network {
  margin-right: 0.8rem;
}

.switcher {
  margin-left: auto;

  @include media-gte(tablet) {
    display: none;
  }
}
</style>
