<template>
  <transition name="transition--fade" appear>
    <div
      :class="[$s.root, `is-type--${type}`, {
        'is-action-mobile-to-bottom': actionMobileToBottom,
      }]"
    >
      <div :class="$s.main">
        <MIcon
          :name="icon"
          size="medium"
          sprite
          :class="$s.icon"
        />
        <p><slot>{{ text }}</slot></p>
      </div>

      <div v-if="action && onAction || $slots.action" :class="$s.action">
        <slot name="action">
          <MButton
            :text="actionText"
            :disabled="actionDisabled"
            :type="type === 'danger' ? 'danger' : void 0"
            :class="$s.button"
            name="message-action"
            @click="onAction"
          />
        </slot>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue'

import MButton from '@/components/ui/MButton.vue'
import MIcon from '@/components/ui/MIcon.vue'


const IMessageTypes = {
  info: 'info',
  warning: 'warning',
  danger: 'danger',
} as const

const ICONS = {
  [IMessageTypes.info]: 'info',
  [IMessageTypes.warning]: 'warning',
  [IMessageTypes.danger]: 'warning',
}

export default defineComponent({
  components: {
    MButton,
    MIcon,
  },
  props: {
    type: {
      type: String as PropType<typeof IMessageTypes[keyof typeof IMessageTypes]>,
      required: true,
      validator: (prop: typeof IMessageTypes[keyof typeof IMessageTypes]) => (
        prop in IMessageTypes
      ),
    },
    text: String,
    action: Boolean,
    actionMobileToBottom: Boolean,
    onAction: Function,
    actionDisabled: Boolean,
    actionText: {
      type: String,
      default: 'Submit',
    },
  },
  setup: (props) => {
    const icon = computed(() => ICONS[props.type])

    return {
      icon,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: flex;
  align-items: center;
  padding: 1rem;
  color: $color-white;
  border-radius: 1.2rem;
  backdrop-filter: blur(1rem);

  &:global {
    &.is-type--info {
      background-color: rgba($--color-info, 0.2);

      @supports not (backdrop-filter: blur(1rem)) {
        background-color: $color-bg-info;
      }
    }

    &.is-type--warning {
      background-color: rgba($--color-warning, 0.2);

      @supports not (backdrop-filter: blur(1rem)) {
        background-color: $color-bg-warning;
      }
    }

    &.is-type--danger {
      background-color: rgba($--color-danger, 0.2);

      @supports not (backdrop-filter: blur(1rem)) {
        background-color: $color-bg-danger;
      }
    }
  }

  @include media-lte(tablet-xs) {
    &:global(.is-action-mobile-to-bottom) {
      flex-direction: column;
      align-items: initial;
    }
  }
}

.icon {
  $root: &;

  flex-shrink: 0;
  margin: 0.2rem;
  margin-right: 0.8rem;

  @at-root .root {
    &:global(.is-type--info) #{$root} {
      color: $color-info;
    }

    &:global(.is-type--warning) #{$root} {
      color: $color-warning;
    }

    &:global(.is-type--danger) #{$root} {
      color: $color-danger;
    }
  }
}

.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  word-break: break-word;
}

.action {
  display: flex;
  justify-content: flex-end;
  margin-left: 0.8rem;

  @include media-lte(tablet-xs) {
    .root:global(.is-action-mobile-to-bottom) & {
      margin-top: 0.8rem;
      margin-left: 0;
    }
  }
}

.button {
  flex-shrink: 0;
  white-space: nowrap;
}
</style>
