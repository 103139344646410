
export const SOCIAL_MEDIUM = {
  id: 'medium',
  label: 'Medium',
  href: 'https://medium.com/@MultiSenderApp',
  icon: 'socials/medium',
}

export const SOCIAL_TELEGRAM = {
  id: 'telegram',
  label: 'Telegram',
  href: 'https://t.me/MultiSender',
  icon: 'socials/telegram',
}

export const SOCIAL_TWITTER = {
  id: 'twitter',
  label: 'Twitter',
  href: 'https://twitter.com/multi_sender',
  icon: 'socials/twitter',
}

export const SOCIAL_GITHUB = {
  id: 'github',
  label: 'Github',
  href: 'https://github.com/',
  icon: 'socials/github',
}
