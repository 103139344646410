import {
  Getters,
  Mutations,
  Actions,
  Module,
  createComposable,
} from 'vuex-smart-module'
import type { ComposableFn } from 'vuex-smart-module/lib'
import { RESTORE_MUTATION } from '../plugins'
import { StoreModules } from '../types'


type S = RootState
type G = RootGetters
type M = RootMutations;
type A = RootActions


export class RootState {
}

class RootGetters extends Getters<S> {
}

class RootMutations extends Mutations<StoreModules> {
  private RESTORE_MUTATION(savedState: StoreModules) {
    RESTORE_MUTATION(this.state, savedState)
  }
}

class RootActions extends Actions<S, G, M, A> {
}

const storeModule = new Module({
  namespaced: false,
  state: RootState,
  getters: RootGetters,
  mutations: RootMutations,
  actions: RootActions,
})

const useStore = createComposable(storeModule) as ComposableFn<typeof storeModule>

export {
  storeModule as RootStoreModule,
  useStore as useRootStore,
}
