/* eslint-disable no-await-in-loop */
import { utils, Contract, BigNumber } from 'ethers'
import {
  memoize,
  memoizeAsync,
} from 'utils-decorators'

import type { MerkleProofAirdropAbi } from '@/types/contracts'
import type { ClaimEvent } from '@/types/contracts/MerkleProofAirdropAbi'

import {
  NETWORK_BSC,
  NetworkService,
  ERC20TokenService,
  Multicall3Service,
} from '@/services'

import {
  sleep,
  calcFeePerTx,
  isExceedMaxRange,
  getGasLimitWithCheckBalance,
  AIRDROP_ALREADY_EXIST_MESSAGE,
} from '@/utils'

import {
  toHash,
  transformAirdrop,
  MERKLE_PROOF_AIRDROP_INTERFACE,
} from './utils'

type IAirdrop = Awaited<ReturnType<MerkleProofAirdropAbi['airdrops']>>

type IAirdropsMeta = Record<string, {
  cancelled: boolean;
  done: boolean;
  claimed: string;
  total: string;
  percent: number;
}>

const EMPTY_OBJECT = Object.freeze({})
const COUNT_PER_REQUEST = 1000 // but request with 2x count_per

const BUMPED_FEE = utils.parseEther('0.5')

const createGenerator = <T>(
  createCallData: (data: T) => {
    target: string;
    callData: string;
    allowFailure: boolean;
  },
  list: T[],
) => (
    function* callsGenerator(limit: number) {
      const listInner = [...list]

      while (listInner.length) {
        const shortList = listInner.splice(0, limit)
        yield shortList.map((data) => createCallData(data))
      }
    }
  )

const createClaimedMulticall = <T>(
  multicall3Service: Multicall3Service,
  contract: MerkleProofAirdropAbi,
  list: T[],
  getCallData: (value: T) => [string, string],
) => {
  const createCallData = (data: typeof list[number]) => ({
    target: contract.address,
    allowFailure: false,
    callData: contract.interface.encodeFunctionData('isClaimed', getCallData(data)),
  })

  const decoder = (str: string) => {
    const result = contract.interface.decodeFunctionResult('isClaimed', str)
    return result[0] as boolean
  }

  return multicall3Service.aggregateMulticallBatchGenerator(
    createGenerator(createCallData, list),
    // await contract.estimateGas.isClaimed(hash, recipients[0][0])
    29667,
    decoder,
  )
}

const createClaimedMulticallByRecipients = (
  multicall3Service: Multicall3Service,
  contract: MerkleProofAirdropAbi,
  recipients: [string, string][],
  airdropName: string,
) => {
  const hash = toHash(airdropName)

  const getCallData = ([recipient]: typeof recipients[number]) => [
    hash,
    utils.getAddress(recipient),
  ] as [string, string]

  return createClaimedMulticall(
    multicall3Service,
    contract,
    recipients,
    getCallData,
  )
}

const createClaimedMulticallByAirdropNames = (
  multicall3Service: Multicall3Service,
  contract: MerkleProofAirdropAbi,
  recipient: string,
  airdropNames: string[],
) => {
  const address = utils.getAddress(recipient)

  const getCallData = (airdropName: typeof airdropNames[number]) => [
    toHash(airdropName),
    address,
  ] as [string, string]

  return createClaimedMulticall(
    multicall3Service,
    contract,
    airdropNames,
    getCallData,
  )
}

const createAirdropsMulticall = (
  multicall3Service: Multicall3Service,
  contract: MerkleProofAirdropAbi,
  list: string[],
) => {
  const createCallData = (data: typeof list[number]) => ({
    target: contract.address,
    allowFailure: false,
    callData: contract.interface.encodeFunctionData('airdrops', [toHash(data)]),
  })

  const decoder = (str: string) => {
    const result = contract.interface.decodeFunctionResult('airdrops', str)
    return result as unknown as IAirdrop
  }

  return multicall3Service.aggregateMulticallBatchGenerator(
    createGenerator(createCallData, list),
    // await contract.estimateGas.airdrops(hash)
    40062,
    decoder,
  )
}

export class MerkleTreeService {
  public currentFee: string | null = null

  public createAirdropFee: string | null = null

  @memoize()
  public static getContract(chainId: number) {
    const networkService = new NetworkService(chainId)
    const address = networkService.settings?.contracts.multisenderMerkle.address
    if (!address) return null

    const provider = networkService.rpcProvider
    return new Contract(address, MERKLE_PROOF_AIRDROP_INTERFACE, provider) as MerkleProofAirdropAbi
  }

  constructor(
    private chainId: number,
  ) {}

  private get settings() {
    const networkService = new NetworkService(this.chainId)
    const settings = networkService.settings?.contracts.multisenderMerkle
    return settings as NonNullable<typeof settings>
  }

  private get contract() {
    const contract = MerkleTreeService.getContract(this.chainId)
    return contract as NonNullable<typeof contract>
  }

  @memoizeAsync(60 * 60 * 1000)
  public async updateCurrentFee() {
    const { chainId, contract } = this

    let currentFee = await contract.fee()
    if (chainId !== NETWORK_BSC.network.chainId && currentFee.lt(BUMPED_FEE) && process.env.NODE_ENV !== 'development') {
      currentFee = BUMPED_FEE
    }

    this.currentFee = currentFee.toString()

    return this.currentFee
  }

  public async updateCreateAirdropFee(payload: {
    publicName: string;
    treeRoot: string;
    tokenAddress: string;
    total: string;
    ethAccount: string;
  }) {
    const { contract } = this
    const currentFee = await this.updateCurrentFee()

    const args = [
      payload.publicName,
      payload.treeRoot,
      payload.tokenAddress,
      payload.total,
    ] as const

    const overrides = {
      value: currentFee,
      from: payload.ethAccount,
    }

    const [estimateGas, feeData] = await Promise.all([
      // if in wallet do not enough balance -> error? change it to currentFee
      contract.estimateGas.createAirdrop(...args, overrides).catch(() => BigNumber.from(0)),
      contract.provider.getFeeData(),
    ])

    const feePerTx = calcFeePerTx(estimateGas, feeData)

    this.createAirdropFee = feePerTx.add(currentFee).toString()

    return this.createAirdropFee
  }

  private async prepareCreateAirdrop(
    publicName: string,
    treeRoot: string,
    tokenAddress: string,
    total: string,
    ethAccount: string,
  ) {
    const args = [
      publicName,
      treeRoot,
      tokenAddress,
      total,
    ] as const

    const currentFee = await this.updateCurrentFee()

    return {
      args,
      currentFee,

      publicName,
      treeRoot,
      tokenAddress,
      total,
      ethAccount,
    }
  }

  public async checkCreateAirdrop(...payload:
    Parameters<typeof this.prepareCreateAirdrop>) {
    const {
      args,
      currentFee,
      tokenAddress,
      ethAccount,
      total,
    } = await this.prepareCreateAirdrop(...payload)

    // before send transaction - test approve and balance of owner
    const tokenContract = ERC20TokenService.buildClass(tokenAddress, this.chainId).contract

    try {
      const airdrop = await this.getAirdrop(args[0], 6)

      if (airdrop) {
        const error = new Error(AIRDROP_ALREADY_EXIST_MESSAGE)
        return Promise.reject(error)
      }
    } catch {
      // do nothing
    }


    await this.contract.estimateGas.createAirdrop(...args, { value: currentFee, from: ethAccount })

    await tokenContract.estimateGas.transferFrom(
      ethAccount,
      this.contract.address,
      total,
      { from: this.contract.address },
    )

    return true
  }

  public async createAirdrop(
    argsPayload: readonly [string, string, string, string, string],
    isInjectedWallet: boolean,
  ) {
    const { args, ethAccount, currentFee } = await this.prepareCreateAirdrop(...argsPayload)

    const { gasLimit } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.createAirdrop(...args, { value: currentFee, from: ethAccount }),
      this.contract.provider,
      ethAccount,
      isInjectedWallet,
    )

    const data = this.contract.interface.encodeFunctionData('createAirdrop', [...args]) as `0x${string}`
    return {
      data,
      value: BigInt(currentFee),
      gas: BigInt(gasLimit.toString()),
      to: this.contract.address as `0x${string}`,
    }
  }

  public async getAirdrop(airdropName: string, decimals: number) {
    const { contract } = this
    const hash = toHash(airdropName)
    const result = await contract.airdrops(hash)
    return result.total.isZero() ? null : transformAirdrop(result, decimals)
  }

  public async getAirdropEvent(airdropName:string, creator: string, blockNumber: number) {
    const { contract } = this

    const currentBlockNumber = await contract.provider.getBlockNumber()
    const filter = contract.filters.AirdropCreated(undefined, creator)
    const events = await contract.queryFilter(filter, blockNumber, currentBlockNumber)
    if (events) {
      const searchableEvent = events.find((el) => el.args[0] === airdropName)
      return searchableEvent
    }
    return undefined
  }

  public async getCancelAirdropEvent(airdropName:string, blockNumber: number) {
    const { contract } = this

    const currentBlockNumber = await contract.provider.getBlockNumber()
    const filter = contract.filters.AirdropCancelled()
    const events = await contract.queryFilter(filter, blockNumber, currentBlockNumber)

    if (events) {
      const searchableEvent = events.find((el) => el.args[0] === airdropName)
      return searchableEvent
    }
    return undefined
  }

  public async getClaimEvent(airdropName:string, recipient: string, blockNumber: number) {
    const { contract } = this

    const currentBlockNumber = await contract.provider.getBlockNumber()
    const filter = contract.filters.Claim(toHash(airdropName), recipient)
    const [event] = await contract.queryFilter(filter, blockNumber, currentBlockNumber)

    return event || undefined
  }

  public async checkClaimEvent(airdropName: string, recipient: string, txHashAirdrop: string) {
    const { contract } = this

    const [isClaimed, tx] = await Promise.all([
      this.getClaimed(airdropName, recipient),
      contract.provider.getTransactionReceipt(txHashAirdrop)
        .catch(() => ({ blockNumber: this.settings?.blockFrom as number })),
    ])

    // not have event
    if (!isClaimed) return void 0

    try {
      const event = await this.getClaimEvent(airdropName, recipient, tx.blockNumber)
      return event || EMPTY_OBJECT
    } catch {
      return EMPTY_OBJECT
    }
  }

  public async getClaimed(airdropName: string, recipient: string) {
    const hash = toHash(airdropName)
    const { contract } = this

    const result = await contract.isClaimed(hash, recipient)
    return result
  }

  public async getClaimEventsAll(options: {
    airdropName: string;
    blockNumber?: number;
    recipients: [string, string][];
  }) {
    const { contract, settings } = this
    const { airdropName, blockNumber } = options
    if (!contract || !settings?.address || !blockNumber) return null

    try {
      const filter = contract.filters.Claim(airdropName)
      const events = await contract.queryFilter(filter, blockNumber)
      const eventsMap = events.reduce((acc, event) => {
        const { recipient } = event.args
        acc[recipient] = { transactionHash: event.transactionHash }
        return acc
      }, {} as Record<string, { transactionHash?: string }>)
      return eventsMap
    } catch (error) {
      if (isExceedMaxRange(error)) {
        return Promise.reject(error)
      }
      return null
    }
  }

  public async getClaimEventsByRecipients(options: {
    airdropName: string;
    recipients: [string, string][];
    blockNumber?: number;
  }) {
    const { contract, settings } = this
    const { airdropName, blockNumber, recipients } = options
    if (!contract || !settings?.address || !blockNumber) return null

    const promises: Promise<ClaimEvent[]>[] = []

    // eslint-disable-next-line @typescript-eslint/no-for-in-array, no-restricted-syntax, guard-for-in
    for (const index in recipients) {
      const i = +index
      if (i && (i % COUNT_PER_REQUEST === 0)) {
        await sleep(50)
      }

      const [recipient] = recipients[i]
      const filter = contract.filters.Claim(airdropName, recipient)
      const promise = contract.queryFilter(filter, blockNumber)
      promises.push(promise)
    }

    const arr = await Promise.all(promises)

    const eventsMap = arr.reduce((acc, [event], index) => {
      if (!event) return acc
      const [recipient] = recipients[index]
      acc[recipient] = { transactionHash: event.transactionHash }
      return acc
    }, {} as Record<string, { transactionHash?: string }>)

    return { eventsMap }
  }

  public async getClaimedMap(options: {
    airdropName: string;
    recipients: [string, string][];
    txHash?: string;
  }): Promise<{
    data: Record<string, { transactionHash?: string }>;
    count: number | null;
  } | null> {
    const { contract, settings } = this
    if (!contract || !settings?.address) return null
    const { airdropName, recipients, txHash } = options

    try {
      const tx = options.txHash
        ? await contract.provider.getTransactionReceipt(options.txHash)
        : null

      const opt = { ...options, blockNumber: tx?.blockNumber }
      let data = await this.getClaimEventsAll(opt)

      if (data) {
        return {
          data,
          count: Object.keys(data).length,
        }
      }

      data = await this.getClaimEventsByRecipients(opt)

      if (data) {
        return {
          data,
          count: null,
        }
      }
    } catch (error) {
      // do nothing
      // eslint-disable-next-line no-console
      console.warn('[App warn]: error on getClaimedMap', {
        airdropName,
        txHash,
        recipients: recipients.length,
      }, error)
    }

    try {
      const result0 = await this.getClaimedMapMulticall(airdropName, recipients)
      if (result0) return result0
    } catch (error) {
      /** do nothing */
    }

    const result2 = await this.getClaimedMapBatch(airdropName, recipients)
    return result2
  }

  private async getClaimedMapMulticall(airdropName: string, recipients: [string, string][]) {
    const { contract } = this
    const multicall3Service = Multicall3Service.buildClass(this.chainId)
    if (!multicall3Service || !contract) return null

    const results = await createClaimedMulticallByRecipients(
      multicall3Service,
      contract,
      recipients,
      airdropName,
    )

    let count = 0
    const isClaimedMap = recipients.reduce((acc, [recipient], index) => {
      if (!results[index]) return acc
      acc[recipient] = EMPTY_OBJECT
      count += 1
      return acc
    }, {} as Record<string, { transactionHash?: string }>)

    return { data: isClaimedMap, count }
  }

  private async getClaimedMapBatch(airdropName: string, recipients: [string, string][]) {
    const { contract } = this

    const promises: Promise<boolean>[] = []
    const hash = toHash(airdropName)

    // eslint-disable-next-line @typescript-eslint/no-for-in-array, no-restricted-syntax, guard-for-in
    for (const index in recipients) {
      const i = +index
      if (i && (i % COUNT_PER_REQUEST === 0)) {
        // double sleep needs for batch RPC requests
        await sleep(50)
        await sleep(50)
      }

      const [recipient] = recipients[i]
      const promise = contract.isClaimed(hash, recipient)
      promises.push(promise)
    }

    const arr = await Promise.all(promises)
    let count = 0

    const isClaimedMap = arr.reduce((acc, value, index) => {
      if (!value) return acc
      const [recipient] = recipients[index]
      acc[recipient] = EMPTY_OBJECT
      count += 1
      return acc
    }, {} as Record<string, { transactionHash?: string }>)

    return { data: isClaimedMap, count }
  }

  public async getClaimedMapByRecipient(recipient: string, airdropNames: string[]) {
    const { contract, settings } = this
    if (!contract || !settings?.address) return null

    const result0 = await this.getClaimedMapMulticallByRecipient(recipient, airdropNames)
    if (result0) return result0

    const result2 = await this.getClaimedMapBatchByRecipient(recipient, airdropNames)
    return result2
  }

  private async getClaimedMapMulticallByRecipient(recipient: string, airdropNames: string[]) {
    const { contract } = this
    const multicall3Service = Multicall3Service.buildClass(this.chainId)
    if (!multicall3Service || !contract) return null

    const results = await createClaimedMulticallByAirdropNames(
      multicall3Service,
      contract,
      recipient,
      airdropNames,
    )

    const isClaimedMap = airdropNames.reduce((acc, airdropName, index) => {
      acc[airdropName] = results[index]
      return acc
    }, {} as Record<string, boolean>)

    return isClaimedMap
  }

  private async getClaimedMapBatchByRecipient(recipient: string, airdropNames: string[]) {
    const { contract } = this

    const result: Record<string, boolean> = {}

    // eslint-disable-next-line @typescript-eslint/no-for-in-array, no-restricted-syntax, guard-for-in
    for (const index in airdropNames) {
      const i = +index
      if (i && (i % COUNT_PER_REQUEST === 0)) {
        // need for in one request COUNT_PER_REQUEST
        await sleep(50)
        await sleep(50)
      }

      const airdropName = airdropNames[i]
      const hash = toHash(airdropName)
      const isClaimed = await contract.isClaimed(hash, recipient)
      result[airdropName] = isClaimed
    }

    return result
  }

  public async getAirdropsMetaMap(airdropNames: string[]) {
    const { contract, settings } = this
    if (!contract || !settings?.address) return null

    const result0 = await this.getAirdropsMetaMapMulticall(airdropNames)
    if (result0) return result0

    const result2 = await this.getAirdropsMetaMapBatch(airdropNames)
    return result2
  }

  private async getAirdropsMetaMapMulticall(airdropNames: string[]) {
    const { contract } = this
    const multicall3Service = Multicall3Service.buildClass(this.chainId)
    if (!multicall3Service || !contract) return null

    const initialValue: IAirdropsMeta = {}

    const results = await createAirdropsMulticall(
      multicall3Service,
      contract,
      airdropNames,
    )

    const airdropsMetaMap = airdropNames.reduce((acc, airdropName, index) => {
      const { cancelled, claimed, total } = results[index]

      // if airdropName is broken
      if (total.isZero()) return acc

      acc[airdropName] = {
        cancelled,
        done: claimed.eq(total),
        claimed: claimed.toString(),
        total: total.toString(),
        percent: claimed.mul(100).div(total).toNumber(),
      }

      return acc
    }, initialValue)

    return airdropsMetaMap
  }

  private async getAirdropsMetaMapBatch(airdropNames: string[]) {
    const { contract } = this

    const result: IAirdropsMeta = {}

    // eslint-disable-next-line @typescript-eslint/no-for-in-array, no-restricted-syntax, guard-for-in
    for (const index in airdropNames) {
      const i = +index
      if (i && (i % COUNT_PER_REQUEST === 0)) {
        // need for in one request COUNT_PER_REQUEST
        await sleep(50)
        await sleep(50)
      }

      const airdropName = airdropNames[i]
      const hash = toHash(airdropName)
      const airdrop = await contract.airdrops(hash)
      const { cancelled, claimed, total } = airdrop

      if (total.gt(0)) {
        result[airdropName] = {
          cancelled,
          done: claimed.eq(total),
          claimed: claimed.toString(),
          total: total.toString(),
          percent: claimed.mul(100).div(total).toNumber(),
        }
      }
    }

    return result
  }

  public async checkApproveAndBalanceToken(payload: {
    tokenAddress: string;
    owner: string;
    amount: string;
    recipient: string;
  }) {
    const {
      tokenAddress,
      owner,
      amount,
      recipient,
    } = payload
    const { contract } = this

    // before claim check approve and balance
    const tokenContract = ERC20TokenService.buildClass(tokenAddress, this.chainId).contract

    await tokenContract.estimateGas
      .transferFrom(owner, recipient, amount, { from: contract.address })

    return true
  }

  public async claim([
    airdropName,
    proof,
    amount,
    recipient,
    ethAccount,
  ]: [string, string[], string, string, string], isInjectedWallet: boolean) {
    const args = [
      proof,
      recipient,
      amount,
      airdropName,
    ] as const

    const { gasLimit } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.claim(...args, { from: ethAccount }),
      this.contract.provider,
      ethAccount,
      isInjectedWallet,
    )

    const data = this.contract.interface.encodeFunctionData('claim', [...args]) as `0x${string}`

    return {
      data,
      gas: BigInt(gasLimit.toString()),
      to: this.contract.address as `0x${string}`,
    }
  }

  public async cancelAirdrop(
    airdropName: string,
    ethAccount: string,
    isInjectedWallet: boolean,
  ) {
    const { gasLimit } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.cancelAirdrop(airdropName, { from: ethAccount }),
      this.contract.provider,
      ethAccount,
      isInjectedWallet,
    )

    const data = this.contract.interface.encodeFunctionData('cancelAirdrop', [airdropName]) as `0x${string}`

    return {
      data,
      gas: BigInt(gasLimit.toString()),
      to: this.contract.address as `0x${string}`,
    }
  }
}
