import type { Store } from 'vuex'

import type { ComposableFn, Context2 } from 'vuex-smart-module/lib'

import {
  Module,
  Actions,
  Getters,
  Mutations,
  createComposable,
} from 'vuex-smart-module'

import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber, ContractReceipt, ContractTransaction } from 'ethers'

import pick from 'lodash/pick'
import mapValues from 'lodash/mapValues'
import { TransactionStatus } from '@safe-global/safe-gateway-typescript-sdk'

import { waitSafeTx } from '@/services/SafeSDKService'
import { NetworkService, sentryLogger, waitTxReceipt } from '@/services'
import {
  sleep,
  TxStatuses,
  detectAccountTx,
  type ITxDetails,
} from '@/utils'

import { createSetState } from './types'

import { AppStoreModule } from './app'
import { WalletStoreModule } from './wallet'


type S = TxHistoryState
type G = TxHistoryGetters
type M = TxHistoryMutations
type A = TxHistoryActions


type Tx = {
  gasLimit: string;
  gasPrice?: string;
  maxFeePerGas?: string;
  maxPriorityFeePerGas?: string;
  value: string;
  data: string;
  blockNumber: number;
}

type Receipt = {
  blockNumber: number;
  gasUsed: string;
  status: number;
  transactionHash: string;
  effectiveGasPrice: string;
}

export type ITxHistory = {
  tx: Tx;
  details: ITxDetails;
  receipt?: Receipt;
  chainId: number;
  timestamp: number;
  hash: string;
  status: TxStatuses;
  from: string;
  to: string;
  nonce: number;
}

export type IGnosisTx = {
  hash: string;
  token: string;
  chainId:number;
  blockNumber: number;
  details: ITxDetails;
  multisigAddress: string;
  tx?: ContractTransaction;
  status: TransactionStatus;
  confirmationsCounter?: string;
}

type WatchTxParams = {
  txHash: string;
  chainId: number;
  retry?: number;
}

// eslint-disable-next-line
const extractBigNumbers = <R>(data: any): R => (
  mapValues(data, (value) => (value?._isBigNumber ? (value as BigNumber).toString() : value))
)

export class TxHistoryState {
  public list: ITxHistory[] = []

  public gnosisTxs: Record<string, IGnosisTx> = {}
}

class TxHistoryGetters extends Getters<S> {
  private walletStore!: Context2<typeof WalletStoreModule>

  public $init(store: Store<unknown>) {
    this.walletStore = WalletStoreModule.context(store) as Context2<typeof WalletStoreModule>
  }

  public get gnosisListAccount() {
    const ethAccount = this.walletStore.state.ethAccount?.toLowerCase()

    return Object.values(this.state.gnosisTxs)
      .filter((gnosisTx) => !gnosisTx.tx || detectAccountTx(gnosisTx.tx, ethAccount))
      .sort((a, b) => (b.tx?.timestamp ? b.tx.timestamp - (a.tx?.timestamp || -1) : -1))
  }

  public get listAccount() {
    const ethAccount = this.walletStore.state.ethAccount?.toLowerCase()

    return this.state.list
      .filter((tx) => detectAccountTx(tx, ethAccount))
      .sort((a, b) => (b.timestamp ? b.timestamp - a.timestamp : -1))
  }

  public get gnosisListAccountPending() {
    return this.getters.gnosisListAccount.filter((_) => (
      [TransactionStatus.AWAITING_EXECUTION, TransactionStatus.AWAITING_CONFIRMATIONS].includes(_.status)
    ))
  }

  public get listAccountPending() {
    return this.getters.listAccount.filter((_) => (
      _.status === TxStatuses.pending
    ))
  }

  public get listAccountNotFoundTx() {
    return this.getters.listAccount.filter((_) => (
      _.status === TxStatuses.notFoundTx
    ))
  }
}

class TxHistoryMutations extends Mutations<S> {
  public setState(options: {
    [K in keyof S]: { k: K; v: S[K] }
  }[keyof S]) {
    // @ts-ignore: TODO ts(2322)
    this.state[options.k] = options.v
  }
}

export class TxHistoryActions extends Actions<S, G, M, A> {
  private appStore!: Context2<typeof AppStoreModule>

  private walletStore!: Context2<typeof WalletStoreModule>

  public $init(store: Store<unknown>) {
    this.appStore = AppStoreModule.context(store) as Context2<typeof AppStoreModule>
    this.walletStore = WalletStoreModule.context(store) as Context2<typeof WalletStoreModule>
  }

  // wait pending transaction after F5
  public startWaitPendingAll() {
    const list = [
      ...this.getters.listAccountPending,
      // ...this.getters.listAccountNotFoundTx,
    ]

    Object.values(this.state.gnosisTxs).forEach((data) => {
      void this.actions.handleGnosisTx(data)
    })

    list.forEach((data) => {
      void this.actions.waitTx(data)
    })
  }

  public async handleWatchTxData({ txHash, chainId, retry = 0 }: WatchTxParams):Promise<TransactionResponse> {
    try {
      const { rpcUrl } = new NetworkService(chainId)
      const provider = NetworkService.getRpcProvider(rpcUrl, chainId)

      const tx = await provider.getTransaction(txHash)

      if (!tx) {
        throw new Error('Failed to fetch tx data')
      }
      return tx
    } catch (err) {
      if (retry < 20) {
        const _retry = retry + 1
        await sleep(Math.min(120_000, 10_000 * _retry)) // 2min or calculated
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.actions.handleWatchTxData({ txHash, chainId, retry: _retry })
      }
      throw err
    }
  }

  public async handleWaitGnosisTx(payload: IGnosisTx) {
    const setState = createSetState<A>(this)

    const safeTx = await waitSafeTx(payload, (params) => {
      const txInfo = this.state.gnosisTxs[params.hash]

      if (txInfo) {
        const confirmationsCounter = params.confirmationsCounter || txInfo.confirmationsCounter
        const txData = { ...txInfo, status: params.status, confirmationsCounter }

        setState({
          k: 'gnosisTxs',
          v: { ...this.state.gnosisTxs, [params.hash]: txData },
        })
      }
    })

    return safeTx
  }

  public async handleGnosisTx(payload: IGnosisTx) {
    const setState = createSetState<A>(this)
    const { gnosisTxs } = this.state

    const localGnosisTxs = { ...gnosisTxs }
    try {
      const gnosisTx = localGnosisTxs[payload.hash]
      if (!gnosisTx) {
        throw new Error('Gnosis Safe tx hash is missing')
      }

      const safeTxHash = await this.actions.handleWaitGnosisTx(payload)

      if (!safeTxHash) {
        throw new Error('Transaction failed')
      }


      const tx = await this.actions.handleWatchTxData({ txHash: safeTxHash, chainId: payload.chainId })

      tx.from = gnosisTx.multisigAddress
      const onChainTx = await this.actions.add({
        tx,
        chainId: payload.chainId,
        details: payload.details,
      })

      delete localGnosisTxs[payload.hash]
      setState({ k: 'gnosisTxs', v: localGnosisTxs })

      return onChainTx
    } catch (err) {
      delete localGnosisTxs[payload.hash]
      setState({ k: 'gnosisTxs', v: localGnosisTxs })
      return undefined
    }
  }

  public async addGnosisTx(payload: Omit<IGnosisTx, 'blockNumber'>) {
    const setState = createSetState<A>(this)

    const provider = new NetworkService(payload.chainId).rpcProvider
    const blockNumber = await provider.getBlockNumber()

    const gnosisTx = { ...payload, blockNumber }
    const localGnosisTxs = { ...this.state.gnosisTxs, [payload.hash]: gnosisTx }

    setState({ k: 'gnosisTxs', v: localGnosisTxs })
    const tx = await this.actions.handleGnosisTx(gnosisTx)
    return tx
  }

  public async add(payload: {
    tx: ContractTransaction;
    chainId: number;
    details: ITxDetails;
  }) {
    const setState = createSetState<A>(this)
    const { tx, chainId, details } = payload
    const { rpcUrl, rpcProvider } = new NetworkService(chainId)

    const blockNumber = (
      tx.blockNumber
        || await rpcProvider.getBlockNumber()
    )

    const data: ITxHistory = {
      chainId,
      status: TxStatuses.pending,
      timestamp: Date.now(),
      from: tx.from,
      to: tx.to as string,
      hash: tx.hash,
      nonce: tx.nonce,
      details,
      tx: {
        ...extractBigNumbers<ITxHistory['tx']>(pick(tx, [
          'gasLimit',
          'gasPrice',
          'maxFeePerGas',
          'maxPriorityFeePerGas',
          'value',
          'data',
        ])),
        blockNumber,
      },
    }

    // check rpc network on down
    const provider = NetworkService.getRpcProvider(rpcUrl, chainId)
    await provider.getNetwork()

    const transaction = sentryLogger.trackTransaction({
      ...data,
      rpcUrl,
      balance: this.appStore.state.balance,
      provider: this.walletStore.state.type || void 0,
    })

    transaction.finish()

    const list = this.state.list.concat(data)

    setState({ k: 'list', v: list })

    const wait = () => waitTxReceipt(data.chainId, data.hash, {
      ...data.tx,
      ...data,
    })

    const txCorrect = { ...tx, wait }

    void this.actions.waitTx(txCorrect)

    return txCorrect
  }

  public findTx(payload: {
    hash: string;
  }) {
    const { hash } = payload
    const data = this.state.list.find((_) => _.hash === hash)
    if (!data) return null

    const transactionHash = data.receipt?.transactionHash || hash
    const wait = () => waitTxReceipt(data.chainId, transactionHash, {
      ...data.tx,
      ...data,
    })

    const txCorrect = { hash: transactionHash, wait }

    return txCorrect
  }

  private async waitTx(payload: {
    hash: string;
    wait?: () => Promise<ContractReceipt>;
  }) {
    let receipt: ContractReceipt | null = null
    let status = TxStatuses.pending
    const data = this.state.list.find((_) => _.hash === payload.hash)

    if (!data) return
    const { rpcUrl } = new NetworkService(data.chainId)
    const { balance } = this.appStore.state

    try {
      receipt = await waitTxReceipt(data.chainId, data.hash, { ...data.tx, ...data })
      status = receipt?.status ?? TxStatuses.success
    } catch (err) {
      const error = err as { receipt?: ContractReceipt; message?: string }

      // eslint-disable-next-line no-console
      console.warn(`[App err]: error on wait transaction "${data.hash}"`, error)

      if (error.receipt) receipt = error.receipt

      if (error.message?.includes('Transaction canceled')) {
        status = TxStatuses.cancelled
      } else if (error.message?.includes('Transaction was dropped')) {
        status = TxStatuses.dropped
      } else {
        status = TxStatuses.failed
      }

      // TODO: status = TxStatuses.notFoundTx
    }

    void this.appStore.actions.updateBalance()

    const newData: ITxHistory = {
      ...data,
      status,
      receipt: receipt ? extractBigNumbers<ITxHistory['receipt']>(pick(receipt, [
        'blockNumber',
        'gasUsed',
        'effectiveGasPrice',
        'status',
        'transactionHash',
      ])) : void 0,
    }

    const list = [...this.state.list]

    const newList = list.reduce<ITxHistory[]>((acc, curr) => {
      acc.push(curr.hash === receipt?.transactionHash ? newData : curr)
      return acc
    }, [])

    this.mutations.setState({ k: 'list', v: newList })

    sentryLogger.trackTransaction({
      ...newData,
      rpcUrl,
      balance,
      provider: this.walletStore.state.type || undefined,
    }).finish()
  }
}

const storeModule = new Module({
  namespaced: true,
  state: TxHistoryState,
  getters: TxHistoryGetters,
  mutations: TxHistoryMutations,
  actions: TxHistoryActions,
})

const useStore = createComposable(storeModule) as ComposableFn<typeof storeModule>

export {
  storeModule as TxHistoryStoreModule,
  useStore as useTxHistoryStoreModule,
}
