<template>
  <slot
    name="activator"
    :active="isActive"
    :on="{
      mouseenter: onMouseEnter,
      mouseleave: onMouseLeave,
      touchstart: onTouchStart,
      touchend: onTouchEnd,
    }"
  >
    <span
      :style="$attrs.style"
      :class="$attrs.class"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @touchstart="onTouchStart"
      @touchend="onTouchEnd"
      v-text="activatorText"
    />
  </slot>

  <div
    v-if="isActive"
    ref="contentRef"
    :style="contentStyle"
    :class="[$s.content, {
      'is-active': isActive,
      'is-show': isShow,
    }, contentClass]"
    data-triangle-left="166px"
    @mouseenter="onMouseEnterContent"
    @mouseleave="onMouseLeaveContent"
  >
    <slot name="content">
      <span
        v-text="contentText"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import {
  CSSProperties,
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  watch,
  nextTick,
} from 'vue'


const CLOSE_TIMEOUT = 100
const OFFSET_X = 12 // $layout-x-padding-small
const OFFSET_Y = 12
const TOUCH_DURATION = 1_000

const calcOffsetLeft = (activator: HTMLElement, content: HTMLElement) => {
  const activatorPos = activator.getBoundingClientRect()
  const contentPos = content.getBoundingClientRect()
  const viewportWidth = document.documentElement.clientWidth
  const centerOfActivator = activatorPos.left + (activatorPos.width / 2)
  let offsetLeft = centerOfActivator - (contentPos.width / 2)

  if ((offsetLeft + contentPos.width) > (viewportWidth)) {
    offsetLeft = viewportWidth - contentPos.width - OFFSET_X
  } else if (offsetLeft < 0) {
    offsetLeft = OFFSET_X
  }

  return offsetLeft
}

const calcOffsetTop = (activator: HTMLElement, content: HTMLElement, side = 'top') => {
  const activatorPos = activator.getBoundingClientRect()
  const contentPos = content.getBoundingClientRect()

  if (side === 'top') {
    return activatorPos.top - (contentPos.height + OFFSET_Y)
  }

  return activatorPos.bottom + OFFSET_Y
}

const calcContentStyle = (activator: HTMLElement, content: HTMLElement) => {
  const contentOffsetLeft = calcOffsetLeft(activator, content)
  const contentOffsetTop = calcOffsetTop(activator, content)

  const left = contentOffsetLeft ? `${contentOffsetLeft}px` : '0'
  const top = contentOffsetTop ? `${contentOffsetTop}px` : '0'

  return {
    left,
    top,
  }
}

const calcContentTriangleStyle = (activator: HTMLElement, content: HTMLElement) => {
  const activatorPos = activator.getBoundingClientRect()
  const contentPos = content.getBoundingClientRect()
  const centerActivator = activatorPos.x + activatorPos.width / 2

  if (contentPos.x === 0) return '50%'
  const result = centerActivator - contentPos.x

  return `${result}px`
}

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: Boolean,
    activatorText: String,
    contentText: String,
    contentClass: [Object, String],
    disabled: Boolean,
    maxWidth: String,
  },
  emits: ['update:model-value'],
  setup: (props, ctx) => {
    const activatorRef = ref<HTMLElement>()
    const contentRef = ref<HTMLElement>()
    const isActive = ref(false)
    const isShow = ref(false)
    const contentStyle = ref<CSSProperties>({})

    let isAddedEventListener = false
    let isContentAppended = false
    let isUnmounted = false
    let timer: ReturnType<typeof setTimeout>

    const open = async () => {
      clearTimeout(timer)
      if (props.disabled) return

      isActive.value = true

      // wait render wrapper
      await nextTick()

      const activator = activatorRef.value
      const content = contentRef.value
      if (!activator || !content || isUnmounted) return

      if (isContentAppended) {
        contentStyle.value = {}
        await nextTick()
        if (isUnmounted) return
      }

      document.body.appendChild(content)
      isContentAppended = true

      contentStyle.value = {
        ...calcContentStyle(activator, content),
        maxWidth: props.maxWidth,
      }

      isShow.value = true
      ctx.emit('update:model-value', true)

      // wait render contentStyle with styles
      await nextTick()

      contentStyle.value = {
        ...calcContentStyle(activator, content),
        maxWidth: props.maxWidth,
      }

      // wait render contentStyle with styles
      await nextTick()

      const triangleLeft = calcContentTriangleStyle(activator, content)

      contentStyle.value = {
        ...contentStyle.value,
        // @ts-ignore ts(2322)
        '--tooltip-triangle-left': triangleLeft,
      }
    }

    const close = () => {
      clearTimeout(timer)
      if (contentRef.value && isShow.value) {
        // document.body.removeChild(contentRef.value)
      }

      isShow.value = false
      ctx.emit('update:model-value', false)
      isActive.value = isShow.value
    }

    const onMouseEnter = (event: MouseEvent) => {
      activatorRef.value = event.target as HTMLElement
      void open()
    }

    let timerTouch: ReturnType<typeof setTimeout>
    const onTouchStart = (event: TouchEvent) => {
      activatorRef.value = event.target as HTMLElement
      timerTouch = setTimeout(() => void open(), TOUCH_DURATION)
    }

    const onTouchEnd = () => {
      clearTimeout(timerTouch)
      void open()
    }

    const onMouseEnterContent = () => {
      void open()
    }

    const onMouseLeave = () => {
      clearTimeout(timer)
      timer = setTimeout(close, CLOSE_TIMEOUT)
    }

    const onScroll = () => {
      void close()
    }

    watch(() => props.modelValue, (value) => {
      if (value) {
        void open()
      } else {
        void close()
      }
    }, { immediate: true })

    watch(() => props.disabled, close, { immediate: true })

    const onStart = () => {
      isUnmounted = false

      if (isAddedEventListener) return
      window.addEventListener('scroll', onScroll, { passive: true })
      window.addEventListener('orientationchange', close)

      isAddedEventListener = true
    }

    const onEnd = () => {
      isUnmounted = true
      isAddedEventListener = false
      clearTimeout(timer)

      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('orientationchange', close)

      const content = contentRef.value
      if (content && isContentAppended) {
        isContentAppended = false
        document.body.removeChild(content)
      }
    }

    onMounted(onStart)
    onActivated(onStart)

    onBeforeUnmount(onEnd)
    onDeactivated(onEnd)

    return {
      activatorRef,
      contentRef,

      isActive,
      isShow,
      contentStyle,

      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onTouchEnd,
      onMouseEnterContent,
      onMouseLeaveContent: onMouseLeave,
    }
  },
})
</script>

<style lang="scss" module="$s">
.content {
  --tooltip-triangle-left: 50%;

  position: fixed;
  z-index: $layer-tooltip;
  display: none;
  max-width: calc(100% - #{$layout-x-padding-small});
  padding: 0.8rem 1.2rem;
  font-size: 1.3rem;
  color: $color-white;
  background-color: $color-border-3;
  border-radius: 0.8rem;
  box-shadow: 0 1.2rem 1.2rem rgba($--color-bg, 0.5);
  opacity: 0;
  transition: opacity 0.2s;

  &:global(.is-active) {
    display: block;
  }

  &:global(.is-show) {
    opacity: 1;
  }

  &::before {
    $size: 0.7rem;

    position: absolute;
    bottom: -$size;
    left: var(--tooltip-triangle-left);
    width: 0;
    height: 0;
    content: "";
    border-color: $color-border-3;
    border-style: solid;
    border-width: $size $size 0 $size;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: translateX(-$size);
  }
}
</style>
