import { useAppStore } from '@/composable'
import { ValidatorService } from '@/services'

export function useCheckRpc() {
  const { $state } = useAppStore()

  return async (value: string) => {
    const validatorService = new ValidatorService()
    const result = await validatorService.rpcUrl(value, $state.chainId)
    if (result === true) return true

    return result.message
  }
}
