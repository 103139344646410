import type { TransactionReceipt } from '@ethersproject/abstract-provider'


type IWorkerMapData = {
  transactionHash: string;
  nonce: number;
  from: string;
  promise: Promise<TransactionReceipt>;
  watch: () => Promise<void>;
  unwatch: () => void;
}

export class WorkerMap {
  private readonly store = new Map<number, IWorkerMapData[]>()

  public add(chainId: number, data: IWorkerMapData) {
    const list = this.store.get(chainId) || []
    this.store.set(chainId, list.concat(data))
  }

  public remove(chainId: number, transactionHash: string) {
    const list = this.store.get(chainId) || []
    const listNew = list.filter((_) => _.transactionHash !== transactionHash)
    this.store.set(chainId, listNew)
  }

  public get(chainId: number, transactionHash: string) {
    const list = this.store.get(chainId)
    return list?.find((_) => _.transactionHash === transactionHash)
  }

  public clearAll() {
    // eslint-disable-next-line no-restricted-syntax
    for (const chainId of this.store.keys()) {
      const list = this.store.get(chainId)
      list?.forEach((_) => _.unwatch())
    }

    this.store.clear()
  }

  public isSmallestNonce(chainId: number, transactionHash: string) {
    const data = this.get(chainId, transactionHash)
    const list = this.store.get(chainId) || []
    if (!data) return false

    return list
      .filter((_) => _.from === data.from)
      .every((_) => data.nonce <= _.nonce)
  }
}

export const workerMapInstance = new WorkerMap()
