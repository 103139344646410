<template>
  <transition name="transition--fade" appear>
    <div :class="$s.root" />
  </transition>
</template>

<script lang="ts">
import {
  defineComponent,
  useCssModule,
  onMounted,
  onBeforeUnmount,
} from 'vue'

export default defineComponent({
  setup: () => {
    const cssModule = useCssModule('$s')
    const { classList } = document.body

    onMounted(() => classList.add(cssModule.body))
    onBeforeUnmount(() => classList.remove(cssModule.body))
  },
})
</script>

<style lang="scss" module="$s">
.root {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 !important;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    backdrop-filter: blur(0.3rem);
    // background-color: rgba($--color-bg, 0.5);

    @supports not (backdrop-filter: blur(0.3rem)) {
      background-color: rgba($--color-bg, 0.25);
    }
  }

  &::after {
    $size: 8rem;

    @include loading($color-primary, $size, 0.4rem, 2s);
    position: sticky;
    top: calc(50% - #{$size});
  }

  &::before,
  &::after {
    z-index: $layer-preloader;
  }
}

.body {
  @supports not (backdrop-filter: blur(0.3rem)) {
    :global(#page-main) {
      filter: blur(0.3rem);
    }
  }
}
</style>
