import { utils } from 'ethers'


export const shortenAddress = (address: string, count = 4) => [
  address.substring(0, count + 2),
  address.substring(address.length - count),
].join('...')

export const shortenAddressWithValidation = (address: string, count = 4) => {
  const isAddress = utils.isAddress(address)

  if (!isAddress) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return shortenAddress(address, count)
}
