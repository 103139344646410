<template>
  <div :class="$s.root">
    <MMessage
      v-if="switchSettings"
      type="danger"
      :text="$t(switchSettings.text, switchSettings.params)"
      action
      :action-disabled="isDisabledSwitchNetwork"
      action-mobile-to-bottom
      :action-text="$t('button.switch-network')"
      @action="onSwitch"
    />

    <MMessage
      v-else-if="!isFirstTab && isActiveTab"
      type="danger"
      action-mobile-to-bottom
      :text="$t('error.multiple-open')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSwitchChain } from '@wagmi/vue'

import {
  useAirdropStore,
  useAppStore,
  useMultisenderStore,
  useWalletStore,
  useMultiTabMultisend,
  useNotices,
} from '@/composable'

import {
  NetworkService,
  sentryLogger,
} from '@/services'

import {
  useModalSwitchNetwork,
} from '@/components/modals'

import {
  sleep,
  isUsersReject,
  getTransactionError,
} from '@/utils'
import { SWITCH_TO_NETWORK_TIMEOUT } from '@/utils/constants/params'

import MMessage from '@/components/ui/MMessage.vue'

export default defineComponent({
  components: {
    MMessage,
  },
  emits: {
    switch: () => true,
  },
  setup: () => {
    const { t } = useI18n()

    const notices = useNotices()
    const appStore = useAppStore()
    const walletStore = useWalletStore()
    const airdropStore = useAirdropStore()
    const multisenderStore = useMultisenderStore()
    const modalSwitchNetwork = useModalSwitchNetwork()
    const multiTabMultisend = useMultiTabMultisend(false)

    const { switchChainAsync } = useSwitchChain()
    const isLoadingSwitch = ref(false)
    const { isFirstTab, isActiveTab } = multiTabMultisend

    const switchSettings = computed(() => {
      const appSettings = NetworkService.getNetworkForSwitch(appStore.$state.chainId)
      const networkFrom = appSettings?.chainName || appStore.$state.chainId
      switch (true) {
        case appStore.$state.isMismatchAirdropNetwork: {
          const chainId = airdropStore.$state.airdropPublic?.chainId
          const settings = NetworkService.getNetworkForSwitch(chainId)

          const networkTo = settings?.chainName || chainId
          const params = { network: networkFrom, networkFrom, networkTo }

          return {
            text: 'error.network-not-match-airdrop',
            chainId,
            params,
          }
        }

        case appStore.$state.isMismatchMultisenderNetwork: {
          const chainId = multisenderStore.$state.airdropPrivate?.chainId
          const settings = NetworkService.getNetworkForSwitch(chainId)

          const networkTo = settings?.chainName || chainId
          const params = { network: networkFrom, networkFrom, networkTo }

          return {
            text: 'error.network-not-match-multisend',
            chainId,
            params,
          }
        }

        case appStore.$state.isMismatchWalletNetwork: {
          const { chainId } = walletStore.$state
          const settings = NetworkService.getNetworkForSwitch(chainId)
          const networkTo = settings?.chainName || chainId
          const params = { network: networkFrom, networkFrom, networkTo }

          return {
            text: 'error.network-not-match-wallet',
            chainId: appSettings?.chainId,
            params,
          }
        }

        case !appStore.$state.isSupportedNetwork: {
          const { chainId } = walletStore.$state
          const settings = NetworkService.getNetworkForSwitch(chainId)
          const networkTo = settings?.chainName || chainId
          const params = { network: networkFrom, networkFrom, networkTo }

          return {
            text: 'error.network-unsupported',
            chainId,
            params,
          }
        }

        default:
          return null
      }
    })

    const onSwitchToNetwork = async (toggleLoading: () => void) => {
      const chainId = (
        switchSettings.value?.chainId
        || appStore.$state.chainId
      )

      if (isLoadingSwitch.value) return
      if (!chainId) return
      isLoadingSwitch.value = true
      toggleLoading()

      const { networkTo: network } = (
        switchSettings.value?.params
        || { networkTo: chainId }
      )

      try {
        await Promise.race([
          switchChainAsync({ chainId: +chainId }),
          sleep(SWITCH_TO_NETWORK_TIMEOUT).then(() => {
            const message = t('error.switch-network-timeout', { network })
            return Promise.reject(message)
          }),
        ])
      } catch (error) {
        const message = getTransactionError(error, { origin: true })

        if (!isUsersReject(message)) {
          notices.createErrorResponse(error)
          sentryLogger.capture(error)
        }
      }

      isLoadingSwitch.value = false
      toggleLoading()
    }

    const onSwitch = (toggleLoading: () => void) => {
      if (switchSettings.value) {
        void onSwitchToNetwork(toggleLoading)
      } else {
        void modalSwitchNetwork.show()
      }
    }

    const isDisabledSwitchNetwork = computed(() => {
      const walletMeta = walletStore.walletMeta.value
      return Boolean(walletMeta) && (walletMeta?.isGnosisSafe || !walletMeta?.isAvailableChainSwitch)
    })

    return {
      switchSettings,
      isLoadingSwitch,
      isDisabledSwitchNetwork,

      isFirstTab,
      isActiveTab,

      onSwitch,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: flex;
  justify-content: center;
}
</style>
