// https:/webpack.js.org/guides/dependency-management/#require-context
const requireContext = require.context('.', true, /.svg$/)

const SPRITES = new Map<string, string>()

requireContext.keys().forEach((fileName) => {
  // eslint-disable-next-line
  const path = requireContext(fileName) as string

  const name = fileName
    // ./flag/cn.svg -> flag/cn ./default/arrow.svg -> arrow
    .replace(/^\.\/(default\/)?(.*)\.svg$/g, '$2')

  SPRITES.set(name, path)
})

export default SPRITES
