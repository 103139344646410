// eslint-disable-next-line object-curly-newline
import { memoizeAsync } from 'utils-decorators'
import { BigNumberish, Contract, utils } from 'ethers'

import { NetworkService } from '@/services'
import { ERC20Abi } from '@/types/contracts'
import {
  getGasLimitWithCheckBalance,
  formatWeiHuman,
  addressToName,
} from '@/utils'


export enum ApproveTokenTypes {
  default = 'default',
  revoke = 'revoke',
  unknown = 'unknown',
}

const ERC20_INTERFACE = [
  'function name() public view returns (string)',
  'function NAME() public view returns (string)',

  'function symbol() public view returns (string)',
  'function SYMBOL() public view returns (string)',

  'function decimals() public view returns (uint256)',
  'function DECIMALS() public view returns (uint256)',

  'function transfer(address to, uint256 value) external returns (bool)',
  'function approve(address spender, uint256 value) external returns (bool)',
  'function transferFrom(address from, address to, uint256 value) external returns (bool)',
  'function totalSupply() external view returns (uint256)',
  'function balanceOf(address who) external view returns (uint256)',
  'function allowance(address owner, address spender) external view returns (uint256)',
  // 'event Transfer(address indexed from, address indexed to, uint256 value)',
  'event Approval(address indexed owner, address indexed spender, uint256 value)',
]

const ERC20_INTERFACE_BYTES32 = [
  'function name() public view returns (bytes32)',
  'function NAME() public view returns (bytes32)',

  'function symbol() public view returns (bytes32)',
  'function SYMBOL() public view returns (bytes32)',
]

const instances = new Map<string, ERC20TokenService>()

export class ERC20TokenService {
  public symbol = ''

  public decimals = 18

  public balance: string | null = null

  public allowanceForMultisender: string | null = null

  public get balanceHuman() {
    const val = this.balance
    return val ? formatWeiHuman(val, this.decimals) : ''
  }

  public get allowanceForMultisenderHuman() {
    const val = this.allowanceForMultisender
    return val ? formatWeiHuman(val, this.decimals) : ''
  }

  public get contract() {
    const provider = new NetworkService(this.chainId).rpcProvider
    return new Contract(this.address, ERC20_INTERFACE, provider) as ERC20Abi
  }

  private get contractWithBytes32() {
    const provider = new NetworkService(this.chainId).rpcProvider
    return new Contract(this.address, ERC20_INTERFACE_BYTES32, provider) as ERC20Abi
  }

  public static buildClass(
    address: string,
    chainId: number,
    ethAccount?: string | null,
  ) {
    const key = [address.toLowerCase(), chainId].join('-')
    let instance = instances.get(key)

    if (!instance) {
      instance = new ERC20TokenService(address, chainId, ethAccount)
      instances.set(key, instance)
    }

    instance.ethAccount = ethAccount

    return instance
  }

  private constructor(
    public address: string,
    public chainId: number,
    private ethAccount?: string | null,
  ) {
  }

  public async updateData() {
    const promises = [
      this.updateMeta(),
      this.updateAllowanceForMultisender(),
      this.updateBalance(),
    ] as const

    return Promise.all(promises)
  }

  public async updateMeta() {
    [this.symbol, this.decimals] = await this.getMeta(this.contract.address, this.chainId)
  }

  @memoizeAsync()
  public async getMeta(address: string, chainId: number) {
    const { contract, contractWithBytes32 } = this

    const promises = [
      contract.symbol()
        .catch(() => Promise.any([
          contract.SYMBOL(),
          contract.name(),
          contract.NAME(),
          // eslint-disable-next-line promise/no-nesting
          Promise.any([
            contractWithBytes32.symbol(),
            contractWithBytes32.SYMBOL(),
            contractWithBytes32.name(),
            contractWithBytes32.NAME(),
            // eslint-disable-next-line promise/no-nesting
          ]).then((bytes) => utils.parseBytes32String(bytes)),
        ])).catch(() => addressToName(address)),
      contract.decimals()
        .catch(() => contract.DECIMALS())
        .then((_) => +_)
        .catch(() => contract.decimals()),
      address,
    ] as const

    try {
      const [
        symbol,
        decimals,
      ] = await Promise.all(promises)

      return [
        symbol || '',
        decimals,
      ] as const
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('[App err]: error ERC20TokenService#getMeta', { chainId, address }, error)
      return Promise.reject(error)
    }
  }

  public async updateAllowanceForMultisender() {
    const networkService = new NetworkService(this.chainId)
    const address = networkService.settings?.contracts.multisenderMerkle.address

    if (!this.ethAccount || !address) return null
    const allowance = await this.contract.allowance(this.ethAccount, address)
    this.allowanceForMultisender = allowance.toString()
    return this.allowanceForMultisender
  }

  public async updateBalance() {
    if (!this.ethAccount) return null
    const balance = await this.contract.balanceOf(this.ethAccount)
    this.balance = balance.toString()
    return this.balance
  }

  public async getApproveForMultisenderType() {
    const networkService = new NetworkService(this.chainId)
    const address = networkService.settings?.contracts.multisenderMerkle.address
    if (!this.ethAccount || !address) return null
    const { contract } = this
    const overrides = { from: this.ethAccount }

    try {
      await contract.estimateGas.approve(address, '2', overrides)
      return ApproveTokenTypes.default
    } catch {
      //
    }

    try {
      await contract.estimateGas.approve(address, '0', overrides)
      return ApproveTokenTypes.revoke
    } catch {
      //
    }

    return ApproveTokenTypes.unknown
  }

  public async approveForMultisender(amount: BigNumberish, isInjectedWallet:boolean) {
    const networkService = new NetworkService(this.chainId)
    const address = networkService.settings?.contracts.multisenderMerkle.address
    if (!this.ethAccount || !address) return null

    const { gasLimit } = await getGasLimitWithCheckBalance(
      this.contract.estimateGas.approve(address, amount, { from: this.ethAccount }),
      this.contract.provider,
      this.ethAccount,
      isInjectedWallet,
    )

    const data = this.contract.interface.encodeFunctionData('approve', [address, amount]) as `0x${string}`

    return {
      data,
      gas: BigInt(gasLimit.toString()),
      to: this.contract.address as `0x${string}`,
    }
  }

  public async getApproveEvent(owner: string, spender:string, blockNumber: number) {
    const { contract } = this

    const currentBlockNumber = await contract.provider.getBlockNumber()
    const filter = contract.filters.Approval(owner, spender)
    const [event] = await contract.queryFilter(filter, blockNumber, currentBlockNumber)

    return event
  }
}
