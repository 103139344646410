import { RouteRecordRaw } from 'vue-router'
import { t } from '@/utils'
import { ROUTE_TUTORIAL } from '@/utils/constants/routes'


const createRoutes = (): RouteRecordRaw[] => [
  {
    path: '/tutorial',
    name: ROUTE_TUTORIAL,
    meta: {
      title: t('page-title.tutorial'),
    },
    component: () => import(
      /* webpackChunkName: "pages.tutorial" */
      './PageTutorial.vue'
    ),
  },
]

const createModule = () => {
  const routes = createRoutes()
  return routes
}

export default createModule
