import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MLayoutHeaderNav = _resolveComponent("MLayoutHeaderNav")!
  const _component_MBtnNetwork = _resolveComponent("MBtnNetwork")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MBtnBalance = _resolveComponent("MBtnBalance")!
  const _component_MLocaleSwitcher = _resolveComponent("MLocaleSwitcher")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$s.root, "container-main"])
  }, [
    _createVNode(_component_router_link, {
      to: "/",
      class: _normalizeClass(_ctx.$s.logo),
      name: "logo"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: require('@/assets/images/logo.svg'),
          class: _normalizeClass(_ctx.$s.logoImage),
          width: "154",
          height: "32",
          alt: "logo"
        }, null, 10, _hoisted_1)
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_MLayoutHeaderNav, {
      class: _normalizeClass(_ctx.$s.nav)
    }, null, 8, ["class"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$s.features)
    }, [
      _createVNode(_component_MBtnNetwork, {
        class: _normalizeClass(_ctx.$s.btnNetwork),
        "aria-label": _ctx.$t('aria.switch-btn')
      }, null, 8, ["class", "aria-label"]),
      (!_ctx.isConnected)
        ? (_openBlock(), _createBlock(_component_MButton, {
            key: 0,
            type: "outline",
            class: _normalizeClass(_ctx.$s.btnConnect),
            text: _ctx.$t('button.connect'),
            name: "header-connect",
            onClick: _ctx.onConnect
          }, null, 8, ["class", "text", "onClick"]))
        : (_openBlock(), _createBlock(_component_MBtnBalance, {
            key: 1,
            class: _normalizeClass(_ctx.$s.btnBalance),
            "aria-label": _ctx.$t('aria.account')
          }, null, 8, ["class", "aria-label"])),
      _createVNode(_component_MLocaleSwitcher, {
        class: _normalizeClass(_ctx.$s.switcher)
      }, null, 8, ["class"])
    ], 2)
  ], 2))
}