import { utils } from 'ethers'
import trim from 'lodash/trim'

const POSSIBLE_ID_COLUMN_NAMES = [
  'amount',
  'token',
]

const POSSIBLE_ADDRESS_COLUMN_NAMES = [
  'address',
  'recipient',
  'account',
]

const DEFAULT_ADDRESS_COLUMN_INDEX = 0
const DEFAULT_ADDRESS_ID_INDEX = 1

const formatColumnName = (columnName: string | number = '') => (
  String(columnName)
    .replace(/ /g, '')
    .toLowerCase()
)

const getIndex = (searchArray: string[], array: (string | number)[]) => (
  array.findIndex((el) => {
    const value = formatColumnName(el)
    return searchArray.includes(value)
  })
)

const uploadLongArray = (columns: (string | number)[], list: (string | number)[][]) => {
  const filledColumns = columns.filter(Boolean)
  let indexOfAddress = getIndex(POSSIBLE_ADDRESS_COLUMN_NAMES, filledColumns)
  let indexOfTokenId = getIndex(POSSIBLE_ID_COLUMN_NAMES, filledColumns)

  if (indexOfAddress === -1) {
    indexOfAddress = DEFAULT_ADDRESS_COLUMN_INDEX
  }

  if (indexOfTokenId === -1) {
    indexOfTokenId = DEFAULT_ADDRESS_ID_INDEX
  }

  return list.map((row) => {
    const filledRow = row.filter(Boolean)

    const result = [
      (filledRow[indexOfAddress] || '').toString(),
      (filledRow[indexOfTokenId] || '').toString(),
    ].filter(Boolean) as [string, string]

    return result
  })
}

const filterEmptyData = (data: [string, string][]) => (
  // empty lines: [''] -> ''
  data.filter((_) => Boolean(_.toString()))
)

export const parseData = (rawData: [string, string | number][] | string, filtered: boolean) => {
  if (typeof rawData === 'string') return []

  const data = rawData.map((arr) => arr.map((_) => trim(_.toString()))) as [string, string][]

  const [columns, ...values] = filtered ? filterEmptyData(data) : data
  if (!columns) return []

  const indexOfAddress = getIndex(POSSIBLE_ADDRESS_COLUMN_NAMES, columns)
  const addressFirst = columns[DEFAULT_ADDRESS_COLUMN_INDEX]

  const isAddressValueInColumnsRow = (
    indexOfAddress === -1
    && utils.isAddress(addressFirst.toString())
  )

  const recipients = isAddressValueInColumnsRow ? [columns, ...values] : values

  if (columns.length > 3) {
    return uploadLongArray(columns, recipients)
  }

  const [address, amount] = columns

  const isAddress = address && POSSIBLE_ADDRESS_COLUMN_NAMES.includes(formatColumnName(address))
  const isAmount = amount && POSSIBLE_ID_COLUMN_NAMES.includes(formatColumnName(amount))

  if (isAddress || isAmount) {
    return recipients
  }

  return [columns, ...values]
}
