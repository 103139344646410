import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, Transition as _Transition } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_MMessage = _resolveComponent("MMessage")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "transition--fade-slow",
    appear: ""
  }, {
    default: _withCtx(() => [
      (_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$s.root)
          }, [
            _createVNode(_component_MMessage, {
              type: "info",
              action: "",
              "action-text": _ctx.version ? _ctx.$t('button.refresh') : _ctx.$t('button.close'),
              class: _normalizeClass(_ctx.$s.message),
              onAction: _ctx.onRefresh
            }, {
              default: _withCtx(() => [
                (_ctx.version)
                  ? (_openBlock(), _createBlock(_component_i18n_t, {
                      key: 0,
                      keypath: "common.version-available",
                      tag: "span"
                    }, {
                      version: _withCtx(() => [
                        (_ctx.version)
                          ? (_openBlock(), _createElementBlock("strong", {
                              key: 0,
                              textContent: _toDisplayString(_ctx.version)
                            }, null, 8, _hoisted_1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      textContent: _toDisplayString(_ctx.$t('common.version-updated'))
                    }, null, 8, _hoisted_2))
              ]),
              _: 1
            }, 8, ["action-text", "class", "onAction"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}