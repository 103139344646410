/* eslint no-console: 0 */

import { register } from 'register-service-worker'
import {
  SW_UPDATE_FOUND_EVENT,
  SW_UPDATED_EVENT,
  SW_POLLING_TIMEOUT,
} from './utils'


const IN_BROWSER = typeof window !== 'undefined'
// const IS_PROD = process.env.NODE_ENV === 'production'
const isWithSW = IN_BROWSER && navigator.serviceWorker

if (isWithSW) {
  // let refreshing: boolean
  let revision: Record<string, string> | void = void 0

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log([
        '[App sw]: App is being served from cache by a service worker.',
        'For more details, visit https://goo.gl/AFskqB',
      ].join(' '))
    },

    registered(registration) {
    // eslint-disable-next-line no-console
      console.log('[App sw]: Service worker has been registered.')

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        void registration.update()
      }, SW_POLLING_TIMEOUT)
    },

    cached() {
    // eslint-disable-next-line no-console
      console.log('[App sw]: Content has been cached for offline use.')
    },

    updatefound() {
    // eslint-disable-next-line no-console
      console.log('[App sw]: New content is downloading...')
    },

    async updated(registration) {
    // eslint-disable-next-line no-console
      console.log('[App sw]: New content is available; please refresh.')

      revision = await fetch(`${process.env.BASE_URL}revision.json`)
        .then((response) => response.json())
        .catch(() => void 0) as Record<string, string>

      const detail = { registration, revision }
      const event = new CustomEvent(SW_UPDATE_FOUND_EVENT, { detail })

      document.dispatchEvent(event)
    },

    offline() {
    // eslint-disable-next-line no-console
      console.log('[App sw]: No internet connection found. App is running in offline mode.')
    },

    error(error) {
    // eslint-disable-next-line no-console
      console.error('[App sw]: Error during service worker registration:', error)
    },
  })

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    console.log('[App sw]: controllerchange')

    const detail = {}
    const event = new CustomEvent(SW_UPDATED_EVENT, { detail })

    document.dispatchEvent(event)

    // if (refreshing) return
    // it need when skipWaiting === false in settings and app.js has not have hash
    // window.location.reload()

    // refreshing = true
  })
}
