<template>
  <transition name="transition--fade-slow" appear>
    <div v-if="isActive" :class="$s.root">
      <MMessage
        type="info"
        action
        :action-text="version ? $t('button.refresh') : $t('button.close')"
        :class="$s.message"
        @action="onRefresh"
      >
        <template v-if="version">
          <i18n-t keypath="common.version-available" tag="span">
            <template #version>
              <strong v-if="version" v-text="version" />
            </template>
          </i18n-t>
        </template>

        <span
          v-else
          v-text="$t('common.version-updated')"
        />
      </MMessage>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ServiceWorkerService } from '@/services'
import MMessage from '@/components/ui/MMessage.vue'


export default defineComponent({
  components: {
    MMessage,
  },
  setup: () => {
    const {
      isActive,
      version,
      refresh,
    } = ServiceWorkerService.useServiceWorker()

    return {
      isActive,
      version,
      onRefresh: () => refresh(),
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: flex;
  align-items: center;
  align-self: end;
  justify-content: space-between;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.3);
}

.message {
  width: 100%;
}
</style>
