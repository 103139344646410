

const isOS = () => (
  navigator.userAgent.match(/ipad|iphone/i)
)

const createTextarea = (text: string) => {
  const textarea = document.createElement('textarea')
  const { contentEditable, readOnly } = textarea

  textarea.contentEditable = 'true'
  textarea.readOnly = false
  textarea.value = text
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999rem'
  document.body.appendChild(textarea)

  return {
    textarea,
    contentEditable,
    readOnly,
  }
}

const selectText = (options: ReturnType<typeof createTextarea>) => {
  let range: Range
  let selection: Selection | null
  const { textarea } = options

  if (isOS()) {
    range = document.createRange()
    range.selectNodeContents(textarea)
    selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)

    textarea.setSelectionRange(0, 999999)
    textarea.contentEditable = options.contentEditable
    textarea.readOnly = options.readOnly
  } else {
    textarea.select()
  }
}

const copyClipboard = (options: ReturnType<typeof createTextarea>) => {
  document.execCommand('copy')
  document.body.removeChild(options.textarea)
}

export const copyToClipboard = (value: string) => {
  const result = createTextarea(value)
  selectText(result)
  copyClipboard(result)
}
