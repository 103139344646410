<template>
  <div :class="$s.root">
    <div :class="$s.container">
      <transition-group name="transition--fade" appear>
        <MNotice
          v-for="item in notices"
          :key="item.id"
          :data="item"
          :class="$s.notice"
        />
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useNotices } from '@/composable'

import MNotice from './components/MNotice.vue'


export default defineComponent({
  components: {
    MNotice,
  },
  setup: () => {
    const { notices } = useNotices()

    return {
      notices,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  position: relative;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: auto;

  @include media-lt(tablet) {
    width: 100%;
  }

  body:global(.app-modal-root--opened) & {
    padding: $layout-x-padding-small 0;
  }

  @include media-gte(tablet) {
    @include scroll-vertical;

    // position: absolute;
    align-items: center;
    max-height: 100vh;
    padding: $layout-x-padding-small 0;
  }
}

.notice {
  margin-bottom: 1rem;
  pointer-events: all;
}
</style>
