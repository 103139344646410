import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MMenuLinks = _resolveComponent("MMenuLinks")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBtnBurger = _resolveComponent("MBtnBurger")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      class: _normalizeClass([[_ctx.$s.root, _ctx.$attrs.class], "is-desktop"])
    }, [
      _createVNode(_component_MMenuLinks, {
        label: _ctx.$t('menu-main.products'),
        options: _ctx.productOptions,
        class: _normalizeClass(_ctx.$s.link),
        name: "menu-products",
        hover: ""
      }, null, 8, ["label", "options", "class"]),
      _createVNode(_component_MButton, {
        type: "link",
        size: "small",
        to: _ctx.menuAirdrops.to,
        text: _ctx.$t(_ctx.menuAirdrops.label),
        class: _normalizeClass(_ctx.$s.link),
        name: "menu-airdrops"
      }, null, 8, ["to", "text", "class"]),
      _createVNode(_component_MMenuLinks, {
        label: _ctx.$t('menu-main.information'),
        active: _ctx.informationActive,
        options: _ctx.informationOptions,
        class: _normalizeClass(_ctx.$s.link),
        name: "menu-info",
        hover: ""
      }, null, 8, ["label", "active", "options", "class"])
    ], 2),
    _createElementVNode("nav", {
      class: _normalizeClass([[
      _ctx.$s.root,
      _ctx.$attrs.class,
      {
        'is-active': _ctx.isActiveNav,
      },
    ], "is-mobile"])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.id
        }, [
          (item.to)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: item.to,
                class: _normalizeClass(_ctx.$s.linkMobile),
                name: `menu-mobile--${item.id}`,
                onClick: _ctx.onClose
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$s.linkMobileText),
                    textContent: _toDisplayString(_ctx.$t(item.label))
                  }, null, 10, _hoisted_1)
                ]),
                _: 2
              }, 1032, ["to", "class", "name", "onClick"]))
            : (_openBlock(), _createBlock(_component_MButton, {
                key: 1,
                type: "link",
                href: item.href,
                blank: item.blank,
                active: item.active,
                class: _normalizeClass([_ctx.$s.linkMobile, "is-external"]),
                name: `mobile-menu--${item.id}`,
                external: "",
                onClick: _ctx.onClose
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass([_ctx.$s.linkMobileText, "is-external"]),
                    textContent: _toDisplayString(_ctx.$t(item.label))
                  }, null, 10, _hoisted_2)
                ]),
                _: 2
              }, 1032, ["href", "blank", "active", "class", "name", "onClick"]))
        ], 64))
      }), 128))
    ], 2),
    _createVNode(_component_MBtnBurger, {
      active: _ctx.isActiveNav,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActiveNav) = $event)),
      class: _normalizeClass(_ctx.$s.burger),
      "aria-label": _ctx.$t('aria.open-menu')
    }, null, 8, ["active", "class", "aria-label"])
  ], 64))
}