import { utils } from 'ethers'
import { formatWeiHuman } from '@/utils'
import { MerkleProofAirdropAbi } from '@/types/contracts'


export const toHash = (value: string) => (
  // utils.keccak256(utils.toUtf8Bytes(value))
  utils.id(value)
)

export const transformAirdrop = (
  data: Awaited<ReturnType<MerkleProofAirdropAbi['airdrops']>>,
  decimals: number,
) => ({
  owner: data.owner,
  root: data.root,
  tokenAddress: data.tokenAddress,
  cancelled: data.cancelled,

  claimed: data.claimed.toString(),
  claimedHuman: formatWeiHuman(data.claimed, decimals),
  total: data.total.toString(),
  totalHuman: formatWeiHuman(data.total, decimals),
})

// const iface = new utils.Interface(MerkleProofAirdropAbi__factory.abi)
// console.log(iface.format(utils.FormatTypes.full))
export const MERKLE_PROOF_AIRDROP_INTERFACE = [
  'event AirdropCancelled(string airdropName)',
  'event AirdropCreated(string airdropName, address indexed creator, bytes32 root, address tokenAddress, uint256 total)',
  'event Claim(string indexed airdropName, address indexed recipient, uint256 amount)',
  // 'event ClaimedTokens(address token, address owner, uint256 amount)',
  // 'event FeeUpdated(uint256 newFee)',
  // 'event OwnershipTransferred(address indexed previousOwner, address indexed newOwner)',
  'function airdrops(bytes32) view returns (address owner, bytes32 root, address tokenAddress, uint256 total, uint256 claimed, bool cancelled)',
  'function cancelAirdrop(string airdropName)',
  'function claim(bytes32[] proof, address recipient, uint256 amount, string airdropName)',
  // 'function claimTokens(address token, uint256 amount)',
  'function createAirdrop(string airdropName, bytes32 root, address tokenAddress, uint256 total) payable',
  'function fee() view returns (uint256)',
  // 'function initialize(uint256 _fee, address owner)',
  'function isClaimed(bytes32, address) view returns (bool)',
  // 'function owner() view returns (address)',
  // 'function renounceOwnership()',
  // 'function setFee(uint256 _fee)',
  // 'function tokenFallback(address _from, uint256 _value, bytes _data)',
  // 'function transferOwnership(address newOwner)',
  // 'function verify(bytes32[] proof, bytes32 root, bytes32 leaf) pure returns (bool)',
  // 'function verifyProofs(uint256 proofLength, bytes32[] proofs, bytes32 root, bytes32[] leafs) pure returns (bool)',
]
