<template>
  <div :class="$s.root" class="container-main">
    <div :class="$s.address">
      <h5
        :class="$s.addressText"
        v-text="$t('common.multisender-address')"
      />

      <MTooltip>
        <template #activator="{ active, on }">
          <MIcon
            name="info"
            size="medium"
            sprite
            :class="[$s.addressIcon, {
              'is-active': active,
            }]"
            v-on="on"
          />
        </template>

        <template #content>
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <div>{{ networkName }}:</div>

          <MExplorerLink
            type="multisender"
            :chain-id="chainId"
            invert
          />
        </template>
      </MTooltip>
    </div>

    <span
      :class="$s.version"
      v-text="version"
    />

    <div :class="$s.right">
      <MLayoutSocials
        v-once
        :class="$s.socials"
      />

      <MLocaleSwitcher
        :class="$s.switcher"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAppStore } from '@/composable'
import { APP_VERSION } from '@/utils/constants/params'

import MIcon from '@/components/ui/MIcon.vue'
import MTooltip from '@/components/ui/MTooltip.vue'
import MExplorerLink from '@/components/MExplorerLink.vue'

import MLayoutSocials from './components/MLayoutSocials.vue'
import MLocaleSwitcher from './components/MLocaleSwitcher.vue'


export default defineComponent({
  components: {
    MIcon,
    MTooltip,
    MExplorerLink,
    MLayoutSocials,
    MLocaleSwitcher,
  },
  setup: () => {
    const appStore = useAppStore()

    return {
      version: APP_VERSION,
      chainId: appStore.chainId,
      networkName: appStore.networkName,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: $footer-height-mobile;
  font-weight: $font-weight-bold;
  color: $color-text-secondary;

  @include media-lt(tablet) {
    flex-direction: column;
    margin-bottom: $footer-height-mobile;
  }
}

.address,
.version,
.right {
  @include media-lt(tablet) {
    padding: 1rem 0;
  }
}

.address {
  display: flex;
  align-items: center;
}

.address-icon {
  margin-left: 0.6rem;

  &:global(.active) {
    color: $color-primary;
  }
}

.version {
  @include media-lt(tablet) {
    order: 1;
  }
}

.right {
  display: flex;
  align-items: center;
}

.switcher {
  padding-left: 2rem;
  margin-left: 2rem;
  border-left: 0.1rem solid $color-border-2;

  @include media-lt(tablet) {
    display: none;
  }
}
</style>
