<template>
  <MMenu
    ref="menuRef"
    :options="options"
    option-id="id"
    :position="position"
    :disabled="disabled"
    :menu-class="$s.menuClass"
    :name="name"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
  >
    <template #button="props">
      <slot
        name="button"
        v-bind="props"
        :loading="isLoading || loading"
        :on-toggle-loading="onToggleLoading"
      >
        <MButton
          type="link"
          size="small"
          :readonly="disabled"
          :class="[$s.button, {
            'is-active': active,
          }]"
          :name="`${name}--open`"
          @mouseenter="onMouseenter"
          @click="props.onToggle"
        >
          <slot name="label">
            {{ label }}
          </slot>
          <MIcon
            name="chevron-up"
            size="medium"
            sprite
            :class="[$s.icon, {
              'is-loading': isLoading || loading,
            }]"
          />
        </MButton>
      </slot>
    </template>

    <template #option="{ data, onClose }">
      <MButton
        type="link"
        :href="data.href"
        :blank="data.blank"
        :to="data.to"
        :class="[$s.optionButton, {
          'is-external': data.href,
        }]"
        :disabled="disabled"
        full-width
        :active="data.active || data.id === modelValue?.id"
        :text="$te(data.label) ? $t(data.label) : data.label"
        :name="`${name}--${data.id}`"
        @click="onClick(data, onClose)"
      />
    </template>
  </MMenu>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue'
import { useBreakpoints } from '@/composable'

import MIcon from '@/components/ui/MIcon.vue'
import MButton from '@/components/ui/MButton.vue'
import MMenu from '@/components/ui/MMenu.vue'


export type IMenuLinkOption = {
  id: string;
  label: string;
  to?: { name: string } | string;
  href?: string;
  blank?: boolean;
  action?: (data: IMenuLinkOption, onToggleLoading: () => void) => void;
}

export default defineComponent({
  components: {
    MIcon,
    MButton,
    MMenu,
  },
  props: {
    disabled: Boolean,
    loading: Boolean,
    active: Boolean,
    hover: Boolean,
    name: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'bottom left',
    },
    label: {
      type: String,
    },
    modelValue: {
      type: Object as PropType<IMenuLinkOption>,
    },
    options: {
      type: Array as PropType<IMenuLinkOption[]>,
      required: true,
      validator: ([prop]: IMenuLinkOption[]) => (
        prop
        && 'id' in prop
        && 'label' in prop
        && ('to' in prop || 'href' in prop || 'action' in prop)
        && ('action' in prop ? prop.action instanceof Function : true)
      ),
    },
  },
  emits: ['update:model-value'],
  setup: (props, ctx) => {
    const { isTablet } = useBreakpoints()
    const menuRef = ref<InstanceType<typeof MMenu>>()
    const isLoading = ref(false)

    const onToggleLoading = () => {
      isLoading.value = !isLoading.value
    }

    const onClick = (data: IMenuLinkOption, onClose: () => void) => {
      data.action?.(data, onToggleLoading)
      ctx.emit('update:model-value', data, onToggleLoading)
      onClose()
    }

    const onMouseenter = () => {
      if (isTablet.value || !props.hover) return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      void menuRef.value?.onOpen()
    }

    const onMouseleave = () => {
      if (isTablet.value || !props.hover) return
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      void menuRef.value?.onClose()
    }

    return {
      menuRef,
      isLoading,

      onToggleLoading,
      onClick,
      onMouseenter,
      onMouseleave,
    }
  },
})
</script>

<style lang="scss" module="$s">
.button {
  &:global(.is-active) {
    color: $color-white;
  }
}

.icon {
  position: relative;
  margin-left: 0.4rem;
  transform: rotate(180deg);

  &:global(.is-loading) {
    &::before {
      @include loading($color-white, 1.6rem);
    }
  }
}

.menu-class {
  &:global(.bottom.left) {
    top: calc(100% + 0.4rem) !important;
    left: 1rem !important;
  }
}

.option-button {
  justify-content: start !important;
  min-width: auto !important;
  height: 3rem !important;
  padding: 0.8rem 1.6rem !important;
  white-space: nowrap;

  &:global(.is-active),
  &:global(.router-link-active) {
    color: $color-white;
    cursor: default;
  }

  &:global(.is-external:not(.is-active))::after {
    display: inline;
    margin-left: 0.6em;
    content: "↗";
  }
}
</style>
