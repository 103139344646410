/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export const LOCALE_EN = {
  id: 'en',
  icon: 'flags/en',
  image: require('@/assets/images/flags/en.png'),
  label: 'EN',
} as const

export const LOCALE_RU = {
  id: 'ru',
  icon: 'flags/ru',
  image: require('@/assets/images/flags/ru.png'),
  label: 'RU',
} as const

export const LOCALE_ZH = {
  id: 'zh',
  icon: 'flags/zh',
  image: require('@/assets/images/flags/zh.png'),
  label: 'CN',
} as const

export const LOCALE_LIST = [
  LOCALE_EN,
  LOCALE_RU,
  LOCALE_ZH,
]
