export enum StepTypes {
  preparation = 1,
  approving = 2,
  distribution = 3,
}

export const STEPPER_OPTIONS = [
  {
    id: StepTypes.preparation,
    label: 'page-multisender-stepper.prepare',
  },
  {
    id: StepTypes.approving,
    label: 'page-multisender-stepper.approve',
  },
  {
    id: StepTypes.distribution,
    label: 'page-multisender-stepper.massdrop',
  },
]

export const EXAMPLES_OPTIONS = [
  {
    id: 'scv',
    href: '/examples/exampleERC20.csv',
    download: 'multisender_ERC20_example',
    text: 'page-multisender-example.csv',
  },
  {
    id: 'xlsx',
    href: '/examples/exampleERC20.xlsx',
    download: 'multisender_ERC20_example',
    text: 'page-multisender-example.xlsx',
  },
]
