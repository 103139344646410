/* eslint-disable no-underscore-dangle */

import { ref } from 'vue'
import uniqueId from 'lodash/uniqueId'
import { getTransactionError } from '@/utils'


type IClose = () => void

enum NoticeTypes {
  info = 'info',

  error = 'danger',
  warning = 'warning',
}

export type INoticeOptions = {
  id?: string;
  text: string;
  action?: (close?: IClose) => void;
  type?: NoticeTypes;
  timeout?: number;
}

export type INotice = INoticeOptions & {
  id: string;
  type: NoticeTypes;
  _timerId?: ReturnType<typeof setTimeout>;
}

const notices = ref<INotice[]>([])

const ERROR_DEFAULT_TIMEOUT = 10_000

const close = (id: string) => {
  const index = notices.value.findIndex((_) => _.id === id)
  if (index !== -1) notices.value.splice(index, 1)
}

const create = (value: string | INoticeOptions) => {
  const id = uniqueId()

  const data: INotice = typeof value === 'string'
    ? {
      id,
      text: value,
      type: NoticeTypes.info,
    }
    : {
      id,
      type: NoticeTypes.info,
      ...value,
    }

  const exist = notices.value.find((_) => _.id === data.id)

  if (!exist) {
    notices.value.push(data)

    if (data.timeout) {
      data._timerId = setTimeout(close, data.timeout, data.id)
    }
  } else if (exist._timerId) {
    clearTimeout(exist._timerId)
    exist._timerId = setTimeout(close, exist.timeout, exist.id)
  }
}

const createErrorResponse = (error: string | INoticeOptions | unknown) => {
  // eslint-disable-next-line no-console
  console.warn('[App err]: notice error', error)

  if (!error) return null

  if (typeof error === 'string') {
    const data = {
      id: error,
      text: error,
      type: NoticeTypes.error,
      timeout: ERROR_DEFAULT_TIMEOUT,
    }
    return create(data)
  }

  if (error && 'text' in (error as INoticeOptions)) {
    const data = {
      id: (error as INoticeOptions).text,
      type: NoticeTypes.error,
      ...(error as INoticeOptions),
    }

    return create(data)
  }

  const text = getTransactionError(error)

  const data = {
    id: text,
    type: NoticeTypes.error,
    text,
    timeout: ERROR_DEFAULT_TIMEOUT,
  }

  return create(data)
}

export const useNotices = () => ({
  notices,

  create,
  createErrorResponse,
  close,
})
