import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, setBlockTracking as _setBlockTracking, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MExplorerLink = _resolveComponent("MExplorerLink")!
  const _component_MTooltip = _resolveComponent("MTooltip")!
  const _component_MLayoutSocials = _resolveComponent("MLayoutSocials")!
  const _component_MLocaleSwitcher = _resolveComponent("MLocaleSwitcher")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.$s.root, "container-main"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$s.address)
    }, [
      _createElementVNode("h5", {
        class: _normalizeClass(_ctx.$s.addressText),
        textContent: _toDisplayString(_ctx.$t('common.multisender-address'))
      }, null, 10, _hoisted_1),
      _createVNode(_component_MTooltip, null, {
        activator: _withCtx(({ active, on }) => [
          _createVNode(_component_MIcon, _mergeProps({
            name: "info",
            size: "medium",
            sprite: "",
            class: [_ctx.$s.addressIcon, {
              'is-active': active,
            }]
          }, _toHandlers(on)), null, 16, ["class"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.networkName) + ":", 1),
          _createVNode(_component_MExplorerLink, {
            type: "multisender",
            "chain-id": _ctx.chainId,
            invert: ""
          }, null, 8, ["chain-id"])
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$s.version),
      textContent: _toDisplayString(_ctx.version)
    }, null, 10, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$s.right)
    }, [
      _cache[0] || (
        _setBlockTracking(-1),
        _cache[0] = _createVNode(_component_MLayoutSocials, {
          class: _normalizeClass(_ctx.$s.socials)
        }, null, 8, ["class"]),
        _setBlockTracking(1),
        _cache[0]
      ),
      _createVNode(_component_MLocaleSwitcher, {
        class: _normalizeClass(_ctx.$s.switcher)
      }, null, 8, ["class"])
    ], 2)
  ], 2))
}