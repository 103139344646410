<template>
  <span
    v-if="src"
    :key="name"
    :class="[
      $s.root,
      isSvg && `is-icon--${name}`,
      `is-size--${size}`,
    ]"
  >
    <template v-if="isSvg">
      <img
        v-if="sprite"
        v-svg-inline.sprite
        :src="src"
      >

      <img
        v-else
        v-svg-inline
        :src="src"
      >
    </template>
    <img v-else :src="src">
  </span>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue'
import SPRITES from '@/assets/images/sprites/index'


const SIZES = [
  'inherit',
  'default',
  'extra-medium',
  'medium',
  'small',
  'large',
  'xlarge',
  'xl',
  'fill',
  'auto',
  'symbol',
] as const

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
      validator: (value: string) => (
        SPRITES.has(value) || value.includes('data:image')
      ),
    },
    size: {
      type: String as PropType<typeof SIZES[number]>,
      default: 'default',
      validator: (value: typeof SIZES[number]) => (
        SIZES.includes(value)
      ),
    },
    sprite: {
      type: Boolean,
      // default: true,
    },
  },
  setup: (props) => {
    const src = computed(() => SPRITES.get(props.name) || props.name)
    const isSvg = computed(() => !src.value.includes('data:image'))

    return {
      isSvg,
      src,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  fill: currentColor;

  img {
    width: inherit;
    height: inherit;
  }

  svg {
    display: block;
    width: inherit;
    height: inherit;
  }

  &:global {
    &.is-size--default {
      width: 2.4rem;
      height: 2.4rem;
    }

    &.is-size--small {
      width: 1.4rem;
      height: 1.4rem;
    }

    &.is-size--medium {
      width: 1.6rem;
      height: 1.6rem;
    }

    &.is-size--extra-medium {
      width: 2rem;
      height: 2rem;
    }

    &.is-size--large {
      width: 3.2rem;
      height: 3.2rem;
    }

    &.is-size--xlarge {
      width: 4.4rem;
      height: 4.4rem;
    }

    &.is-size--xl {
      width: 6rem;
      height: 6rem;
    }

    &.is-size--fill {
      width: inherit;
      height: inherit;
    }

    &.is-size--auto {
      width: auto;
      height: 100%;
    }

    &.is-size--symbol {
      width: 2.4rem;
      height: auto;
    }
  }
}
</style>
