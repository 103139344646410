<template>
  <MMessage
    :type="data.type"
    :text="$t(data.text)"
    :action="!!data.action"
    :action-mobile-to-bottom="actionToBottom"
    action-text="Submit"
    @action="onAction"
  >
    <template v-if="!data.action" #action>
      <MButton
        size="symbol"
        type="ghost"
        name="notice-close"
        @click="onClose"
      >
        <MIcon
          name="close"
          size="medium"
          sprite
        />
      </MButton>
    </template>
  </MMessage>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { PropType, computed, defineComponent } from 'vue'

import { useNotices, INotice } from '@/composable'

import MIcon from '@/components/ui/MIcon.vue'
import MButton from '@/components/ui/MButton.vue'
import MMessage from '@/components/ui/MMessage.vue'


const MAX_TEXT_LENGTH_WITH_ACTION_BOTTOM = 80

export default defineComponent({
  components: {
    MMessage,
    MButton,
    MIcon,
  },
  props: {
    data: {
      type: Object as PropType<INotice>,
      required: true,
      validator: (prop: INotice) => ('text' in prop && 'type' in prop),
    },
  },
  emits: ['close'],
  setup: (props) => {
    const { close } = useNotices()
    const { t } = useI18n()

    const actionToBottom = computed(() => Boolean(
      props.data.action
      && t(props.data.text).length > MAX_TEXT_LENGTH_WITH_ACTION_BOTTOM,
    ))

    const onClose = () => {
      close(props.data.id)
    }

    const onAction = () => {
      const { action } = props.data
      if (!action) return
      action(onClose)
    }

    return {
      actionToBottom,

      onAction,
      onClose,
    }
  },
})
</script>
