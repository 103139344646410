<template>
  <transition name="transition--fade" appear>
    <div :class="$s.root">
      <div
        :class="[$s.backdrop, {
          'is-backdrop-close': !noBackdropDismiss,
        }]"
        @click="onCloseBackdrop"
      />

      <transition name="transition--fade" appear>
        <div v-if="isActive" :class="$s.wrapper">
          <slot
            v-bind="$props"
            :on-close="onClose"
          >
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>place for content</span>
          </slot>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import { NavigationGuardWithThis, useRouter, Router } from 'vue-router'
import { BlockBodyService } from '@/services'
import { IModalBaseProps } from './types'


type IModalPropsData = Required<IModalBaseProps>

const keydownListeners = {
  list: [] as Array<(event: KeyboardEvent) => void>,

  add(callback: (event: KeyboardEvent) => void) {
    this.list.push(callback)

    if (this.list.length === 1) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.addEventListener('keydown', this.listener)
    }
  },

  remove(callback: (event: KeyboardEvent) => void) {
    const index = this.list.indexOf(callback)
    if (index > -1) this.list.splice(index, 1)

    if (this.list.length === 0) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      window.addEventListener('keydown', this.listener)
    }
  },

  listener: (event: KeyboardEvent) => {
    const { list } = keydownListeners
    // only last !!!
    const callback = list[list.length - 1]
    if (callback) callback(event)
  },
}

const routeDismisses = {
  list: [] as Array<NavigationGuardWithThis<unknown>>,

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unwatch: () => {},

  add(callback: NavigationGuardWithThis<unknown>, router: Router) {
    this.list.push(callback)

    if (this.list.length === 1) {
      this.unwatch = router.beforeResolve(this.listener)
    }
  },

  remove(callback: NavigationGuardWithThis<unknown>) {
    const index = this.list.indexOf(callback)
    if (index > -1) this.list.splice(index, 1)

    if (this.list.length === 0) {
      this.unwatch()
    }
  },

  listener: ((to, from, next) => {
    const { list } = routeDismisses
    // only last !!!
    const callback = list[list.length - 1]
    if (callback) void callback(to, from, next)
  }) as NavigationGuardWithThis<unknown>,
}

export default defineComponent({
  inheritAttrs: false,
  props: {
    noBackdropDismiss: Boolean as PropType<IModalPropsData['noBackdropDismiss']>,
    noRouteDismiss: Boolean as PropType<IModalPropsData['noRouteDismiss']>,
    noEscDismiss: Boolean as PropType<IModalPropsData['noEscDismiss']>,
    maximized: Boolean as PropType<IModalPropsData['maximized']>,
    active: {
      type: Boolean,
      default: true,
    },
    timeoutClose: {
      type: Number,
      default: 200,
    },
    close: {
      type: Function as PropType<IModalPropsData['close']>,
      required: true,
    },
  },
  expose: ['e$close'],
  setup(props) {
    const isActive = ref(true)
    const router = useRouter()
    const blockBodyService = new BlockBodyService([
      'app-modal-root--opened',
    ])

    const onClose = (...args: unknown[]) => {
      isActive.value = false
      setTimeout(() => {
        props.close(...args)
      }, props.timeoutClose)
    }

    const onCloseBackdrop = () => {
      if (props.noBackdropDismiss) return
      onClose()
    }

    const routeDismissesGuard: NavigationGuardWithThis<unknown> = () => {
      onClose()
      return false
    }

    routeDismisses.add(routeDismissesGuard, router)
    onBeforeUnmount(() => routeDismisses.remove(routeDismissesGuard))

    if (!props.noEscDismiss) {
      const closeOnEsc = (event: KeyboardEvent) => {
        if (event.keyCode === 27) {
          onClose()
        }
      }

      onMounted(() => {
        keydownListeners.add(closeOnEsc)
      })

      onBeforeUnmount(() => {
        keydownListeners.remove(closeOnEsc)
      })
    }

    watch(() => props.active, () => onClose())

    onMounted(() => blockBodyService.block())
    onBeforeUnmount(() => blockBodyService.unblock())

    return {
      e$close: onClose,

      isActive,
      onClose,
      onCloseBackdrop,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-modal-container;
  display: table;
  width: 100%;
  height: 100%;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-bg-modal-backdrop;
  backdrop-filter: blur(0.3rem);

  &:global(.is-backdrop-close) {
    cursor: pointer;
  }

  @supports not (backdrop-filter: blur(0.3rem)) {
    background-color: $color-bg-modal-backdrop-dark;
  }
}

:global(.app-modal-root) {
  &:not(:last-child) :local(.wrapper) {
    opacity: 0;
  }

  & + & :local(.backdrop) {
    backdrop-filter: none;
    background-color: transparent;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
</style>
