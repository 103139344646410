<template>
  <component
    :is="to ? 'router-link' : href ? 'a' : 'button'"
    ref="componentRef"
    :to="to"
    :target="href && blank !== false ? '_blank' : null"
    :rel="href ? 'noopener noreferrer nofollow' : null"
    v-bind="$attrs"
    :type="to || href ? null : nativeType"
    :disabled="to || href ? null : disabled"
    :readonly="readonly"
    :class="[
      $s.root,
      `is-type--${type}`,
      `is-size--${size}`,
      {
        [$s.fullWidth]: fullWidth,
        [$s.loading]: loading || isLoading,
        'is-active': active,
        'is-disabled': disabled,
        'is-readonly': readonly,
      },
    ]"
    :name="name"
    @click="onClick"
  >
    <slot>{{ text }}</slot>
  </component>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  onMounted,
} from 'vue'


const TYPES = [
  'default-type',
  'link',
  'link-icon',
  'ghost',
  'outline',
  'warning-ghost',
  'danger',
] as const

const SIZES = [
  'default-size',
  'large',
  'medium',
  'small',
  'mini',
  'symbol',
] as const

export default defineComponent({
  inheritAttrs: false,
  props: {
    to: [String, Object],
    href: String,
    text: String,
    fullWidth: Boolean,
    loading: Boolean,
    active: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    blank: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<typeof TYPES[number]>,
      default: TYPES[0],
      validator: (value: typeof TYPES[number]) => (
        TYPES.includes(value)
      ),
    },
    nativeType: {
      type: String,
      default: 'button',
    },
    size: {
      type: String as PropType<typeof SIZES[number]>,
      default: SIZES[0],
      validator: (value: typeof SIZES[number]) => (
        SIZES.includes(value)
      ),
    },
  },
  emits: {
    click: (toggleLoading: () => void) => !!toggleLoading,
  },
  setup: (props, ctx) => {
    const componentRef = ref<HTMLLinkElement>()
    const isLoading = ref(false)

    const toggleLoading = () => {
      isLoading.value = !isLoading.value
    }

    const onClick = (event: Event) => {
      const isDisabledClick = (
        false
        || props.disabled
        || props.loading
        || props.readonly
        || props.active
      )

      if (isDisabledClick) {
        event.preventDefault()
      } else {
        ctx.emit('click', toggleLoading)
      }
    }

    onMounted(() => {
      const { value } = componentRef
      if (!props.href || !value) return
      value.href = props.href
    })

    return {
      componentRef,
      isLoading,

      onClick,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  position: relative;
  z-index: $layer-minimal;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
  overflow: hidden;
  font-family: $font-family-main;
  font-weight: $font-weight-medium;
  letter-spacing: normal;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.2s ease-in, text-indent 0s;

  // stylelint-disable no-descending-specificity
  &::-moz-focus-inner {
    border: none;
  }

  &:global {
    &.is-type {
      &--default-type {
        color: $color-white;
        background-color: $color-primary;

        &.is-active,
        &:hover:not([disabled]):not(.is-readonly),
        &:focus-within:not([disabled]):not(.is-readonly),
        &:active:not([disabled]):not(.is-readonly) {
          background-color: darken($--color-primary, 14%);
        }

        &:active:not([disabled]):not(.is-readonly) {
          transform: translateY(0.2rem);
        }
      }

      &--link {
        color: $color-text-secondary;
        background-color: transparent;

        &.is-active,
        &:hover:not([disabled]):not(.is-readonly),
        &:focus-within:not([disabled]):not(.is-readonly),
        &:active:not([disabled]):not(.is-readonly) {
          color: $color-white;
        }

        &:global(.is-disabled),
        &:disabled {
          color: $color-border-2;
          background-color: transparent;
        }
      }

      &--link-icon {
        color: $color-text-secondary;
        background-color: transparent;

        &.is-active,
        &:hover:not([disabled]):not(.is-readonly),
        &:focus-within:not([disabled]):not(.is-readonly),
        &:active:not([disabled]):not(.is-readonly) {
          color: $color-primary;
        }
      }

      &--ghost {
        color: $color-white;
        background-color: transparent;

        &.is-active,
        &:hover:not([disabled]):not(.is-readonly),
        &:focus-within:not([disabled]):not(.is-readonly),
        &:active:not([disabled]):not(.is-readonly) {
          background-color: $color-white-02;
        }
      }

      &--outline {
        color: $color-primary;
        background-color: transparent;
        border: 0.1rem solid currentColor;
        transition: background-color 0.2s ease-in;

        &.is-active,
        &:hover:not([disabled]):not(.is-readonly),
        &:focus-within:not([disabled]):not(.is-readonly),
        &:active:not([disabled]):not(.is-readonly) {
          background-color: rgba($--color-primary, 0.1);
        }
      }

      &--warning-ghost {
        color: $color-white;
        background-color: $color-white-02;

        &.is-active,
        &:hover:not([disabled]):not(.is-readonly),
        &:focus-within:not([disabled]):not(.is-readonly),
        &:active:not([disabled]):not(.is-readonly) {
          background-color: $color-white-04;
        }
      }

      &--danger {
        color: $color-white;
        background-color: $color-danger;

        &.is-active,
        &:hover:not([disabled]):not(.is-readonly),
        &:focus-within:not([disabled]):not(.is-readonly),
        &:active:not([disabled]):not(.is-readonly) {
          background-color: darken($--color-danger, 14%);
        }

        &:active:not([disabled]):not(.is-readonly) {
          transform: translateY(0.2rem);
        }

        &:global(.is-disabled),
        &:disabled {
          color: $color-white;
          background-color: lighten($--color-danger, 5%);
        }

        &:global(.is-readonly) {
          color: rgba($--color-white, 0.75);
          cursor: default;
        }
      }
    }

    &.is-size {
      &--default-size {
        height: 3.2rem;
        padding: 0.8rem 1.2rem;
        font-size: 1.3rem;
        border-radius: 0.8rem;
      }

      &--medium {
        min-width: 4.8rem;
        height: 4.8rem;
        padding: 0.8rem 2rem;
        border-radius: 1.2rem;
      }

      &--symbol {
        $size: 3.4rem;

        min-width: $size;
        max-width: $size;
        height: $size;
        padding: $size - 3.2rem;
        margin: 3.2rem - $size;
        line-height: 1;
        border-radius: 1.8rem;
      }
    }
  }

  &:global(.is-active) {
    cursor: default;
  }

  &:global(.is-disabled),
  &:disabled {
    color: $color-text-secondary;
    cursor: not-allowed;
    background-color: $color-bg-light;
  }

  &:global(.is-readonly) {
    // color: $color-text-secondary;
    cursor: default;
  }
}

.full-width {
  width: 100%;
}

.loading {
  overflow: hidden;
  color: transparent !important;
  pointer-events: none;
  cursor: default;
  transition: 0;

  &::after {
    @include loading;
  }
}
</style>
