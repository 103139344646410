import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
import { IInitPlugin } from './types.d'
// import { APP_VERSION } from '@/utils/constants/params'


export const initPlugin: IInitPlugin = (app) => {
  app.use(VueSvgInlinePlugin, {
    /*
    cache: {
      version: APP_VERSION,
      persistent: true,
      removeRevisions: true,
    },
    */
  })
}
