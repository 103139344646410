import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MLayoutHeader = _resolveComponent("MLayoutHeader")!
  const _component_MLayoutMessages = _resolveComponent("MLayoutMessages")!
  const _component_MLayoutRPCMessage = _resolveComponent("MLayoutRPCMessage")!
  const _component_MLayoutNotices = _resolveComponent("MLayoutNotices")!
  const _component_MPageLoading = _resolveComponent("MPageLoading")!
  const _component_MLayoutUpdatePopup = _resolveComponent("MLayoutUpdatePopup")!
  const _component_MScrollUpButton = _resolveComponent("MScrollUpButton")!
  const _component_MLayoutFooter = _resolveComponent("MLayoutFooter")!

  return (_openBlock(), _createElementBlock("div", {
    id: "page",
    class: _normalizeClass(_ctx.$s.root)
  }, [
    _createElementVNode("header", {
      class: _normalizeClass([_ctx.$s.header, {
        'is-scrolled': _ctx.isScrolled,
        'is-thin': _ctx.isThin,
      }])
    }, [
      _createVNode(_component_MLayoutHeader)
    ], 2),
    _createElementVNode("main", {
      class: _normalizeClass(_ctx.$s.main)
    }, [
      _createElementVNode("header", {
        class: _normalizeClass([_ctx.$s.messagesContainer, "container-small"])
      }, [
        (_ctx.connected)
          ? (_openBlock(), _createBlock(_component_MLayoutMessages, {
              key: 0,
              class: _normalizeClass(_ctx.$s.messages)
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        (!_ctx.isValidRpc)
          ? (_openBlock(), _createBlock(_component_MLayoutRPCMessage, {
              key: 1,
              class: _normalizeClass(_ctx.$s.messages)
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        _createVNode(_component_MLayoutNotices, {
          class: _normalizeClass([_ctx.$s.notices, "container-small"])
        }, null, 8, ["class"])
      ], 2),
      _createElementVNode("section", {
        class: _normalizeClass(_ctx.$s.mainInner)
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_MPageLoading, { key: 0 }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "main", { id: "page-main" })
      ], 2),
      _createElementVNode("footer", {
        class: _normalizeClass([_ctx.$s.mainFooter, "container-main"])
      }, [
        _createVNode(_component_MLayoutUpdatePopup, {
          class: _normalizeClass(_ctx.$s.updatePopup)
        }, null, 8, ["class"]),
        _createVNode(_component_MScrollUpButton, {
          class: _normalizeClass(_ctx.$s.scrollUpButton)
        }, null, 8, ["class"])
      ], 2)
    ], 2),
    _createElementVNode("footer", {
      class: _normalizeClass(_ctx.$s.footer)
    }, [
      _createVNode(_component_MLayoutFooter)
    ], 2)
  ], 2))
}