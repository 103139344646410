<template>
  <div :class="$s.root">
    <MMessage
      type="danger"
      action
      action-mobile-to-bottom
      :text="$t('rpcToast.button-action')"
      :action-text="$t('rpcToast.error-message')"
      @action="onOpenProfile"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import MMessage from '@/components/ui/MMessage.vue'
import { useModalAccount } from '@/components/modals'

export default defineComponent({
  components: {
    MMessage,
  },
  emits: {
    switch: () => true,
  },
  setup: () => {
    const modalAccount = useModalAccount()

    const onOpenProfile = () => {
      void modalAccount.show()
    }

    return {
      onOpenProfile,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: flex;
  justify-content: center;
}
</style>
