import { Store } from 'vuex'
import { createStore as vuexCreateStore } from 'vuex-smart-module'

import {
  AirdropStoreModule,
  AirdropsStoreModule,
  AppStoreModule,
  MultisenderStoreModule,
  TxHistoryStoreModule,
  RootStoreModule,
  WalletStoreModule,
} from './modules'

import {
  loggerSentryBreadcrumbsPlugin,
  persistPlugin,
} from './plugins/index'

import { StoreRootModule, StoreModules } from './types'


export * from './modules'

const modules: Record<keyof StoreModules, unknown> = {
  airdrop: AirdropStoreModule,
  airdrops: AirdropsStoreModule,
  app: AppStoreModule,
  multisender: MultisenderStoreModule,
  txHistory: TxHistoryStoreModule,
  wallet: WalletStoreModule,
}

export const createStore = () => {
  RootStoreModule.options.modules = modules

  const plugins = [
    persistPlugin.plugin,
    loggerSentryBreadcrumbsPlugin,
  ]

  const store = vuexCreateStore(
    RootStoreModule,
    {
      strict: process.env.NODE_ENV !== 'production',
      // strict: false,
      plugins,
    },
  )

  return store as Store<StoreRootModule>
}
