import { RouteRecordRaw } from 'vue-router'
import { ROUTE_ERROR_404 } from '@/utils/constants/routes'


const createRoutes = (): RouteRecordRaw[] => [
  {
    path: '/:path_match(.*)*',
    name: ROUTE_ERROR_404,
    props: ({ params: p }) => ({ ...p, error_code: 404 }),
    component: () => import(
      /* webpackChunkName: "pages.errors" */
      './PageError404.vue'
    ),
  },
]

const createModule = () => {
  const routes = createRoutes()
  return routes
}

export default createModule
