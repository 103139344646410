import { createI18n } from 'vue-i18n'

import { IInitPlugin } from './types.d'

import enJSON from '../../locales/en.json'
import ruJSON from '../../locales/ru.json'
import zhJSON from '../../locales/zh.json'


export const localeTypes = {
  ru: 'ru',
  en: 'en',
  zh: 'zh',
} as const

type ILocaleTypes = keyof typeof localeTypes

const getBrowserLocale = () => {
  const locale = navigator.language.slice(0, 2) as ILocaleTypes
  return (locale in localeTypes) ? locale : void 0
}

const DEFAULT_MESSAGES = {
  [localeTypes.en]: enJSON,
  [localeTypes.ru]: ruJSON,
  [localeTypes.zh]: zhJSON,
}

export const i18n = createI18n({
  legacy: false,
  locale: getBrowserLocale(),
  fallbackLocale: localeTypes.en,
  messages: DEFAULT_MESSAGES,
})

export const initPlugin: IInitPlugin = (app) => {
  app.use(i18n)
}
