<template>
  <MButton
    type="outline"
    :class="$s.root"
    name="network"
    :disabled="isDisabled"
    @click="onClick"
  >
    <MIcon
      :name="networkIcon"
      symbol
      sprite
      :class="[$s.icon, `is-network--${networkIcon}`]"
    />

    <span
      :class="$s.name"
      v-text="networkShortName"
    />
  </MButton>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

import { useAppStore, useWalletStore } from '@/composable'
import { useModalSwitchNetwork } from '@/components/modals'

import MIcon from '@/components/ui/MIcon.vue'
import MButton from '@/components/ui/MButton.vue'


export default defineComponent({
  components: {
    MIcon,
    MButton,
  },
  setup: () => {
    const appStore = useAppStore()
    const walletStore = useWalletStore()
    const modalSwitchNetwork = useModalSwitchNetwork()

    const onClick = () => {
      void modalSwitchNetwork.show()
    }

    const isDisabled = computed(() => {
      const walletMeta = walletStore.walletMeta.value
      return walletMeta !== null && (walletMeta?.isGnosisSafe || !walletMeta?.isAvailableChainSwitch)
    })

    return {
      isDisabled,
      networkIcon: appStore.networkIcon,
      networkShortName: appStore.networkShortName,

      onClick,
    }
  },
})
</script>

<style lang="scss" module="$s">
.icon {
  color: $color-primary;

  @include media-gte(tablet) {
    margin-right: 0.5rem;
  }

  &:global(.is-network--symbols\/ethereum) {
    // corrected styles for narrow icon
    margin: 0 (0.5rem - 0.5rem) 0 -0.5rem;

    @include media-lt(tablet) {
      margin: 0 -0.5rem;
    }
  }
}

.name {
  font-weight: $font-weight-medium;
  color: $color-primary;

  @include media-lt(tablet) {
    display: none;
  }

  @include media-gte(tablet) {
    display: inline-block;
  }
}
</style>
