import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MMessage = _resolveComponent("MMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$s.root)
  }, [
    (_ctx.switchSettings)
      ? (_openBlock(), _createBlock(_component_MMessage, {
          key: 0,
          type: "danger",
          text: _ctx.$t(_ctx.switchSettings.text, _ctx.switchSettings.params),
          action: "",
          "action-disabled": _ctx.isDisabledSwitchNetwork,
          "action-mobile-to-bottom": "",
          "action-text": _ctx.$t('button.switch-network'),
          onAction: _ctx.onSwitch
        }, null, 8, ["text", "action-disabled", "action-text", "onAction"]))
      : (!_ctx.isFirstTab && _ctx.isActiveTab)
        ? (_openBlock(), _createBlock(_component_MMessage, {
            key: 1,
            type: "danger",
            "action-mobile-to-bottom": "",
            text: _ctx.$t('error.multiple-open')
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
  ], 2))
}