/* eslint-disable @typescript-eslint/no-explicit-any */


type IValidatorResult<R> = {
  value: boolean;
  result: R;
}

type IValidatorResult2<R> = {
  value: boolean;
  result?: R;
  message: string;
}

export interface IValidatorRule0<R = boolean> {
  validate: () => boolean | IValidatorResult<R> | Promise<boolean | IValidatorResult<R>>;
  getMessage: (v: never[], r: IValidatorResult<R>) => string;
}

export interface IValidatorRule1 <T0, R = boolean>{
  validate: (v0: T0) => boolean | IValidatorResult<R> | Promise<boolean | IValidatorResult<R>>;
  getMessage: (v: [T0], r: IValidatorResult<R>) => string;
}

export interface IValidatorRule2 <T0, T1, R = boolean>{
  validate: (v0: T0, v1: T1) => boolean | IValidatorResult<R> | Promise<boolean | IValidatorResult<R>>;
  getMessage: (v: [T0, T1], r: IValidatorResult<R>) => string;
}

export interface IValidatorRule3 <T0, T1, T2, R = boolean>{
  validate: (v0: T0, v1: T1, v2: T2) => boolean | IValidatorResult<R> | Promise<boolean | IValidatorResult<R>>;
  getMessage: (v: [T0, T1, T2], r: IValidatorResult<R>) => string;
}

export abstract class ValidatorServiceBase {
  public async executor<R>(
    rule: IValidatorRule0<R>,
  ): Promise<true | IValidatorResult2<R>>;

  public async executor<T0, R>(
    rule: IValidatorRule1<T0, R>,
    ...args: Parameters<typeof rule['validate']>
  ): Promise<true | IValidatorResult2<R>>;

  public async executor<T0, T1, R>(
    rule: IValidatorRule2<T0, T1, R>,
    ...args: Parameters<typeof rule['validate']>
  ): Promise<true | IValidatorResult2<R>>;

  public async executor<T0, T1, T2, R>(
    rule: IValidatorRule3<T0, T1, T2, R>,
    ...args: Parameters<typeof rule['validate']>
  ): Promise<true | IValidatorResult2<R>>;

  public async executor<T0, T1, T2, R>(
    rule:
      | IValidatorRule0<R>
      | IValidatorRule1<T0, R>
      | IValidatorRule2<T0, T1, R>
      | IValidatorRule3<T0, T1, T2, R>
      | never,
    ...args: Parameters<typeof rule['validate']>
  ): Promise<true | IValidatorResult2<R>> {
    const result = await rule.validate(
      ...args as [T0, T1, T2],
    )
    // @ts-ignore ts(2367)
    if (result === true) return true

    // @ts-ignore ts(2345)
    const message = rule.getMessage(args as [T0, T1, T2], result)

    return { value: false, message, ...result }
  }
}
