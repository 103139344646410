import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href,
    class: _normalizeClass([_ctx.$s.root, {
      'is-invert': _ctx.invert,
      'is-external': _ctx.external,
    }]),
    target: "_blank",
    rel: "noopener noreferrer"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.text), 1)
    ])
  ], 10, _hoisted_1))
}