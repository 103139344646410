<template>
  <button
    v-if="isActive"
    :class="$s.root"
    @click="onClick"
  >
    <MIcon
      name="arrow-down"
      sprite
      size="medium"
      :class="$s.icon"
    />
  </button>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from 'vue'

import MIcon from '@/components/ui/MIcon.vue'


const MIN_ACTIVE_VALUE = 300 // px

export default defineComponent({
  components: {
    MIcon,
  },
  setup() {
    const isActive = ref(false)

    const onClick = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    let timerId: ReturnType<typeof setTimeout> | null

    const onScroll = () => {
      if (timerId) return
      timerId = setTimeout(() => {
        isActive.value = window.scrollY > MIN_ACTIVE_VALUE
        if (timerId) clearTimeout(timerId)
        timerId = null
      }, 100)
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
    })

    return {
      isActive,
      onClick,
    }
  },
})
</script>

<style lang="scss" module="$s">
$size: 5.4rem;

.root {
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  cursor: pointer;
  background: $color-white-01;
  border-radius: 100%;
  transition: 0.3s;
  backdrop-filter: blur(0.4rem);

  &:hover {
    background-color: $color-white-04;
  }
}

.icon {
  color: $color-text-secondary;
  transform: rotate(180deg);
}
</style>
