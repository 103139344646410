import { mergeProps as _mergeProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MMenu = _resolveComponent("MMenu")!

  return (_openBlock(), _createBlock(_component_MMenu, {
    ref: "menuRef",
    options: _ctx.options,
    "option-id": "id",
    position: _ctx.position,
    disabled: _ctx.disabled,
    "menu-class": _ctx.$s.menuClass,
    name: _ctx.name,
    onMouseenter: _ctx.onMouseenter,
    onMouseleave: _ctx.onMouseleave
  }, {
    button: _withCtx((props) => [
      _renderSlot(_ctx.$slots, "button", _mergeProps(props, {
        loading: _ctx.isLoading || _ctx.loading,
        onToggleLoading: _ctx.onToggleLoading
      }), () => [
        _createVNode(_component_MButton, {
          type: "link",
          size: "small",
          readonly: _ctx.disabled,
          class: _normalizeClass([_ctx.$s.button, {
            'is-active': _ctx.active,
          }]),
          name: `${_ctx.name}--open`,
          onMouseenter: _ctx.onMouseenter,
          onClick: props.onToggle
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _createVNode(_component_MIcon, {
              name: "chevron-up",
              size: "medium",
              sprite: "",
              class: _normalizeClass([_ctx.$s.icon, {
              'is-loading': _ctx.isLoading || _ctx.loading,
            }])
            }, null, 8, ["class"])
          ]),
          _: 2
        }, 1032, ["readonly", "class", "name", "onMouseenter", "onClick"])
      ])
    ]),
    option: _withCtx(({ data, onClose }) => [
      _createVNode(_component_MButton, {
        type: "link",
        href: data.href,
        blank: data.blank,
        to: data.to,
        class: _normalizeClass([_ctx.$s.optionButton, {
          'is-external': data.href,
        }]),
        disabled: _ctx.disabled,
        "full-width": "",
        active: data.active || data.id === _ctx.modelValue?.id,
        text: _ctx.$te(data.label) ? _ctx.$t(data.label) : data.label,
        name: `${_ctx.name}--${data.id}`,
        onClick: ($event: any) => (_ctx.onClick(data, onClose))
      }, null, 8, ["href", "blank", "to", "class", "disabled", "active", "text", "name", "onClick"])
    ]),
    _: 3
  }, 8, ["options", "position", "disabled", "menu-class", "name", "onMouseenter", "onMouseleave"]))
}