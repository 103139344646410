import './assets/scss/main.scss'

import { createApp } from 'vue'
import { createStore } from './store'
import { createRouter } from './router'
import { initPlugins } from './plugins'
import App from './App.vue'


const app = createApp(App)
const store = createStore()
const router = createRouter({ store, app })

app
  .use(initPlugins, { router, store })
  .use(store)
  .use(router)
  .mount('#app')
