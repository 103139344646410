import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createBlock(_component_MButton, {
    class: _normalizeClass([_ctx.$s.root, {
      'is-custom-network': _ctx.isCustom,
    }]),
    type: "outline",
    name: "balance",
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$s.balance)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.$s.number, { 'is-loading': _ctx.isLoading }]),
          textContent: _toDisplayString(_ctx.balanceHuman)
        }, null, 10, _hoisted_1),
        _createTextVNode("  " + _toDisplayString(_ctx.currency.symbol), 1)
      ], 2),
      (_ctx.pendingTxs > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.$s.pending),
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClickPending && _ctx.onClickPending(...args)), ["stop"])),
            textContent: _toDisplayString(_ctx.$t(
        'common.amount-pending',
        { amount: _ctx.pendingTxs },
      ))
          }, null, 10, _hoisted_2))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.$s.address),
            textContent: _toDisplayString(_ctx.ethAccountShort)
          }, null, 10, _hoisted_3))
    ]),
    _: 1
  }, 8, ["class", "onClick"]))
}