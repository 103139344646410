import { App } from 'vue'
import type { Store } from 'vuex'

import { i18n } from '@/plugins/vue-i18n'
import {
  Router,
  createWebHistory,
  NavigationHookAfter,
  createRouter as createVueRouter,
} from 'vue-router'

import type { StoreRootModule } from '@/store/types'

import { createRoutes } from './routes'


type ICreateRouter = {
  app: App;
  store: Store<StoreRootModule>;
}

const createTitleGuard: (
  options: ICreateRouter & { router: Router },
  defaultTitle: string,
) => NavigationHookAfter = (options, defaultTitle) => (to) => {
  const { title } = to.meta

  document.title = [
    title ? i18n.global.t(title as string) : null,
    defaultTitle,
  ].filter(Boolean).join(' | ')
}

export const createRouter = (options: ICreateRouter): Router => {
  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [],
    scrollBehavior(to, from, savedPosition) {
      if (to.name === from.name) {
        return void 0
      }

      if (savedPosition) {
        return savedPosition
      }

      return { top: 0 }
    },
  })

  const routesOptions = { router, ...options }
  const routes = createRoutes(routesOptions)
  routes.forEach((route) => router.addRoute(route))

  const titleGuard = createTitleGuard(routesOptions, document.title)
  router.afterEach(titleGuard)

  return router
}
