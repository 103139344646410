export * from './useAirdropsStore'
export * from './useAirdropStore'
export * from './useAppStore'
export * from './useBreakpoints'
export * from './useGlobalLoading'
export * from './useMultisenderStore'
export * from './useMultiTab'
export * from './useNotices'
export * from './useTxHistoryStore'
export * from './useWalletStore'
export * from './useWallet'
export * from './useWallets'
export * from './useWatchAsset'
export * from './useChangeChain'
export * from './useWalletListener'
export * from './useCheckRpc'

import { useMultiTab } from './useMultiTab'

export const useMultiTabMultisend = (active: boolean) => (
  useMultiTab('multisend', active)
)
