<template>
  <div
    v-click-outside="onClickOutside"
    v-bind="$attrs"
    :class="$s.root"
    :name="name"
  >
    <slot
      name="button"
      :active="isActive"
      :on-toggle="onToggle"
      :on-open="onOpen"
      :on-close="onClose"
    />

    <transition name="transition--fade" appear>
      <ul
        v-if="isActive && !disabled"
        :class="[$s.menu, menuClass, position]"
        @click="onClose"
      >
        <li
          v-for="item of options"
          :key="item[optionId]"
          :class="[$s.option, optionClass]"
        >
          <slot
            name="option"
            :data="item"
            :on-toggle="onToggle"
            :on-open="onOpen"
            :on-close="onClose"
          />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line object-curly-newline
import { defineComponent, ref, watch, onBeforeUnmount } from 'vue'
import clickOutsideVue from 'click-outside-vue3'


const CLOSE_TIMEOUT = 100

const POSITIONS = [
  'top left',
  'top center',
  'top right',

  'bottom left',
  'bottom center',
  'bottom right',
]

export default defineComponent({
  directives: {
    clickOutside: clickOutsideVue.directive,
  },
  props: {
    modelValue: Boolean,
    disabled: Boolean,
    menuClass: {
      type: [String, Object],
    },
    name: {
      type: String,
      required: true,
    },
    optionClass: {
      type: [String, Object],
    },
    optionId: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    position: {
      type: String,
      default: 'bottom center',
      validator: (prop: string) => (
        POSITIONS.includes(prop)
      ),
    },
  },
  emits: [
    'update:model-value',
  ],
  setup: (props, ctx) => {
    const isActive = ref(false)
    let timerId: ReturnType<typeof setTimeout>

    const emitModelValue = () => {
      ctx.emit('update:model-value', isActive.value)
    }

    const onOpen = () => {
      clearTimeout(timerId)
      if (props.disabled) return
      isActive.value = true
      emitModelValue()
    }

    const onClose = () => {
      clearTimeout(timerId)

      timerId = setTimeout(() => {
        isActive.value = false
        emitModelValue()
      }, CLOSE_TIMEOUT)
    }

    const onToggle = () => {
      if (isActive.value) {
        void onClose()
      } else {
        void onOpen()
      }
    }

    const onClickOutside = () => {
      if (isActive.value) onClose()
    }

    watch(() => props.modelValue, (value) => {
      isActive.value = value
    }, { immediate: true })

    watch(() => props.disabled, () => {
      isActive.value = false
    }, { immediate: true })

    onBeforeUnmount(() => {
      clearTimeout(timerId)
    })

    return {
      isActive,

      onOpen,
      onClose,
      onToggle,
      onClickOutside,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  position: relative;
}

.menu {
  position: absolute;
  z-index: $layer-modal-container;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin: 0;
  overflow: hidden;
  list-style: none;
  background-color: $color-border-3;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2rem $box-shadow-menu;

  &:global {
    &.top {
      bottom: calc(100% + 1rem);
    }

    &.bottom {
      top: calc(100% + 1rem);
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.option {
  padding: 0;
}
</style>
