import { providers } from 'ethers'
import memoize from 'lodash/memoize'


export const isLink = (text: string) => /http(s)?:\/\//.test(text)


// for stop many call eth_chainId
// https://github.com/ethers-io/ethers.js/issues/901#issuecomment-651044105
class StaticJsonRpcBatchProvider extends providers.JsonRpcBatchProvider {
  public async getNetwork(): Promise<providers.Network> {
    // eslint-disable-next-line no-underscore-dangle
    if (this._network) {
      // eslint-disable-next-line no-underscore-dangle
      return Promise.resolve(this._network)
    }
    return super.getNetwork()
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getStaticRpcProvider = (url?: string, chainId?: number) => {
  const rpcProvider = new StaticJsonRpcBatchProvider(url, chainId)
  return rpcProvider
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getRpcProvider = (url?: string, chainId?: number) => {
  const rpcProvider = new providers.JsonRpcBatchProvider(url, chainId)
  return rpcProvider
}

export const getStaticRpcProviderMemoized = memoize(getStaticRpcProvider)
