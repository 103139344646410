import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createBlock(_component_MButton, {
    class: _normalizeClass([_ctx.$s.root, {
      'is-active': _ctx.active,
    }]),
    "aria-pressed": _ctx.active ? 'true' : 'false',
    type: "ghost",
    size: "symbol",
    name: "burger",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:active', !_ctx.active)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$s.line)
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$s.line)
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$s.line)
      }, null, 2)
    ]),
    _: 1
  }, 8, ["class", "aria-pressed"]))
}