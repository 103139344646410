import { ref, onBeforeUnmount } from 'vue'


const loading = ref(false)

const toggle = (value = !loading.value) => {
  loading.value = value
}

export const useGlobalLoading = (reset = false) => {
  onBeforeUnmount(() => {
    if (reset) toggle(false)
  })

  return {
    loading,
    toggle,
  }
}
