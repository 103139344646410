<template>
  <MMenu
    :options="locales"
    option-id="id"
    position="top right"
    name="locale-menu"
  >
    <template #button="{ onToggle }">
      <MButton
        :aria-label="$t('aria.switch-locale')"
        type="ghost"
        size="symbol"
        name="locale-switch"
        @click="onToggle"
      >
        <img
          :src="activeLocale.image"
          :class="$s.image"
          :alt="activeLocale.id"
        >
      </MButton>
    </template>

    <template #option="{ data, active, onClose }">
      <MButton
        type="link"
        :class="[$s.optionButton, { 'is-active': active }]"
        :active="data.id === activeLocale.id"
        :name="`locale--${data.id}`"
        @click="selectLocale(data), onClose()"
      >
        <img
          :src="data.image"
          :class="$s.image"
          :alt="data.id"
        >

        <span
          :class="$s.optionLabel"
          v-text="data.label"
        />
      </MButton>
    </template>
  </MMenu>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import { LOCALE_LIST, LOCALE_EN } from '@/utils/constants/locales'

import MMenu from '@/components/ui/MMenu.vue'
import MButton from '@/components/ui/MButton.vue'


export default defineComponent({
  components: {
    MMenu,
    MButton,
  },
  setup: () => {
    const { locale } = useI18n()
    const locales = LOCALE_LIST

    const activeLocale = computed(() => locales.find((_) => _.id === locale.value)
      || LOCALE_EN)

    const selectLocale = (item: typeof LOCALE_LIST[number]) => {
      locale.value = item.id
    }

    return {
      activeLocale,
      locales,

      selectLocale,
    }
  },
})
</script>

<style lang="scss" module="$s">
.option-button {
  height: 3rem !important;

  &:global(.is-active) {
    cursor: default;
  }
}

.image {
  width: 2.4rem;
  height: 2.4rem;
}

.option-label {
  margin-left: 1rem;
  font-weight: $font-weight-bold;
  word-break: keep-all;
}
</style>
