/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable vue/one-component-per-file */

import {
  App,
  DefineComponent,
  Component,
  ComponentInternalInstance,
  onBeforeUnmount,

  ref,
  createApp,
  defineAsyncComponent,
  getCurrentInstance,
  h,
} from 'vue'
import { EventEmitter } from 'eventemitter3'

// From https://git.io/JlvW9
function createChildApp(appCfg: Component, parentApp: App) {
  const app = createApp(appCfg)

  app.config.globalProperties = parentApp.config.globalProperties

  // @ts-ignore ts(2339)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reload, ...appContext } = parentApp._context
  Object.assign(app._context, appContext)

  return app
}

const MODAL_PARENT_KEY = '__modal_parent__'

const useModalInner = <
  // eslint-disable-next-line max-len
  IModalPropsData extends Record<string, unknown> & { closeOnUnmountParent?: boolean } = Record<string, unknown> & { closeOnUnmountParent?: boolean },
  IComponentPropsData extends Record<string, unknown> = Record<string, unknown>,
  IReturnData = boolean
  >(
    modalName: string,
    asyncModalBase: DefineComponent,
    asyncComponent: DefineComponent | ReturnType<typeof defineAsyncComponent>,
    modalPropsData: IModalPropsData,
    defaultComponentPopsData: Partial<IComponentPropsData>,
    instance: ComponentInternalInstance,
  ) => {
  const eventEmitter = new EventEmitter()

  const emitter = {
    on(event: 'mount' | 'unmount' | 'init', callback: (app: App) => void) {
      return eventEmitter.on(event, callback)
    },
    off(event: 'mount' | 'unmount' | 'init', callback: (app: App) => void) {
      return eventEmitter.off(event, callback)
    },
  }

  let isMounted = false
  let isParentUnmounted = false
  let onCloseModal = (result?: IReturnData) => {
    if (!isMounted) return
    // eslint-disable-next-line no-console
    console.warn('[App warn]: empty onCloseModal', result)
  }

  const createAppModal = (
    defaultPopsData: Partial<IComponentPropsData>,
    propsData: Partial<IComponentPropsData>,
  ) => {
    const appModal = createChildApp({
      name: modalName || 'Modal',
      setup() {
        type IModalBaseExpose = {
          e$close?:(result?: IReturnData) => void;
        }

        const modalBaseRef = ref<IModalBaseExpose>()

        const closeFromComponent = (result?: IReturnData) => {
          if (modalBaseRef.value?.e$close) {
            modalBaseRef.value?.e$close(result)
          } else {
            onCloseModal(result)
          }
        }

        const childProps = {
          ...defaultPopsData,
          ...propsData,
          [MODAL_PARENT_KEY]: instance,
          onClose: closeFromComponent,
          close: closeFromComponent,
        }

        const modalBase = asyncModalBase as unknown as DefineComponent
        const component = asyncComponent as unknown as DefineComponent
        const getChildren = () => h(component, childProps)

        return () => h(modalBase, {
          ...modalPropsData,
          ref: modalBaseRef,
          onClose: onCloseModal,
          close: onCloseModal,
        }, { default: getChildren })
      },
    }, instance.appContext.app)

    return appModal
  }

  const show = (
    propsData: Partial<IComponentPropsData> = {},
  ) => {
    const parentNode = (
      document.getElementById('app')
      || instance.appContext.app._container as HTMLElement
    )

    const element = document.createElement('div')
    element.classList.add('app-modal-root')
    parentNode.appendChild(element)

    eventEmitter.emit('init')

    const appModal = createAppModal(defaultComponentPopsData, propsData)

    return new Promise<IReturnData | undefined>((resolve) => {
      onCloseModal = (result?: IReturnData) => {
        try {
          appModal.unmount()
        } catch {
          // eslint-disable-next-line no-empty
        }

        try {
          element.parentNode?.removeChild(element)
        } catch {
          // eslint-disable-next-line no-empty
        }

        resolve(result)
        isMounted = false
        eventEmitter.emit('unmount', appModal)
      }

      appModal.mount(element)
      eventEmitter.emit('mount', appModal)
      isMounted = true

      if (isParentUnmounted && modalPropsData.closeOnUnmountParent) {
        eventEmitter.removeAllListeners()
      }
    })
  }

  // on hook @vnode-before
  onBeforeUnmount(() => {
    isParentUnmounted = true
    if (modalPropsData.closeOnUnmountParent) {
      onCloseModal()
      eventEmitter.removeAllListeners()
    }
  }, instance)

  const result = {
    show,
    hide: () => onCloseModal(),
    emitter,
  }

  return result
}

export const useModal = <
  // eslint-disable-next-line max-len
  IModalPropsData extends Record<string, unknown> & { closeOnUnmountParent?: boolean } = Record<string, unknown> & { closeOnUnmountParent?: boolean },
  IComponentPropsData extends Record<string, unknown> = Record<string, unknown>,
  IReturnData = boolean
  >(
    modalName: string,
    asyncModalBase: DefineComponent,
    asyncComponent: DefineComponent | ReturnType<typeof defineAsyncComponent>,
    modalPropsData: IModalPropsData = {} as IModalPropsData,
    componentPopsData: IComponentPropsData = {} as IComponentPropsData,
    parent: ComponentInternalInstance | null = null,
  ) => {
  let instance = (parent || getCurrentInstance()) as ComponentInternalInstance

  {
    // Вызываем модалку в модалке? Тогда нам нужен instance.parent
    let parent2: ComponentInternalInstance | null = instance
    while (parent2) {
      if (parent2?.attrs[MODAL_PARENT_KEY]) {
        instance = parent2?.attrs[MODAL_PARENT_KEY] as ComponentInternalInstance
        break
      }
      parent2 = parent2?.parent
    }
  }

  if (!instance) {
    throw new Error('[App err]: useModal needs args.parent or use in setup hook')
  }

  return useModalInner<
    IModalPropsData,
    IComponentPropsData,
    IReturnData
  >(
    modalName,
    asyncModalBase,
    asyncComponent,
    modalPropsData,
    componentPopsData,
    instance,
  )
}
