export const ROUTE_HOME = 'ROUTE_HOME'

export const ROUTE_MULTISENDER_STEP = 'ROUTE_MULTISENDER_STEP'

export const ROUTE_AIRDROPS = 'ROUTE_AIRDROPS'
export const ROUTE_AIRDROP_DETAILS = 'ROUTE_AIRDROP_DETAILS'
export const ROUTE_AIRDROPS_FIND = 'ROUTE_AIRDROPS_FIND'
export const ROUTE_AIRDROP = 'ROUTE_AIRDROP'
export const ROUTE_AIRDROP_EDIT = 'ROUTE_AIRDROP_EDIT'

export const ROUTE_TUTORIAL = 'ROUTE_TUTORIAL'
export const ROUTE_FAQ = 'ROUTE_FAQ'

export const ROUTE_ERROR_404 = 'ROUTE_ERROR_404'
