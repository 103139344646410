

export const getToChecksumAddress = async () => {
  const { toChecksumAddress } = await import(
    /* webpackChunkName: "lib.web3-utils" */
    'web3-utils'
  )

  return toChecksumAddress
}
