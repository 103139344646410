import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MTooltip = _resolveComponent("MTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$s.root)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialList, (item) => {
      return (_openBlock(), _createBlock(_component_MTooltip, {
        key: item.id,
        "content-text": item.label
      }, {
        activator: _withCtx(({ on }) => [
          _createVNode(_component_MButton, _mergeProps({
            type: "link-icon",
            size: "symbol",
            href: item.href,
            class: _ctx.$s.link,
            "aria-label": item.label,
            name: `social--${item.id}`
          }, _toHandlers(on)), {
            default: _withCtx(() => [
              _createVNode(_component_MIcon, {
                name: item.icon,
                class: _normalizeClass(_ctx.$s.icon),
                sprite: ""
              }, null, 8, ["name", "class"])
            ]),
            _: 2
          }, 1040, ["href", "class", "aria-label", "name"])
        ]),
        _: 2
      }, 1032, ["content-text"]))
    }), 128))
  ], 2))
}