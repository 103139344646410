import { t } from '@/utils'

import {
  ROUTE_HOME,
  ROUTE_AIRDROPS,
  ROUTE_TUTORIAL,
  ROUTE_FAQ,
} from './routes'


export const MENU_HOME = {
  id: ROUTE_HOME,
  label: '',
  to: {
    name: ROUTE_HOME,
  },
}

export const MENU_MULTISENDER_NFT = {
  id: 'ROUTE_NFT',
  label: t('menu-main.nft'),
  to: void 0,
  href: 'https://nft.multisender.app',
  blank: false,
}

export const MENU_MULTISENDER_CLASSIC = {
  id: 'ROUTE_CLASSIC',
  label: t('menu-main.classic'),
  to: void 0,
  href: 'https://classic.multisender.app',
  blank: false,
}

export const MENU_MULTISENDER_TON = {
  id: 'ROUTE_TON',
  label: t('menu-main.ton'),
  to: void 0,
  href: 'https://ton.multisender.app',
  blank: false,
}

export const MENU_MULTISENDER_TRON = {
  id: 'ROUTE_TRON',
  label: t('menu-main.tron'),
  to: void 0,
  href: 'https://tron.multisender.app',
  blank: false,
}

export const MENU_MULTISENDER_SOLANA = {
  id: 'ROUTE_SOLANA',
  label: t('menu-main.solana'),
  to: void 0,
  href: 'https://solana.multisender.app',
  blank: false,
}

export const MENU_MULTISENDER_MASSDROP = {
  id: 'ROUTE_NFT',
  label: t('menu-main.massdrop'),
  to: void 0,
  active: window.location.origin === process.env.VUE_APP_SITE_ORIGIN,
  href: process.env.VUE_APP_SITE_ORIGIN,
  blank: false,
}

export const MENU_AIRDROPS = {
  id: ROUTE_AIRDROPS,
  label: t('menu-main.airdrops'),
  to: {
    name: ROUTE_AIRDROPS,
  },
  href: void 0,
}

export const MENU_TUTORIAL = {
  id: ROUTE_TUTORIAL,
  label: t('menu-main.tutorial'),
  to: {
    name: ROUTE_TUTORIAL,
  },
  href: void 0,
}

export const MENU_FAQ = {
  id: ROUTE_FAQ,
  label: t('menu-main.faq'),
  to: {
    name: ROUTE_FAQ,
  },
  href: void 0,
}

export const MENU_ANALYTICS = {
  id: 'ROUTE_ANALYTICS',
  label: t('menu-main.analytics'),
  to: void 0,
  href: 'https://analytics.multisender.app',
  blank: false,
}
