<template>
  <div id="page" :class="$s.root">
    <header
      :class="[$s.header, {
        'is-scrolled': isScrolled,
        'is-thin': isThin,
      }]"
    >
      <MLayoutHeader />
    </header>

    <main :class="$s.main">
      <header :class="$s.messagesContainer" class="container-small">
        <MLayoutMessages
          v-if="connected"
          :class="$s.messages"
        />

        <MLayoutRPCMessage
          v-if="!isValidRpc"
          :class="$s.messages"
        />

        <MLayoutNotices
          :class="$s.notices"
          class="container-small"
        />
      </header>

      <section :class="$s.mainInner">
        <MPageLoading v-if="loading" />
        <slot id="page-main" name="main" />
      </section>

      <footer :class="$s.mainFooter" class="container-main">
        <MLayoutUpdatePopup
          :class="$s.updatePopup"
        />
        <MScrollUpButton
          :class="$s.scrollUpButton"
        />
      </footer>
    </main>

    <footer :class="$s.footer">
      <MLayoutFooter />
    </footer>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line object-curly-newline
import { ref, defineComponent, onMounted, onBeforeUnmount } from 'vue'

import MPageLoading from '@/components/MPageLoading.vue'

import MLayoutHeader from './MLayoutHeader.vue'
import MLayoutFooter from './MLayoutFooter.vue'
import MLayoutNotices from './MLayoutNotices.vue'
import MLayoutMessages from './MLayoutMessages.vue'
import MLayoutUpdatePopup from './MLayoutUpdatePopup.vue'
import MScrollUpButton from './MScrollUpButton.vue'
import MLayoutRPCMessage from '@/components/layout/MLayoutRPCMessage.vue'


export default defineComponent({
  components: {
    MPageLoading,
    MLayoutHeader,
    MLayoutFooter,
    MLayoutNotices,
    MLayoutMessages,
    MScrollUpButton,
    MLayoutRPCMessage,
    MLayoutUpdatePopup,
  },
  props: {
    loading: Boolean,
    connected: Boolean,
    isValidRpc: Boolean,
  },
  setup: () => {
    const isScrolled = ref(false)
    const isThin = ref(false)

    const onScroll = (event: Event) => {
      // @ts-ignore ts(2339)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const scrollTop = event.target?.scrollingElement?.scrollTop as number

      isScrolled.value = scrollTop > 10
      isThin.value = isThin.value ? scrollTop > 20 : scrollTop > 40
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll, { passive: true })
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
    })

    return {
      isScrolled,
      isThin,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: flex;
  flex-direction: column;
  min-width: map-get($m-breakpoints, mobile);
  min-height: 100vh;
}

.header {
  position: sticky;
  top: 0;
  z-index: $layer-overlay;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  border-bottom: 0.1rem solid transparent;
  transition: height 0.2s, border-color 0.2s;

  @include media-lt(tablet) {
    height: $header-height-mobile;

    &:global(.is-thin) {
      height: $header-height-mobile-scroll;
    }
  }

  @include media-gte(tablet) {
    height: $header-height;

    &:global(.is-thin) {
      height: $header-height-scroll;
    }
  }

  &:global(.is-scrolled) {
    @include body-background-image;
  }

  &:global(.is-thin) {
    border-bottom-color: $color-border-3;
  }
}

.main {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 40rem;
}

.main-inner {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.notices {
  max-height: 100%;
  pointer-events: none;

  @mixin fixed-position {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $layer-modal;
  }

  @include media-lt(tablet) {
    body:global(:not(.app-modal-root--opened)) & {
      padding-right: 0;
      padding-left: 0;
    }
  }

  @include media-gte(tablet) {
    @include fixed-position;
  }

  body:global(.app-modal-root--opened) & {
    @include fixed-position;

    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.mainFooter {
  position: sticky;
  bottom: 1rem;
  z-index: $layer-modal;
}

.updatePopup,
.scrollUpButton {
  right: 0;

  @include media-lt(tablet) {
    position: fixed;
    bottom: $footer-height-mobile;
  }

  @include media-gte(tablet) {
    position: absolute;
    bottom: 0;
  }
}

.updatePopup {
  $margin-size: 1rem;

  margin: 0 $margin-size;

  @include media-lt(tablet-xs) {
    width: calc(100% - #{2 * $margin-size});
  }
}

.scrollUpButton {
  $margin-size: 1rem;
  $update-popup-size: 5.2rem;

  margin: 0 $margin-size;

  .updatePopup + & {
    @include media-lt(tablet) {
      bottom: $footer-height-mobile + $update-popup-size + $margin-size;
    }

    @include media-gte(tablet) {
      position: absolute;
      bottom: $update-popup-size + $margin-size;
    }
  }
}

.footer {
  position: relative;
  z-index: $layer-minimal;
  flex-shrink: 0;
}

.messagesContainer {
  min-height: 5.2rem;
}

.messages {
  z-index: $layer-navigation;
  margin-bottom: 1rem;
}
</style>
