import memoize from 'lodash/memoize'
import { computed, reactive, toRefs } from 'vue'
import { useAirdropStoreModule } from '@/store'


let store: ReturnType<typeof useAirdropStoreModule>


const isCanceled = computed(() => (
  !!store.state.airdrop?.cancelled
))

const useAirdrop = () => {
  store = useAirdropStoreModule()

  const stateRefs = toRefs(store.state)
  const gettersRefs = toRefs(store.getters)

  const $state = reactive({
    ...stateRefs,
    ...gettersRefs,

    isCanceled,
  })

  return {
    $store: store,
    $state,
    $actions: store.actions,
    $mutations: store.mutations,

    ...stateRefs,
    ...gettersRefs,

    isCanceled,
  }
}

export const useAirdropStore = memoize(useAirdrop)
