import { RouteRecordRaw } from 'vue-router'
import { t } from '@/utils'
import { ROUTE_MULTISENDER_STEP } from '@/utils/constants/routes'
import { StepTypes } from './utils'


const STEPS = [
  StepTypes[StepTypes.preparation],
  StepTypes[StepTypes.approving],
  StepTypes[StepTypes.distribution],
] as const

const createRoutes = (): RouteRecordRaw[] => [
  {
    path: `/:step(${STEPS.join('|')})`,
    name: ROUTE_MULTISENDER_STEP,
    sensitive: true,
    meta: {
      title: t('page-title.massdrop'),
    },
    props: true,
    component: () => import(
      /* webpackChunkName: "pages.multisender" */
      './PageMultisender.vue'
    ),
  },
]

const createModule = () => {
  const routes = createRoutes()
  return routes
}

export default createModule
