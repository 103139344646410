import memoize from 'lodash/memoize'
import { reactive, toRefs } from 'vue'
import { useTxHistoryStoreModule } from '@/store'


let store: ReturnType<typeof useTxHistoryStoreModule>


const useTxHistory = () => {
  store = useTxHistoryStoreModule()

  const stateRefs = toRefs(store.state)
  const gettersRefs = toRefs(store.getters)

  const $state = reactive({
    ...stateRefs,
    ...gettersRefs,
  })

  return {
    $store: store,
    $state,
    $actions: store.actions,

    ...stateRefs,
    ...gettersRefs,
  }
}

export const useTxHistoryStore = memoize(useTxHistory)
