import { SCREENING_API } from '@/constants'

export type ValidateResponse = {
  isGoodAddress: boolean;
}

export const checkIsSanctionAddress = async (address: string): Promise<boolean> => {
  try {
    const response = await fetch(
      `${SCREENING_API}/validate-address/?address=${address}`,
    )

    if (!response.ok) {
      throw new Error('Validate address response not ok')
    }

    const data: ValidateResponse = await response.json()

    return !data.isGoodAddress
  } catch {
    return true
  }
}
