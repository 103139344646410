import type {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/abstract-provider'

import { providers } from 'ethers'
import { memoizeAsyncify } from 'utils-decorators'
import { findReplacementTx } from 'find-replacement-tx'

import { NetworkService } from '@/services'
import { isErrorFindingTransaction } from '@/utils'
import { workerMapInstance } from './WorkerMap'


type IFindReplaceData = {
  nonce: number;
  from: string;
  to: string;
  value?: string;
  data?: string;
  blockNumber: number;
}

const BLOCK_NUMBER_CORRECTED = 3
const RETRY_COUNT = 6

export const hasPending = async (chainId: number, from: string) => {
  const provider = new NetworkService(chainId).rpcProvider

  const [count1, count2] = await Promise.all([
    provider.getTransactionCount(from),
    provider.getTransactionCount(from, 'pending'),
  ])

  return count1 !== count2
}

const waitTxReceiptWorker = async (payload: {
  provider: providers.Provider;
  transactionHash: string;
  blockNumber: number;
  data: IFindReplaceData;
}) => {
  const {
    provider,
    transactionHash,
    blockNumber,
    data,
  } = payload

  let transaction: TransactionResponse | null = null
  let error: unknown

  try {
    // last get transaction
    transaction = await provider.getTransaction(transactionHash)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('[App err]: error on first get transaction', err)
  }

  let i = 0
  let isErrorFinding = false
  // eslint-disable-next-line no-plusplus
  while (i++ < RETRY_COUNT && (!transaction || isErrorFinding)) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => { provider.once('block', resolve) })
    } catch {
      // do nothing
    }

    error = void 0

    try {
      // eslint-disable-next-line no-await-in-loop
      transaction = await findReplacementTx(provider, blockNumber - BLOCK_NUMBER_CORRECTED, {
        nonce: data.nonce,
        from: data.from,
        to: data.to,
        value: data.value,
        data: data.data,
      })
    } catch (err) {
      error = err
    } finally {
      isErrorFinding = isErrorFindingTransaction((error as Error)?.message)
    }
  }

  // error - must be 403 - archive data do not supported
  if (error) {
    try {
      // last get transaction
      transaction = await provider.getTransaction(transactionHash)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('[App err]: error on last get transaction', err)
    }
  }

  if (!transaction && error) {
    return { error }
  }

  if (transaction) {
    try {
      const receipt = await provider.getTransactionReceipt(transaction.hash)

      if (receipt) {
        // wait mind transaction in block
        await provider.getTransaction(receipt.transactionHash)
      } else if (error) {
        return { error }
      }

      return { receipt }
    } catch (err) {
      return { error: err }
    }
  }

  return null
}

// TODO: needs for all chainId add queue by 1 in active

const WaitTxReceipt = async (
  chainId: number,
  transactionHash: string,
  data: IFindReplaceData,
) => {
  const listItem = workerMapInstance.get(chainId, transactionHash)

  if (listItem) {
    void listItem.watch()
    return listItem.promise
  }

  const provider = new NetworkService(chainId).rpcProvider
  let watch = () => Promise.resolve()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unwatch = () => {}
  let stopWatching = false

  try {
    const receipt = await provider.getTransactionReceipt(transactionHash)

    if (receipt) {
      stopWatching = true
      workerMapInstance.remove(chainId, transactionHash)
      return receipt
    }
  } catch {
    // do nothing
  }

  const promise = new Promise<TransactionReceipt>((resolve, reject) => {
    let { blockNumber } = data
    let callbackPromise = Promise.resolve()

    const callback = async (block: number) => {
      const isSmallestNonce = workerMapInstance.isSmallestNonce(chainId, transactionHash)

      const result = isSmallestNonce ? await waitTxReceiptWorker({
        provider,
        transactionHash,
        blockNumber,
        data,
      }).catch((error: unknown) => ({ error, receipt: void 0 })) : null

      if (result?.receipt) {
        resolve(result.receipt)
        workerMapInstance.remove(chainId, transactionHash)
      } else if (result?.error) {
        reject(result.error)
        workerMapInstance.remove(chainId, transactionHash)
      } else {
        blockNumber = block - BLOCK_NUMBER_CORRECTED
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        void watch()
      }
    }

    const callbackWrapped = (block: number) => {
      callbackPromise = callback(block)
    }

    watch = async () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      provider.off('block', callbackWrapped)

      if (!stopWatching) {
        await callbackPromise

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        provider.once('block', callbackWrapped)
      }
    }

    unwatch = () => {
      provider.off('block', callbackWrapped)
      stopWatching = true
    }

    void watch()
  })

  workerMapInstance.add(chainId, {
    transactionHash,
    nonce: data.nonce,
    from: data.from,
    watch,
    unwatch,
    promise,
  })

  return promise
}

export const waitTxReceipt = memoizeAsyncify(WaitTxReceipt, 1000)

export const waitTxReceiptClearAll = () => workerMapInstance.clearAll()
