// about contract https://github.com/mds1/multicall

import type { Multicall3Abi } from '@/types/contracts'
import type { Multicall3 } from '@/types/contracts/Multicall3Abi'

import { BigNumber, Contract } from 'ethers'
import { NetworkService } from '@/services'
import { sleep } from '@/utils'
import {
  MULTICALL_INTERFACE,
  ETH_CALL_BLOCK_NUMBER_LIMIT,
} from './utils'


const MAX_LIMIT_CALL = 400

export class Multicall3Service {
  private constructor(
    public readonly chainId: number,
    private readonly address: string,
    private readonly networkService: NetworkService,
  ) {
  }

  public static buildClass(chainId?: number | null) {
    if (!chainId) return null

    const networkService = new NetworkService(chainId)
    const multicall3 = networkService.settings?.contracts.multicall3
    if (!multicall3) return null

    return new Multicall3Service(chainId, multicall3.address, networkService)
  }

  private get contract() {
    const provider = this.networkService.rpcProvider
    return new Contract(this.address, MULTICALL_INTERFACE, provider) as Multicall3Abi
  }

  public async aggregateMulticallBatchGenerator<R>(
    generator: (limit: number) => Generator<Multicall3.Call3Struct[]>,
    estimateGas: number | BigNumber,
    decoder: (value: string) => R,
  ) {
    // const { gasLimit } = await contract.provider.getBlock('latest')
    const blockGasLimit = this.networkService.settings?.blockGasLimit

    // 80% of gasLimit
    let limit = !blockGasLimit ? 50 : ETH_CALL_BLOCK_NUMBER_LIMIT * (blockGasLimit / +estimateGas)
    limit = limit > MAX_LIMIT_CALL ? MAX_LIMIT_CALL : Math.floor(limit)

    const iterator = generator(limit)
    let calldataList: Multicall3.Call3Struct[][] = []
    const promises: Promise<R[]>[] = []

    const runAggregateMulticall = () => {
      calldataList.forEach((call) => {
        const promise = this.aggregateMulticall<R>(call, decoder)
        promises.push(promise)
      })

      calldataList = []
    }

    // eslint-disable-next-line no-restricted-syntax
    for await (const calldata of iterator) {
      calldataList.push(calldata)
      await sleep(40)

      // eslint-disable-next-line no-continue
      if (calldataList.length < 5) continue

      runAggregateMulticall()
    }

    runAggregateMulticall()

    const results = await Promise.all(promises)
    return results.flat()
  }

  public async aggregateMulticall<R>(
    calls: Multicall3.Call3Struct[],
    decoder: (value: string) => R,
  ) {
    const { contract } = this

    const response = await contract.callStatic.aggregate3(calls)
    const result = response.map((r) => decoder(r.returnData))
    return result
  }
}
