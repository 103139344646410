import memoize from 'lodash/memoize'
import { computed, reactive, toRefs } from 'vue'
import { useAppStoreModule } from '@/store'
import { NetworkService } from '@/services'
import { formatWeiHuman, formatWeiWithMin } from '@/utils'


let store: ReturnType<typeof useAppStoreModule>

const currency = computed(() => {
  const { chainId } = store.state

  return (
    (chainId && new NetworkService(chainId).settings?.currency)
    || NetworkService.getAllNetworks()[1].currency
  )
})

const balanceHuman = computed(() => {
  const value = store.state.balance
  const { decimals } = currency.value
  return formatWeiWithMin(value, decimals, { compact: true })
})

const balanceHumanFull = computed(() => {
  const value = store.state.balance
  const { decimals } = currency.value
  return formatWeiHuman(value, decimals)
})

const useApp = () => {
  store = useAppStoreModule()

  const stateRefs = toRefs(store.state)
  const gettersRefs = toRefs(store.getters)

  const $state = reactive({
    ...stateRefs,
    ...gettersRefs,

    currency,
    balanceHuman,
    balanceHumanFull,
  })

  return {
    $store: store,
    $state,
    $actions: store.actions,

    ...stateRefs,
    ...gettersRefs,

    currency,
    balanceHuman,
    balanceHumanFull,
  }
}

export const useAppStore = memoize(useApp)
