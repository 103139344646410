/* eslint-disable @typescript-eslint/no-use-before-define */

import memoize from 'lodash/memoize'
import { computed, reactive, toRefs } from 'vue'
import { useWalletStoreModule } from '@/store'
import {
  shortenAddress,
} from '@/utils'


let store: ReturnType<typeof useWalletStoreModule>

const ethAccountShort = computed(() => {
  const { ethAccount } = store.state
  return ethAccount && shortenAddress(ethAccount)
})

const useWallet = () => {
  store = useWalletStoreModule()

  const stateRefs = toRefs(store.state)
  const gettersRefs = toRefs(store.getters)

  const $state = reactive({
    ...stateRefs,
    ...gettersRefs,

    ethAccountShort,
  })

  return {
    $store: store,
    $state,
    $actions: store.actions,

    ...stateRefs,
    ...gettersRefs,

    ethAccountShort,
  }
}

export const useWalletStore = memoize(useWallet)
