import { computed, onMounted, watch } from 'vue'

import {
  Connector,
  useAccount,
  Connection,
  useConnect,
  useReconnect,
  useDisconnect,
  useConnections,
} from '@wagmi/vue'

import { useModalSanctionAddress } from '@/components/modals'

import { getConfig } from '@/plugins/wagmi'

import { useWalletStore, useChangeChain } from '@/composable'

import { walletDescriptions, walletIcons } from '@/utils/constants/wallet'
import { checkIsSanctionAddress, sleep } from '@/utils'

function useChainListener() {
  const onChangeChain = useChangeChain()
  const walletStore = useWalletStore()

  const { chainId } = useAccount()

  watch(chainId, (id) => {
    if (id && +id !== walletStore.chainId.value) {
      void onChangeChain(+id)
    }
  })
}

const AUTOCONNECTED_CONNECTOR_IDS = ['safe']
export function useWalletListener() {
  const connections = useConnections()
  const walletStore = useWalletStore()
  const { reconnect } = useReconnect()
  const { connectAsync } = useConnect()
  const { disconnect } = useDisconnect()
  const modalSanctionAddress = useModalSanctionAddress()

  useChainListener()

  const connection = computed(() => connections.value?.[0])

  const onConnectWallet = async (connector: Connector) => {
    try {
      await connectAsync({ connector })
    } catch { /* empty */ }
  }

  const getIsWalletConnectSafe = async (currentConnection: Connection, retry = 0):Promise<boolean> => {
    try {
      const { connector } = currentConnection

      if (connector.name !== 'WalletConnect') {
        return false
      }
      const provider = await connector.getProvider()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const type = provider?.session?.peer.metadata.name

      return type === 'Safe{Wallet}'
    } catch (err) {
      if (retry < 3) {
        await sleep(2000)
        const result = await getIsWalletConnectSafe(currentConnection)
        return result
      }
      return false
    }
  }

  const getIsAvailableChainSwitch = async (currentConnection: Connection, retry = 0):Promise<boolean> => {
    try {
      const { connector } = currentConnection

      if (connector.name !== 'WalletConnect') {
        return true
      }
      const provider = await connector.getProvider()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { namespaces, optionalNamespaces } = provider?.session || {}

      if (namespaces?.eip155 && optionalNamespaces?.eip155) {
        const methods = ['wallet_addEthereumChain', 'wallet_switchEthereumChain']
        const checker = (arr: Array<string>, target: Array<string>) => target.every((v) => arr.includes(v))

        return checker(namespaces.eip155?.methods, methods) || checker(optionalNamespaces.eip155?.methods, methods)
      }

      return true
    } catch (err) {
      if (retry < 3) {
        await sleep(2000)
        const result = await getIsAvailableChainSwitch(currentConnection)
        return result
      }
      return true
    }
  }

  const onConnectApp = async (currentConnection: Connection) => {
    const { accounts, connector } = currentConnection

    const iconLocalFile = walletIcons[connector.name]
    const icon = iconLocalFile ? `wallets/${iconLocalFile}` : connector.icon || ''
    const isSafe = connector.name === 'Safe'
    const isWalletConnectSafe = await getIsWalletConnectSafe(currentConnection)
    const isAvailableChainSwitch = await getIsAvailableChainSwitch(currentConnection)

    await walletStore.$actions.connect({
      providerName: connector.name,
      address: accounts[0],
      meta: {
        icon,
        name: connector.name,
        isAvailableChainSwitch,
        isGnosisSafe: isSafe || isWalletConnectSafe,
        description: walletDescriptions[connector.name],
      },
    })

    if (connector.name === 'Safe') {
      reconnect(getConfig())
    }
  }

  // For gnosis auto connection
  onMounted(() => {
    AUTOCONNECTED_CONNECTOR_IDS.forEach((connector) => {
      if (connection.value && connection.value.connector.id === connector) {
        void onConnectWallet(connection.value.connector)
      }
    })
  })

  // For use with multiple injected wallets
  watch(connections, (currentConnections) => {
    if (currentConnections.length > 0) {
      currentConnections.forEach((_connection, index) => {
        if (index > 0) {
          disconnect({ connector: _connection.connector })
        }
      })
    }
  })

  // For set the connection state in the store
  watch(connection, async (currentConnection, prevConnector) => {
    if (!currentConnection) {
      void walletStore.$actions.disconnect()
      return
    }

    const { accounts, connector } = currentConnection

    if (accounts[0] === prevConnector?.accounts[0] && connector.id === prevConnector?.connector.id) {
      return
    }

    const isSanctionAddress = await checkIsSanctionAddress(accounts[0])

    if (isSanctionAddress) {
      void modalSanctionAddress.show()
      disconnect()
      return
    }

    void onConnectApp(currentConnection)
  })
}
