import type { IRevision } from '@/types/common'

import { ref } from 'vue'
import { SW_UPDATE_FOUND_EVENT, SW_UPDATED_EVENT } from './utils'


type IEvent = {
  detail: {
    registration: ServiceWorkerRegistration;
    revision?: IRevision;
  };
}

const isActive = ref(false)
const version = ref(process.env.VUE_APP_VERSION)
let eventGlobal: IEvent | null = null

const refresh = () => {
  // eslint-disable-next-line no-console
  console.log('[App sw] Updating content...')
  // https://developer.chrome.com/docs/workbox/reference/workbox-build/#type-GenerateSWOptions
  eventGlobal?.detail.registration.waiting?.postMessage({ type: 'SKIP_WAITING' })

  isActive.value = false
  eventGlobal = null

  if (version.value) {
    // it need when skipWaiting === false in settings and app.js has not have hash
    // need reload when founded new version
    window.location.reload()
  }
}

const onSwUpdatefound = (event: Event) => {
  isActive.value = true
  eventGlobal = event as unknown as IEvent

  const { revision } = eventGlobal.detail

  version.value = (
    (revision?.VERSION === process.env.VUE_APP_VERSION ? '' : revision?.VERSION)
    || (revision?.RELEASE_SHORT_SHA === process.env.VUE_APP_RELEASE_SHORT_SHA ? '' : revision?.RELEASE_SHORT_SHA)
    || ''
  )

  // eslint-disable-next-line no-console
  console.log(
    '[App sw]: update found',
    eventGlobal.detail.revision,
    process.env.VUE_APP_VERSION,
    process.env.VUE_APP_RELEASE_SHORT_SHA,
  )
}

const onSwUpdated = () => {
  isActive.value = false
  eventGlobal = null

  // eslint-disable-next-line no-console
  console.log('[App sw]: updated')
}

document.addEventListener(SW_UPDATE_FOUND_EVENT, onSwUpdatefound)
document.addEventListener(SW_UPDATED_EVENT, onSwUpdated)

export class ServiceWorkerService {
  public static useServiceWorker() {
    return {
      isActive,
      version,
      refresh,
    }
  }
}
