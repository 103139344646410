import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
    class: _ctx.$s.root,
    name: _ctx.name
  }), [
    _renderSlot(_ctx.$slots, "button", {
      active: _ctx.isActive,
      onToggle: _ctx.onToggle,
      onOpen: _ctx.onOpen,
      onClose: _ctx.onClose
    }),
    _createVNode(_Transition, {
      name: "transition--fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isActive && !_ctx.disabled)
          ? (_openBlock(), _createElementBlock("ul", {
              key: 0,
              class: _normalizeClass([_ctx.$s.menu, _ctx.menuClass, _ctx.position]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item[_ctx.optionId],
                  class: _normalizeClass([_ctx.$s.option, _ctx.optionClass])
                }, [
                  _renderSlot(_ctx.$slots, "option", {
                    data: item,
                    onToggle: _ctx.onToggle,
                    onOpen: _ctx.onOpen,
                    onClose: _ctx.onClose
                  })
                ], 2))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 16, _hoisted_1)), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}