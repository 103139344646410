import memoize from 'lodash/memoize'
import { computed, reactive, toRefs } from 'vue'
import { useMultisenderStoreModule } from '@/store'
import { formatWeiHuman } from '@/utils'


let store: ReturnType<typeof useMultisenderStoreModule>

const createAirdropFeeHuman = computed(() => {
  const { createAirdropFee } = store.state
  return createAirdropFee ? formatWeiHuman(createAirdropFee) : '-'
})

const recipientsAmountHuman = computed(() => {
  const recipientsAmount = store.state.airdropPrivate?.recipientsAmount || '0'
  const decimals = store.state.tokenInfo?.decimals || 18
  const result = formatWeiHuman(recipientsAmount, decimals)
  return result
})

const useMultisender = () => {
  store = useMultisenderStoreModule()

  const stateRefs = toRefs(store.state)
  const gettersRefs = toRefs(store.getters)

  const $state = reactive({
    ...stateRefs,
    ...gettersRefs,

    createAirdropFeeHuman,
    recipientsAmountHuman,
  })

  return {
    $store: store,
    $state,
    $actions: store.actions,
    $mutations: store.mutations,

    ...stateRefs,
    ...gettersRefs,

    createAirdropFeeHuman,
    recipientsAmountHuman,
  }
}

export const useMultisenderStore = memoize(useMultisender)
