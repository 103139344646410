import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "transition--fade",
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$s.root, `is-type--${_ctx.type}`, {
        'is-action-mobile-to-bottom': _ctx.actionMobileToBottom,
      }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$s.main)
        }, [
          _createVNode(_component_MIcon, {
            name: _ctx.icon,
            size: "medium",
            sprite: "",
            class: _normalizeClass(_ctx.$s.icon)
          }, null, 8, ["name", "class"]),
          _createElementVNode("p", null, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.text), 1)
            ])
          ])
        ], 2),
        (_ctx.action && _ctx.onAction || _ctx.$slots.action)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.$s.action)
            }, [
              _renderSlot(_ctx.$slots, "action", {}, () => [
                _createVNode(_component_MButton, {
                  text: _ctx.actionText,
                  disabled: _ctx.actionDisabled,
                  type: _ctx.type === 'danger' ? 'danger' : void 0,
                  class: _normalizeClass(_ctx.$s.button),
                  name: "message-action",
                  onClick: _ctx.onAction
                }, null, 8, ["text", "disabled", "type", "class", "onClick"])
              ])
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }))
}