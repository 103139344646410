

const ERROR_MAP = {
  ELSE: 'Some thing happened',
  ANY: 'Some thing wrong',
  404: 'Not Found',
  500: 'Server error',
}

const getError = async (response: Response) => {
  let status = 500

  try {
    const result = await response.json()
    const message = (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      result?.message
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      || result?.error?.message
    ) as string
    if (message) return message
  } catch {
    status = response.status
  }

  if (!status) return ERROR_MAP.ANY
  if (status === 404) return ERROR_MAP[404]
  if (status >= 500) return ERROR_MAP[500]

  return ERROR_MAP.ELSE
}

const handleError = async <T extends object>(response: Response) => {
  if (!response.ok) {
    const message = await getError(response)

    return {
      // error: Object.assign(new Error(message), { response }),
      error: message,
      response,
    }
  }

  const json = await response.json() as T

  const jsonWithError = json as T & { error: string }

  const hasError = (
    jsonWithError
    && typeof 'json' === 'object'
    && 'error' in json
    && jsonWithError.error
    && jsonWithError.error !== 'false'
  )

  if (hasError) {
    return {
      error: jsonWithError.error,
      data: json,
      response,
    }
  }

  return { data: json, response }
}

export abstract class ApiBaseService {
  protected fetcher<T extends object>(url: string, init: RequestInit) {
    return fetch(url, init)
      .then((response) => (handleError<T>(response)))
  }
}
