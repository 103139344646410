import { RouteRecordRaw } from 'vue-router'
import { ROUTE_HOME } from '@/utils/constants/routes'


const createRoutes = (): RouteRecordRaw[] => [
  {
    path: '/',
    name: ROUTE_HOME,
    component: () => import(
      /* webpackChunkName: "pages.home" */
      './PageHome.vue'
    ),
  },
]

const createModule = () => {
  const routes = createRoutes()
  return routes
}

export default createModule
