<template>
  <nav :class="[$s.root, $attrs.class]" class="is-desktop">
    <MMenuLinks
      :label="$t('menu-main.products')"
      :options="productOptions"
      :class="$s.link"
      name="menu-products"
      hover
    />

    <MButton
      type="link"
      size="small"
      :to="menuAirdrops.to"
      :text="$t(menuAirdrops.label)"
      :class="$s.link"
      name="menu-airdrops"
    />

    <MMenuLinks
      :label="$t('menu-main.information')"
      :active="informationActive"
      :options="informationOptions"
      :class="$s.link"
      name="menu-info"
      hover
    />
  </nav>

  <nav
    :class="[
      $s.root,
      $attrs.class,
      {
        'is-active': isActiveNav,
      },
    ]"
    class="is-mobile"
  >
    <template v-for="item in navList" :key="item.id">
      <router-link
        v-if="item.to"
        :to="item.to"
        :class="$s.linkMobile"
        :name="`menu-mobile--${item.id}`"
        @click="onClose"
      >
        <span :class="$s.linkMobileText" v-text="$t(item.label)" />
      </router-link>

      <MButton
        v-else
        type="link"
        :href="item.href"
        :blank="item.blank"
        :active="item.active"
        :class="$s.linkMobile"
        class="is-external"
        :name="`mobile-menu--${item.id}`"
        external
        @click="onClose"
      >
        <span
          :class="$s.linkMobileText"
          class="is-external"
          v-text="$t(item.label)"
        />
      </MButton>
    </template>
  </nav>

  <MBtnBurger
    v-model:active="isActiveNav"
    :class="$s.burger"
    :aria-label="$t('aria.open-menu')"
  />
</template>

<script lang="ts">
import {
  computed, defineComponent, ref, watch, onBeforeUnmount,
} from 'vue'
import { useRoute } from 'vue-router'
import { useBreakpoints } from '@/composable'
import { BlockBodyService } from '@/services'
import {
  MENU_MULTISENDER_NFT,
  MENU_MULTISENDER_TON,
  MENU_MULTISENDER_TRON,
  MENU_MULTISENDER_SOLANA,
  MENU_MULTISENDER_CLASSIC,
  MENU_MULTISENDER_MASSDROP,
  MENU_TUTORIAL,
  MENU_AIRDROPS,
  MENU_FAQ,
  MENU_ANALYTICS,
} from '@/utils/constants/main-menu'

import MButton from '@/components/ui/MButton.vue'
import MMenuLinks from '@/components/MMenuLinks.vue'

import MBtnBurger from './MBtnBurger.vue'

const PRODUCT_OPTIONS = [
  MENU_MULTISENDER_CLASSIC,
  MENU_MULTISENDER_TON,
  MENU_MULTISENDER_SOLANA,
  MENU_MULTISENDER_TRON,
  MENU_MULTISENDER_NFT,
  MENU_MULTISENDER_MASSDROP,
]

const INFORMATION_OPTIONS = [MENU_TUTORIAL, MENU_ANALYTICS, MENU_FAQ]

const NAV_LIST = [...PRODUCT_OPTIONS, MENU_AIRDROPS, ...INFORMATION_OPTIONS]

export default defineComponent({
  components: {
    MButton,
    MMenuLinks,
    MBtnBurger,
  },
  props: {
    active: {
      type: Boolean,
    },
  },
  setup: () => {
    const isActiveNav = ref(false)
    const { isTablet } = useBreakpoints()
    const blockBodyService = new BlockBodyService()
    const route = useRoute()

    let unBlockedBody: (() => void) | null = null

    const informationActive = computed(() => INFORMATION_OPTIONS.some((_) => _.to?.name === route.name))

    watch([isTablet], () => {
      isActiveNav.value = false
    })

    watch(isActiveNav, (value) => {
      unBlockedBody?.()
      if (value) unBlockedBody = blockBodyService.block()
    })

    const onClose = () => {
      isActiveNav.value = false
    }

    onBeforeUnmount(() => {
      unBlockedBody?.()
    })

    return {
      isActiveNav,
      informationActive,

      productOptions: PRODUCT_OPTIONS,
      informationOptions: INFORMATION_OPTIONS,
      menuAirdrops: MENU_AIRDROPS,
      navList: NAV_LIST,

      onClose,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  &:global(.is-mobile) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $layer-modal-container;
    padding: 3.6rem 2rem;
    background-color: $color-bg-dark;
    transition: opacity 0.2s;
  }

  &:global(.is-desktop) {
    display: inline-flex;
    align-items: center;
  }

  &:global(.is-mobile:not(.is-active)) {
    visibility: hidden;
    opacity: 0;
  }

  @include media-lt(desktop) {
    &:global(.is-desktop) {
      display: none;
    }
  }

  @include media-gte(desktop) {
    &:global(.is-mobile) {
      display: none;
    }
  }
}

.link {
  margin: 0 1.6rem;

  &:global(.router-link-active) {
    color: $color-white;
    cursor: default;
  }
}

.link-mobile {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.8rem;
  margin-top: 1.6rem;
  font-size: 1.5rem !important;
  font-weight: 600;

  &:global(.router-link-active) {
    color: $color-white;
    background-color: $color-white-02;
    border-radius: 0.6rem;
  }

  &:global(:not(.is-active):not(.router-link-active)) {
    color: $color-link-text;

    &:hover {
      color: $color-link-hover !important;
    }
  }

  &:global(.is-external:not(.is-active))::after {
    display: inline;
    margin-left: 0.6em;
    content: "↗";
  }
}

.link-mobile-text {
  position: relative;

  text-align: center;
  white-space: nowrap;

  &::before {
    position: absolute;
    bottom: -0.6rem;
    left: 0;
    display: block;
    width: 100%;
    height: 0.2rem;
    background-color: currentColor;
    border-radius: 1rem;
    box-shadow: 0 0 0.6rem currentColor;
    transition: transform 0.2s ease-in;
    transform: scaleX(0);

    .link:hover > &,
    .link:global(.router-link-active) > & {
      transform: scaleX(1);
    }
  }

  &:global(.is-external) {
    padding-right: 0.6rem;
    margin-right: -0.6rem;
  }
}

.burger {
  z-index: $layer-modal-container;
  order: 2;
  margin-left: 0.8rem !important;

  @include media-gte(desktop) {
    display: none;
    visibility: hidden;
  }
}
</style>
