import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? 'router-link' : _ctx.href ? 'a' : 'button'), _mergeProps({
    ref: "componentRef",
    to: _ctx.to,
    target: _ctx.href && _ctx.blank !== false ? '_blank' : null,
    rel: _ctx.href ? 'noopener noreferrer nofollow' : null
  }, _ctx.$attrs, {
    type: _ctx.to || _ctx.href ? null : _ctx.nativeType,
    disabled: _ctx.to || _ctx.href ? null : _ctx.disabled,
    readonly: _ctx.readonly,
    class: [
      _ctx.$s.root,
      `is-type--${_ctx.type}`,
      `is-size--${_ctx.size}`,
      {
        [_ctx.$s.fullWidth]: _ctx.fullWidth,
        [_ctx.$s.loading]: _ctx.loading || _ctx.isLoading,
        'is-active': _ctx.active,
        'is-disabled': _ctx.disabled,
        'is-readonly': _ctx.readonly,
      },
    ],
    name: _ctx.name,
    onClick: _ctx.onClick
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.text), 1)
      ])
    ]),
    _: 3
  }, 16, ["to", "target", "rel", "type", "disabled", "readonly", "class", "name", "onClick"]))
}