import { INetworkConfig, NetworkChainId } from './types'

export const TESTNET_IDS = [NetworkChainId.sepolia]

// TODO: need inspect gasPrice settings for all settings

const ALCHEMY_MAINNET_KEY = process.env.VUE_APP_ALCHEMY_MAINNET_KEY
const ALCHEMY_SEPOLIA_KEY = process.env.VUE_APP_ALCHEMY_SEPOLIA_KEY

const ETHERSCAN_KEY = process.env.VUE_APP_ETHERSCAN_KEY
const BSCSCAN_KEY = process.env.VUE_APP_BSCSCAN_KEY
const ETHPLORER_KEY = process.env.VUE_APP_ETHPLORER_KEY

export const NETWORK_MAINNET: INetworkConfig = {
  network: {
    chainId: NetworkChainId.mainnet,
    name: 'Ethereum Mainnet',
    nameShort: 'Mainnet',
    icon: 'symbols/ethereum',
    pollTime: 15,
    EIP1559: true,
    testnet: false,
    color: '#29b6af', // #627EEA
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: 'symbols/ethereum',
  },
  contracts: {
    multisenderMerkle: {
      address: '0xA6646162Ab02E9945CeDdf950167F892F682c985',
      blockFrom: 14920219,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 20,
    // url: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    url: 'https://eth-mainnet.g.alchemy.com/v2/ORDaRT20rHu5Dgm8C_JkIJ8OUYaeVVWm',
  },
  gasPrice: {
    fast: 21,
    low: 1,
    custom: 3,
    standard: 4,
  },
  explorer: {
    url: 'https://etherscan.io',
  },
  tokenApis: [
    {
      type: 'alchemy',
      url: `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_MAINNET_KEY}`,
      query: '',
    },
    {
      type: 'etherscan',
      url: 'https://api.etherscan.io/api',
      query: `apikey=${ETHERSCAN_KEY}&module=account&action=tokentx&address=`,
    },
    {
      type: 'ethplorer',
      url: 'https://api.ethplorer.io/getAddressInfo',
      query: `apiKey=${ETHPLORER_KEY}`,
    },
    // {
    //   type: 'convalenthq',
    //   url: 'https://api.covalenthq.com/v1',
    //   query: `no-nft-fetch=true&key=${COVALENTH_KEY}`,
    // },
    {
      type: 'ztake',
      url: 'https://blockchains.ztake.org/api/V9HSbkbGw9J8v64gDqoxauwhyCQjB8NF',
      query: '',
    },
  ],
  blockGasLimit: 30_087_597,
}

export const NETWORK_SEPOLIA: INetworkConfig = {
  network: {
    chainId: NetworkChainId.sepolia,
    name: 'Ethereum Sepolia',
    nameShort: 'Sepolia',
    icon: 'symbols/sepolia',
    pollTime: 15,
    EIP1559: true,
    testnet: true,
    color: '#3099f2', // #627EEA
  },
  currency: {
    name: 'SEP',
    symbol: 'SEP',
    decimals: 18,
    icon: 'symbols/sepolia',
  },
  contracts: {
    multisenderMerkle: {
      address: '0xAa6E22C688Aafc52195E61555d0eDD65f2412332',
      blockFrom: 5293973,
    },
    multicall3: NETWORK_MAINNET.contracts.multicall3,
  },
  rpc: {
    retryAttempt: 15,
    url: 'https://1rpc.io/sepolia',
  },
  explorer: {
    url: 'https://sepolia.etherscan.io',
  },
  tokenApis: [
    {
      type: 'alchemy',
      url: `https://eth-sepolia.alchemyapi.io/v2/${ALCHEMY_SEPOLIA_KEY}`,
      query: '',
    },
    {
      type: 'etherscan',
      url: 'https://api-sepolia.etherscan.io/api',
      query: `apikey=${ETHERSCAN_KEY}&module=account&action=tokentx&address=`,
    },
  ],
  gasPrice: {
    fast: 120,
    low: 20,
    custom: 20,
    standard: 35,
  },
  blockGasLimit: 29_970_677,
}

export const NETWORK_BSC: INetworkConfig = {
  network: {
    chainId: NetworkChainId.binance,
    nameShort: 'BSC',
    name: 'BNB Smart Chain Mainnet',
    pollTime: 15,
    EIP1559: false,
    icon: 'symbols/binance',
    testnet: false,
    color: '#F3BA2F',
  },
  currency: {
    name: 'BNB Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
    icon: 'symbols/binance',
  },
  contracts: {
    multisenderMerkle: {
      address: '0x00fe66030962387441a6Fdb32A593677752f71b7',
      blockFrom: 18477930,
    },
    multicall3: NETWORK_MAINNET.contracts.multicall3,
  },
  rpc: {
    retryAttempt: 15,
    mainUrl: 'https://bsc-dataseed1.bnbchain.org',
    url: 'https://blockchains.ztake.org/rpc/bsc?api_key=acdf6513-bb57-40b6-947a-dc00028aad5a',
    // mainUrl: 'https://bsc-dataseed1.ninicoin.io',
    // url: 'https://bsc-dataseed1.bnbchain.org',
  },
  explorer: {
    url: 'https://bscscan.com',
  },
  tokenApis: [
    {
      type: 'etherscan',
      url: 'https://api.bscscan.com/api',
      query: `apikey=${BSCSCAN_KEY}&module=account&action=tokentx&address=`,
    },
    {
      type: 'ztake',
      url: 'https://blockchains.ztake.org/api/V9HSbkbGw9J8v64gDqoxauwhyCQjB8NF',
      query: '',
    },
  ],
  gasPrice: {
    fast: 20,
    low: 5,
    custom: 5,
    standard: 5,
  },
  blockGasLimit: 79_847_587,
}

export const NETWORK_CONFIGS = [
  NETWORK_MAINNET,
  NETWORK_SEPOLIA, // NETWORK_MAINNET
  NETWORK_BSC,
].reduce((acc, settings) => {
  const { chainId } = settings.network
  acc[chainId] = settings
  return acc
}, {} as Record<NetworkChainId, INetworkConfig>)

/*
// update gasLimit for all networks

const checkGasLimit = () => {
  void Object.values(NETWORK_CONFIGS).reduce(async (acc, { network, rpc }) => {
    await acc
    const { getRpcProviderMemoized } = await import('./utils')
    const provider = getRpcProviderMemoized(rpc.url, network.chainId)

    try {
      const block = await provider.getBlock('latest')
      // eslint-disable-next-line no-console
      console.log({ chainId: network.chainId, gasLimit: block.gasLimit.toNumber() })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ chainId: network.chainId, gasLimit: null })
      // eslint-disable-next-line no-console
      console.error((error as Error)?.message)
    }

    return acc
  }, Promise.resolve(null))
}

checkGasLimit()
*/
