import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MMessage = _resolveComponent("MMessage")!

  return (_openBlock(), _createBlock(_component_MMessage, {
    type: _ctx.data.type,
    text: _ctx.$t(_ctx.data.text),
    action: !!_ctx.data.action,
    "action-mobile-to-bottom": _ctx.actionToBottom,
    "action-text": "Submit",
    onAction: _ctx.onAction
  }, _createSlots({ _: 2 }, [
    (!_ctx.data.action)
      ? {
          name: "action",
          fn: _withCtx(() => [
            _createVNode(_component_MButton, {
              size: "symbol",
              type: "ghost",
              name: "notice-close",
              onClick: _ctx.onClose
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MIcon, {
                  name: "close",
                  size: "medium",
                  sprite: ""
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["type", "text", "action", "action-mobile-to-bottom", "onAction"]))
}