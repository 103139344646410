import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "activator", {
      active: _ctx.isActive,
      on: {
      mouseenter: _ctx.onMouseEnter,
      mouseleave: _ctx.onMouseLeave,
      touchstart: _ctx.onTouchStart,
      touchend: _ctx.onTouchEnd,
    }
    }, () => [
      _createElementVNode("span", {
        style: _normalizeStyle(_ctx.$attrs.style),
        class: _normalizeClass(_ctx.$attrs.class),
        onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnter && _ctx.onMouseEnter(...args))),
        onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
        onTouchstart: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTouchStart && _ctx.onTouchStart(...args))),
        onTouchend: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onTouchEnd && _ctx.onTouchEnd(...args))),
        textContent: _toDisplayString(_ctx.activatorText)
      }, null, 46, _hoisted_1)
    ]),
    (_ctx.isActive)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref: "contentRef",
          style: _normalizeStyle(_ctx.contentStyle),
          class: _normalizeClass([_ctx.$s.content, {
      'is-active': _ctx.isActive,
      'is-show': _ctx.isShow,
    }, _ctx.contentClass]),
          "data-triangle-left": "166px",
          onMouseenter: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnterContent && _ctx.onMouseEnterContent(...args))),
          onMouseleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeaveContent && _ctx.onMouseLeaveContent(...args)))
        }, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            _createElementVNode("span", {
              textContent: _toDisplayString(_ctx.contentText)
            }, null, 8, _hoisted_2)
          ])
        ], 38))
      : _createCommentVNode("", true)
  ], 64))
}