<template>
  <div :class="$s.root">
    <template v-for="item in socialList" :key="item.id">
      <MTooltip
        :content-text="item.label"
      >
        <template #activator="{ on }">
          <MButton
            type="link-icon"
            size="symbol"
            :href="item.href"
            :class="$s.link"
            :aria-label="item.label"
            :name="`social--${item.id}`"
            v-on="on"
          >
            <MIcon
              :name="item.icon"
              :class="$s.icon"
              sprite
            />
          </MButton>
        </template>
      </MTooltip>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  SOCIAL_MEDIUM,
  SOCIAL_TELEGRAM,
  SOCIAL_TWITTER,
  // SOCIAL_GITHUB,
} from '@/utils/constants/socials'

import MButton from '@/components/ui/MButton.vue'
import MIcon from '@/components/ui/MIcon.vue'
import MTooltip from '@/components/ui/MTooltip.vue'


const SOCIAL_LIST = [
  SOCIAL_MEDIUM,
  SOCIAL_TWITTER,
  SOCIAL_TELEGRAM,
  // SOCIAL_GITHUB,
]

export default defineComponent({
  components: {
    MButton,
    MIcon,
    MTooltip,
  },
  setup: () => ({
    socialList: SOCIAL_LIST,
  }),
})
</script>

<style lang="scss" module="$s">
.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  & + & {
    margin-left: 1.2rem;
  }
}

.icon {
  width: 2.4rem;
  height: auto;
}
</style>
