import { useAppStore, useWalletStore } from '@/composable'

export function useChangeChain() {
  const appStore = useAppStore()
  const walletStore = useWalletStore()

  return async (chainId?: number) => {
    void await Promise.all([
      appStore.$actions.switchToNetwork(chainId),
      walletStore.$actions.chainChanged(chainId || null),
    ])
  }
}
