import { type WalletClient } from 'viem'
import { useReconnect, useConfig, useAccount } from '@wagmi/vue'
import { getWalletClient } from '@wagmi/core'

import { sleep } from '@/utils'

export function useWallet() {
  const config = useConfig()
  const { reconnectAsync } = useReconnect()
  const account = useAccount()

  const getWallet = async (attempt = 0): Promise<WalletClient> => {
    try {
      if (!account.address.value) {
        throw new Error('wallet not connected')
      }

      const result = await getWalletClient(config)
      return result
    } catch (err) {
      if (attempt < 3) {
        if (!(err as Error).message.includes('unsupported provider')) {
          await reconnectAsync(config)
        }
        await sleep(3000)
        const wallet = await getWallet(attempt + 1)
        return wallet
      }
      throw err
    }
  }

  return {
    getWallet,
  }
}
