import {
  BigNumber, ContractTransaction, providers, utils,
} from 'ethers'

import type { ITxHistory } from '@/store'


export const isMayBeENSAddress = (address: string) => (
  address.toLowerCase().slice(-4) === '.eth'
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sleep = <T = any>(timeout: number, data?: T) => (
  new Promise<T>((resolve) => {
    setTimeout(resolve, timeout, data)
  })
)

export const numberFormat = (
  number: number,
  locale?: string,
  options?: Intl.NumberFormatOptions,
) => (
  new Intl.NumberFormat(locale, options).format(number)
)

export const requestPolling = (request: () => Promise<unknown>, timeout: number) => {
  let isCancelled = false
  let timerId: ReturnType<typeof setTimeout>

  const cancel = () => {
    isCancelled = true
    clearTimeout(timerId)
  }

  const worker = async () => {
    if (isCancelled) return

    try {
      await request()
    } catch {
      //
    }

    if (isCancelled) return
    await sleep(timeout)
    if (isCancelled) return

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    timerId = setTimeout(worker, timeout)
  }

  void worker()


  return {
    cancel,
  }
}

const calcGasLimit = (estimateGas: BigNumber) => estimateGas.mul(3).div(2)

export const calcFeePerTx = (
  estimateGas: BigNumber,
  feeData?: Awaited<ReturnType<providers.Provider['getFeeData']>>,
  withLog?: boolean,
) => {
  if (!feeData) return BigNumber.from(0)

  const feePerGas = feeData.maxFeePerGas
    ? feeData.maxFeePerGas.mul(2).div(3) // .add(feeData.maxPriorityFeePerGas || 0)
    : feeData.gasPrice || 0

  const gasLimit = calcGasLimit(estimateGas)
  const feePerTx = gasLimit.mul(feePerGas)

  if (withLog) {
    // eslint-disable-next-line no-console
    console.log('[App info]: calcFeePerTx', {
      feePerTx: utils.formatUnits(feePerTx, 18),
      estimateGas: estimateGas.toNumber(),
      gasLimit: gasLimit.toNumber(),
      maxFeePerGas: utils.formatUnits(feeData.maxFeePerGas || 0, 9),
      maxPriorityFeePerGas: utils.formatUnits(feeData.maxPriorityFeePerGas || 0, 9),
      gasPrice: utils.formatUnits(feeData.gasPrice || 0, 9),
    })
  }

  return feePerTx
}

export const getGasLimitWithCheckBalance = async (
  estimateGasPromise: Promise<BigNumber>,
  provider: providers.Provider,
  ethAccount: string,
  isInjectedWallet?: boolean,
) => {
  const [estimateGas, feeData, balance] = await Promise.all([
    estimateGasPromise,
    provider?.getFeeData(),
    isInjectedWallet
      ? provider?.getBalance(ethAccount)
      : new Promise<undefined>((resolve) => { resolve(undefined) }),
  ])

  const gasLimit = calcGasLimit(estimateGas)
  const feePerTx = calcFeePerTx(estimateGas, feeData, true)

  const requiredBalance = feePerTx.mul(2).div(3)
  if (balance && isInjectedWallet && requiredBalance.gt(balance)) {
    const error = new Error('Insufficient balance found')
    throw error
  }

  return {
    gasLimit,
    estimateGas,
    ...feeData,
  }
}

export const t = (message: string) => message

export const isInfinityWei = (value?: string | null) => !!(
  value && value.length > 36
)

export const createWaitPrevProcess = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let instanceInner: Promise<any>

  const waitPrevProcess = async <T>(
    instance?: Promise<T>,
  ): Promise<T> => {
    try {
      await instanceInner
    } catch {
      // do nothing
    }

    instanceInner = instance || Promise.resolve(undefined)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return instanceInner
  }

  return waitPrevProcess
}

export function addressComparison(address1?: string, address2?: string) {
  return address1?.toLowerCase() === address2?.toLowerCase()
}


export function detectAccountTx(tx?: ITxHistory | ContractTransaction, account?: string) {
  if (!account) {
    return false
  }

  const isFromAddress = addressComparison(tx?.from, account)
  const isToAddress = addressComparison(tx?.to, account)

  return isFromAddress || isToAddress
}
