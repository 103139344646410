import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MMenu = _resolveComponent("MMenu")!

  return (_openBlock(), _createBlock(_component_MMenu, {
    options: _ctx.locales,
    "option-id": "id",
    position: "top right",
    name: "locale-menu"
  }, {
    button: _withCtx(({ onToggle }) => [
      _createVNode(_component_MButton, {
        "aria-label": _ctx.$t('aria.switch-locale'),
        type: "ghost",
        size: "symbol",
        name: "locale-switch",
        onClick: onToggle
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.activeLocale.image,
            class: _normalizeClass(_ctx.$s.image),
            alt: _ctx.activeLocale.id
          }, null, 10, _hoisted_1)
        ]),
        _: 2
      }, 1032, ["aria-label", "onClick"])
    ]),
    option: _withCtx(({ data, active, onClose }) => [
      _createVNode(_component_MButton, {
        type: "link",
        class: _normalizeClass([_ctx.$s.optionButton, { 'is-active': active }]),
        active: data.id === _ctx.activeLocale.id,
        name: `locale--${data.id}`,
        onClick: ($event: any) => (_ctx.selectLocale(data), onClose())
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: data.image,
            class: _normalizeClass(_ctx.$s.image),
            alt: data.id
          }, null, 10, _hoisted_2),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.$s.optionLabel),
            textContent: _toDisplayString(data.label)
          }, null, 10, _hoisted_3)
        ]),
        _: 2
      }, 1032, ["class", "active", "name", "onClick"])
    ]),
    _: 1
  }, 8, ["options"]))
}