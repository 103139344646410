// from https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/


// https://stackoverflow.com/a/13382873
const getScrollbarWidth = () => {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  document.body.appendChild(outer)

  const inner = document.createElement('div')
  outer.appendChild(inner)

  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)

  outer.parentNode?.removeChild(outer)

  return scrollbarWidth
}


const scrollbarVisible = (element: HTMLElement) => (
  element.scrollHeight > element.clientHeight
)


let counter = 0

const classNameMap = new Map<string, number>()

export class BlockBodyService {
  private classNames?: string[]

  constructor(
    className?: string | string[],
  ) {
    this.classNames = className?.length
      ? ([] as string[]).concat(className)
      : void 0
  }

  public block(backgroundColor = '#0b152d') {
    const { classNames } = this
    counter += 1

    if (counter === 1) {
      const { style } = document.body
      const isScrollbarVisible = scrollbarVisible(document.body)

      if (isScrollbarVisible) {
        const width = getScrollbarWidth()
        style.paddingRight = `${width}px`
        style.backgroundColor = backgroundColor
      }

      style.overflow = 'hidden'
    }

    let isUnBlocked = false

    if (classNames) {
      const { classList } = document.body
      classNames.forEach((className) => {
        const count = (classNameMap.get(className) || 0) + 1
        classNameMap.set(className, count)
        classList.add(className)
      })
    }

    return () => {
      if (isUnBlocked) return
      isUnBlocked = true
      this.unblock()
    }
  }


  public unblock() {
    const { classNames } = this
    const { style, classList } = document.body
    counter -= 1

    if (classNames) {
      classNames.forEach((className) => {
        const count = (classNameMap.get(className) || 0) - 1
        classNameMap.set(className, count)
        if (count === 0) classList.remove(className)
      })
    }

    if (counter) return
    style.paddingRight = ''
    style.overflow = ''
    style.backgroundColor = ''
  }
}
