
import { Store } from 'vuex'
import isEqual from 'lodash/isEqual'
import mapValues from 'lodash/mapValues'

import { sentryLogger } from '@/services'

import type { StoreModules } from '../types'

type TPayload = {
  type: string;
  payload: unknown;
}

const deepCopy = (store: StoreModules) => {
  const copy = mapValues(store, (value) => ({ ...value }))

  return copy as StoreModules
}

const addMutationBreadcrumb = (
  mutation: { type: string },
  stateBefore: StoreModules,
  stateAfter: StoreModules,
  storeModuleName: keyof StoreModules,
  key: string,
) => {
  // @ts-ignore ts(7053)
  const before = stateBefore[storeModuleName][key] as unknown

  // @ts-ignore ts(7053)
  const after = stateAfter[storeModuleName][key] as unknown

  if (before === after || isEqual(before, after)) return false

  sentryLogger.addBreadcrumb({
    category: 'store',
    data: { before, after },
    message: `mutation - ${mutation.type} - ${key}`,
  })

  return false
}

const addActionBreadcrumb = (
  action: { type: string },
  store: StoreModules[keyof StoreModules],
) => {
  sentryLogger.addBreadcrumb({
    category: 'store',
    message: `action - ${action.type}`,
    data: {
      payload: (action as unknown as TPayload)?.payload,
      store,
    },
  })

  return false
}

const mutationLogger = (
  mutation: { type: string },
  stateBefore: StoreModules,
  stateAfter: StoreModules,
) => {
  if (mutation.type === 'airdrop/setState') {
    // @ts-ignore ts(2339)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const key = mutation.payload?.k as unknown as keyof StoreModules['airdrop']
    const storeModuleName = 'airdrop'

    switch (key) {
      case 'claimEvent':
      case 'recipients':
      case 'claimEventMap':
        break

      case 'airdropPublic':
      case 'airdrop':
      case 'chainId':
        addMutationBreadcrumb(
          mutation,
          stateBefore,
          stateAfter,
          storeModuleName,
          key,
        )
        break

      default:
        return key
    }
  }

  if (mutation.type === 'app/setState') {
    // @ts-ignore ts(2339)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const key = mutation.payload?.k as unknown as keyof StoreModules['app']
    const storeModuleName = 'app' as const

    switch (key) {
      case 'isLoadingBalance':
        break

      case 'balance':
      case 'chainId':
        addMutationBreadcrumb(
          mutation,
          stateBefore,
          stateAfter,
          storeModuleName,
          key,
        )
        break

      default:
        return key
    }
  }

  if (mutation.type === 'wallet/setState') {
    // @ts-ignore ts(2339)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const key = mutation.payload?.k as unknown as keyof StoreModules['wallet']
    const storeModuleName = 'wallet' as const

    switch (key) {
      case 'isLoadingConnection':
        break

      case 'ethAccount':
      case 'type':
      case 'chainId':
        addMutationBreadcrumb(
          mutation,
          stateBefore,
          stateAfter,
          storeModuleName,
          key,
        )
        break

      default:
        return key
    }
  }

  return null
}

const actionLogger = (
  action: { type: string },
  state: StoreModules,
  store: Store<StoreModules>,
) => {
  if (action.type.startsWith('app/')) {
    const type = action.type.split('/')[1] as keyof import('../modules/app').AppActions
    switch (type) {
      case '$init':
      case 'updateBalance':
        break

      case 'updateRpc':
      case 'switchToNetwork':
        addActionBreadcrumb(
          action,
          {
            ...state.app,
            ...store.state.wallet,
            ...store.state.airdrop,
            rpcUrls: JSON.stringify(state.app.rpcUrls) as unknown as typeof state.app.rpcUrls,
          },
        )
        break

      default:
        return type
    }
  }

  if (action.type.startsWith('wallet/')) {
    const type = action.type.split('/')[1] // as keyof import('../modules/wallet').WalletActions

    switch (type) {
      case 'connectToHandler':
      case 'disconnect':
      case 'accountsChanged':
      case 'chainChanged':
      case 'switchToNetwork':
      case 'updateRpc': {
        addActionBreadcrumb(action, state.wallet)
        break
      }

      default:
        return type
    }
  }

  return null
}

export const loggerSentryBreadcrumbsPlugin = (store: Store<StoreModules>) => {
  let prevState = deepCopy(store.state)

  store.subscribe((mutation, state) => {
    const nextState = deepCopy(state)
    mutationLogger(mutation, prevState, nextState)
    prevState = nextState
  })

  store.subscribeAction((action, state) => {
    actionLogger(action, state, store)
  })
}
