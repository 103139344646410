import { Store } from 'vuex'
import { Router, RouteRecordRaw } from 'vue-router'
import { StoreRootModule } from '@/store/types'

import AirdropRoutes from '@/pages/airdrop/routes'
import FAQRoutes from '@/pages/faq/routes'
import HomeRoutes from '@/pages/home/routes'
import MultisenderRoutes from '@/pages/multisender/routes'
import TutorialRoutes from '@/pages/tutorial/routes'
import ErrorsRoutes from '@/pages/errors/routes'


type ICreateRoutesOptions = {
  router: Router;
  store: Store<StoreRootModule>;
}

// eslint-disable-next-line
const UIRoutes = process.env.NODE_ENV !== 'production'
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  ? require('@/pages/ui/routes').default as unknown as typeof AirdropRoutes
  : () => []

export const createRoutes = (
  options: ICreateRoutesOptions,
): RouteRecordRaw[] => [
  HomeRoutes,

  AirdropRoutes,
  FAQRoutes,
  MultisenderRoutes,
  TutorialRoutes,
  ErrorsRoutes,
  UIRoutes,


  // must be last, because in error - `*` route
  ErrorsRoutes,
  // TODO: needs store and router in routes???
  // @ts-ignore ts(2554)
].map((callback) => callback(options)).flat()
