import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotice = _resolveComponent("MNotice")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$s.root)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$s.container)
    }, [
      _createVNode(_TransitionGroup, {
        name: "transition--fade",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notices, (item) => {
            return (_openBlock(), _createBlock(_component_MNotice, {
              key: item.id,
              data: item,
              class: _normalizeClass(_ctx.$s.notice)
            }, null, 8, ["data", "class"]))
          }), 128))
        ]),
        _: 1
      })
    ], 2)
  ], 2))
}