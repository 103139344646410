import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MExternalLink = _resolveComponent("MExternalLink")!

  return (_ctx.href)
    ? (_openBlock(), _createBlock(_component_MExternalLink, {
        key: 0,
        invert: _ctx.invert,
        external: _ctx.external,
        href: _ctx.href,
        text: _ctx.text || _ctx.addressRef
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["invert", "external", "href", "text"]))
    : _createCommentVNode("", true)
}