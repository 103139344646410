<template>
  <MButton
    :class="[$s.root, {
      'is-active': active,
    }]"
    :aria-pressed="active ? 'true' : 'false'"
    type="ghost"
    size="symbol"
    name="burger"
    @click="$emit('update:active', !active)"
  >
    <span :class="$s.line" />
    <span :class="$s.line" />
    <span :class="$s.line" />
  </MButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MButton from '@/components/ui/MButton.vue'


export default defineComponent({
  components: {
    MButton,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:active',
  ],
})
</script>

<style lang="scss" module="$s">
.root {
  position: relative;
  z-index: $layer-modal-container;
}

.line {
  position: absolute;
  left: 50%;
  width: 2rem;
  height: 0.2rem;
  color: $color-link-hover;
  background-color: currentColor;
  transition: all 0.2s ease-in-out;

  &:nth-of-type(1) {
    top: 1rem;
    transform: translateX(-50%);
  }

  &:nth-of-type(2) {
    top: 50%;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    transform: translate(-50%, -50%);
  }

  &:nth-of-type(3) {
    bottom: 1rem;
    transform: translateX(-50%);
  }

  .root:global(.is-active) & {
    &:nth-of-type(1),
    &:nth-of-type(3) {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &:nth-of-type(1) {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:nth-of-type(2) {
      opacity: 0;
    }

    &:nth-of-type(3) {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
</style>
