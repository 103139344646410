import { computed } from 'vue'
import { Connector, useConnect } from '@wagmi/vue'
import { useWalletStore } from '@/composable'
import { walletIcons, walletDescriptions } from '@/utils/constants/wallet'

const safeFilter = () => !(typeof window === 'undefined') && window?.parent !== window

const metamaskFilter = () => {
  if (typeof window === 'undefined') {
    return false
  }
  return Boolean(window.ethereum?.isMetaMask)
}

const injectFilter = () => Boolean(window?.ethereum)

const filterWallets = (connector: Connector, withKnownInjected: boolean) => {
  switch (connector.name) {
    case 'Safe':
      return safeFilter()
    case 'MetaMask':
      return metamaskFilter()
    case 'Injected':
      return !withKnownInjected && injectFilter()
    default:
      return true
  }
}


export type Wallet = {
  key:string;
  name:string;
  icon:string;
  description: string;
  connector: Connector;
  isConnected: boolean;
}

export type Wallets = Wallet[]

export function useWallets() {
  const { connectors } = useConnect()
  const walletStore = useWalletStore()

  const formatWallet = (connector: Connector) => {
    const isConnected = connector.name === walletStore.walletMeta.value?.name
    const iconLocalFile = walletIcons[connector.name]
    const icon = iconLocalFile ? `wallets/${iconLocalFile}` : connector.icon || ''
    return {
      icon,
      connector,
      isConnected,
      key: connector.uid,
      name: connector.name,
      description: walletDescriptions[connector.name],
    }
  }

  const withKnownInjected = connectors.some(({ name, type }) => name.toLowerCase() !== 'injected' && type.toLowerCase() === 'injected')

  return computed<Wallets>(() => connectors.reduce<Wallets>((acc, curr) => {
    if (curr.id.toLowerCase().endsWith('sdk')) {
      const duplicatedConnector = connectors.find((c) => c.type.toLowerCase() === 'injected' && curr.name.toLowerCase() === c.name.toLowerCase())
      if (duplicatedConnector) {
        return acc
      }
    }
    if (filterWallets(curr, withKnownInjected)) {
      acc.unshift(formatWallet(curr))
    }
    return acc
  }, []))
}
