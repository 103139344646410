import type { IModalBaseProps } from './types'
import type { DefineComponent, AsyncComponentLoader } from 'vue'

import { defineAsyncComponent } from 'vue'
import { sentryLogger } from '@/services'
import { sleep } from '@/utils'
import { useModal } from './useModal'

import loadingComponent from '@/components/MPageLoading.vue'
import MModalBase from './MModalBase.vue'


type TUseModalP = Parameters<typeof useModal>
const RETRY_ATTEMPTS = 3

const useModalHandler = <T>(
  modalName: string,
  asyncComponent: AsyncComponentLoader<T>,
  modalPropsData: IModalBaseProps = {},
  componentPopsData: TUseModalP[4] = {},
  parent: TUseModalP[5] = null,
) => {
  let result: ReturnType<typeof useModal>
  let transaction: ReturnType<typeof sentryLogger.trackModal>
  const name = modalName.replace(/\./g, '_')

  const AsyncComponent = defineAsyncComponent({
    loadingComponent,
    timeout: 30_000,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loader: asyncComponent,
    onError: async (error, retry, fail, attempts) => {
      sentryLogger.addBreadcrumb({
        category: 'modal',
        message: `Retry ${attempts} - ${name}`,
      })

      await sleep(attempts * 1000)

      if (attempts >= RETRY_ATTEMPTS) {
        fail()
        result?.hide()
      } else {
        retry()
      }
    },
  })

  result = useModal<IModalBaseProps>(
    modalName,
    MModalBase as unknown as DefineComponent,
    AsyncComponent,
    modalPropsData,
    componentPopsData,
    parent,
  )

  result.emitter.on('init', () => {
    sentryLogger.addBreadcrumb({
      category: 'modal',
      message: `Init - ${name}`,
    })
  })

  result.emitter.on('mount', (app) => {
    transaction = sentryLogger.trackModal(app, name)

    sentryLogger.addBreadcrumb({
      category: 'modal',
      message: `Open - ${name}`,
    })
  })

  result.emitter.on('unmount', () => {
    sentryLogger.addBreadcrumb({
      category: 'modal',
      message: `Close - ${name}`,
    })

    transaction?.finish()
  })

  return result
}

export const useModalConnect = () => {
  const AsyncComponent = () => import(
    /* webpackChunkName: "modal.connect" */
    './MModalConnect.vue'
  )

  const result = useModalHandler(
    'modal.connect',
    AsyncComponent,
  )

  return result
}

export const useModalTxHistory = () => {
  const AsyncComponent = () => import(
    /* webpackChunkName: "modal.tx-history" */
    './MModalTxHistory.vue'
  )

  const result = useModalHandler(
    'modal.tx-history',
    AsyncComponent,
  )

  return result
}

export const useModalAccount = () => {
  const AsyncComponent = () => import(
    /* webpackChunkName: "modal.account" */
    './MModalAccount.vue'
  )

  const result = useModalHandler(
    'modal.account',
    AsyncComponent,
  )

  return result
}

export const useModalSanctionAddress = () => {
  const AsyncComponent = () => import(
    /* webpackChunkName: "modal.sanction-address" */
    './MModalSanctionAddress.vue'
  )

  const result = useModalHandler(
    'modal.sanction-address',
    AsyncComponent,
  )

  return result
}

export const useModalSwitchNetwork = () => {
  const AsyncComponent = () => import(
    /* webpackChunkName: "modal.switch-network" */
    './MModalSwitchNetwork.vue'
  )

  const result = useModalHandler(
    'modal.switch-network',
    AsyncComponent,
  )

  return result
}

export const useModalRecipientExample = () => {
  const AsyncComponent = () => import(
    /* webpackChunkName: "modal.recipient-example" */
    './MModalRecipientExample.vue'
  )

  const result = useModalHandler(
    'modal.recipient-example',
    AsyncComponent,
    {
      closeOnUnmountParent: true,
    },
  )

  return result
}
