import { mainnet, bsc, sepolia } from '@wagmi/vue/chains'
import { http, createConfig, WagmiPlugin } from '@wagmi/vue'
import {
  safe,
  injected,
  walletConnect,
  coinbaseWallet,
} from '@wagmi/connectors'

import { IInitPlugin } from './types.d'

import { NETWORK_CONFIGS, NetworkChainId, NetworkService } from '@/services'

const APP_NAME = process.env.VUE_APP_NAME
// const SITE_URL = process.env.VUE_APP_SITE_ORIGIN
const APP_LOGO_URL = `${process.env.VUE_APP_SITE_ORIGIN}/favicon/apple-touch-icon.png`

export const getConfig = () => createConfig({
  chains: [
    mainnet,
    sepolia,
    {
      ...bsc,
      name: NETWORK_CONFIGS[bsc.id].network.name,
      rpcUrls: {
        default: { http: [NETWORK_CONFIGS[bsc.id].rpc.mainUrl || NETWORK_CONFIGS[bsc.id].rpc.url] },
      },
    },
  ],
  connectors: [
    safe({
      allowedDomains: [/app.safe.global$/],
    }),
    injected(),
    walletConnect({
      projectId: 'e346ca1620ff47bf7f90af576f5c5082',
      showQrModal: true,
      metadata: {
        url: window.location.href, // SITE_URL,
        name: APP_NAME,
        description: APP_NAME,
        icons: [APP_LOGO_URL],
      },
    }),
    coinbaseWallet({
      appName: APP_NAME,
    }),
  ],
  transports: {
    [bsc.id]: http(new NetworkService(NetworkChainId.binance).rpcUrl),
    [mainnet.id]: http(new NetworkService(NetworkChainId.mainnet).rpcUrl),
    [sepolia.id]: http(new NetworkService(NetworkChainId.sepolia).rpcUrl),
  },
})

export const initPlugin: IInitPlugin = (app) => {
  app.use(WagmiPlugin, { config: getConfig() })
}
