<template>
  <MButton
    :class="[$s.root, {
      'is-custom-network': isCustom,
    }]"
    type="outline"
    name="balance"
    @click="onClick"
  >
    <span :class="$s.balance">
      <span
        :class="[$s.number, { 'is-loading': isLoading }]"
        v-text="balanceHuman"
      />
      &nbsp;{{ currency.symbol }}
    </span>

    <span
      v-if="pendingTxs > 0"
      :class="$s.pending"
      @click.stop="onClickPending"
      v-text="$t(
        'common.amount-pending',
        { amount: pendingTxs },
      )"
    />

    <span
      v-else
      :class="$s.address"
      v-text="ethAccountShort"
    />
  </MButton>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useAppStore, useTxHistoryStore, useWalletStore } from '@/composable'
import { useModalAccount, useModalTxHistory } from '@/components/modals'

import MButton from '@/components/ui/MButton.vue'


export default defineComponent({
  components: {
    MButton,
  },
  setup: () => {
    const appStore = useAppStore()
    const walletStore = useWalletStore()
    const txHistoryStore = useTxHistoryStore()
    const modalAccount = useModalAccount()
    const modalTxHistory = useModalTxHistory()

    const isCustom = computed(() => (
      appStore.$state.rpcUrls.find((_) => _.active)?.type === 'custom'
    ))

    const onClick = () => {
      void modalAccount.show()
    }

    const onClickPending = () => {
      void modalTxHistory.show()
    }

    const pendingTxs = computed(() => {
      const { listAccountPending, gnosisListAccountPending } = txHistoryStore
      return listAccountPending.value.length + gnosisListAccountPending.value.length
    })

    return {
      isCustom,
      pendingTxs,
      currency: appStore.currency,
      isLoading: appStore.isLoadingBalance,
      balanceHuman: appStore.balanceHuman,
      ethAccountShort: walletStore.ethAccountShort,

      onClick,
      onClickPending,
    }
  },
})
</script>

<style lang="scss" module="$s">
.root {
  padding-right: 0 !important;

  &:global(.is-custom-network) {
    border-color: $color-bg-gradient-circle;
  }
}

.balance {
  position: relative;
  display: flex;
  font-weight: $font-weight-medium;

  .number {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s;

    &:global(.is-loading) {
      min-width: 3rem;
      color: $color-white-02;

      &::before {
        @include loading($color-primary, 2rem);
      }
    }
  }
}

.address,
.pending {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.4rem;
  padding: 0.4rem 1rem;
  margin: 0.2rem 0.2rem 0.2rem 1rem;
  background-color: rgba($--color-primary, 0.1);
  border-radius: 0.5rem;
}

.pending {
  &::after {
    @include loading($color-primary, 1.6rem);
    position: static;
    margin: 0 0 0 1rem;
  }
}
</style>
