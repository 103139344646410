import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_svg_inline = _resolveDirective("svg-inline")!

  return (_ctx.src)
    ? (_openBlock(), _createElementBlock("span", {
        key: _ctx.name,
        class: _normalizeClass([
      _ctx.$s.root,
      _ctx.isSvg && `is-icon--${_ctx.name}`,
      `is-size--${_ctx.size}`,
    ])
      }, [
        (_ctx.isSvg)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.sprite)
                ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.src
                  }, null, 8, _hoisted_1)), [
                    [
                      _directive_svg_inline,
                      void 0,
                      void 0,
                      { sprite: true }
                    ]
                  ])
                : _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.src
                  }, null, 8, _hoisted_2)), [
                    [_directive_svg_inline]
                  ])
            ], 64))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.src
            }, null, 8, _hoisted_3))
      ], 2))
    : _createCommentVNode("", true)
}