import { markRaw } from 'vue'
import debounce from 'lodash/debounce'
import VuexPersistence, { PersistOptions } from 'vuex-persist'
import { StoreModules } from '../types'


const SAVE_STATE_TIMEOUT = 500

const reducer: PersistOptions<StoreModules>['reducer'] = (state) => {
  const multisender = {
    ...state.multisender,
    recipients: [],
    walletTokens: [],
    chainId: null,
    tokenInfo: null,
  }

  if (!multisender.airdropPrivate) {
    multisender.chainId = null
    multisender.tokenInfo = null
  }

  return {
    app: state.app,
    multisender,
    txHistory: state.txHistory,
    wallet: state.wallet,
  }
}

const saveState: PersistOptions<StoreModules>['saveState'] = (key, state, storage) => {
  try {
    storage?.setItem(key, JSON.stringify(state))
  } catch {
    // eslint-disable-next-line no-console
    console.warn('[App err]: error save state for persist plugin')
  }
}

export const persistPlugin = new VuexPersistence<StoreModules>({
  key: 'VUEX_STATE',
  strictMode: process.env.NODE_ENV !== 'production',
  reducer,
  saveState: debounce(saveState, SAVE_STATE_TIMEOUT, {
    leading: true,
    trailing: true,
  }),
  supportCircular: false,
})

const setRestoreData = (
  object: StoreModules,
  key: keyof StoreModules,
  value: StoreModules[keyof StoreModules],
) => {
  const val = { ...value }

  if ('list' in val) {
    val.list = markRaw(val.list)
  }

  if ('recipients' in val) {
    val.recipients = markRaw(val.recipients)
  }

  Object.assign(object[key], val)
}

export function RESTORE_MUTATION(
  currentState: StoreModules,
  savedState: StoreModules,
) {
  persistPlugin.RESTORE_MUTATION.call({
    _vm: { $set: setRestoreData },
  }, currentState, savedState)
}
