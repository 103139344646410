export const walletIcons: Record<string, string> = {
  WalletConnect: 'wallet-connect',
  MetaMask: 'metamask',
  Safe: 'safe',
  'Coinbase Wallet': 'coinbase',
  Injected: 'injected',
}

export const walletDescriptions: Record<string, string> = {
  WalletConnect: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  MetaMask: 'Easy-to-use crypto wallet.',
  Safe: 'Connect to smart contract wallet.',
  'Coinbase Wallet': 'Use mobile app or browser extension.',
  Injected: 'Connect to the detected wallet.',
}
