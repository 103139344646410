import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MLayoutDefault = _resolveComponent("MLayoutDefault")!

  return (_openBlock(), _createBlock(_component_MLayoutDefault, {
    loading: _ctx.isLoading,
    connected: _ctx.isConnected,
    "is-valid-rpc": _ctx.isValidRpc
  }, {
    main: _withCtx((props) => [
      _createVNode(_component_router_view, {
        class: _normalizeClass(_ctx.$s.view)
      }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "transition--fade",
            mode: "out-in",
            appear: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_KeepAlive, { include: ['PageAirdrops'] }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), _normalizeProps(_guardReactiveProps(props)), null, 16))
              ], 1024))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["class"])
    ]),
    _: 1
  }, 8, ["loading", "connected", "is-valid-rpc"]))
}